<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row v-if="risqueLocal[0].produitsFiltred.length > 0">
      <b-col class="mb-2" cols="12">
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 3 : Comparaison des offres
        </h2>
        <h6>Sélectionner les offres répondant le mieux aux besoins exprimés (1 minimum, 2 maximum)</h6>
      </b-col>
      <b-col lg="12">
        <b-button class="float-left my-2" size="lg" variant="primary" @click="backStep">
          Précédent
        </b-button>
        <b-button v-if="canNext" class="float-right my-2" size="lg" variant="primary" @click="submitProduit"> Suivant </b-button>
      </b-col>
      <transition name="fade">
        <b-col v-if="showCompareTable" cols="12">
          <div v-for="(rl, index) in risqueLocal" :key="index + 'content'" class="mb-5">
            <b-dropdown class="mb-2 float-right" right split text="Trier par" variant="outline-primary">
              <b-dropdown-item @click="filtreUpDown(index)">
                Prix décroissant
              </b-dropdown-item>
              <b-dropdown-item @click="filtreDownUp(index)">
                Prix croissant
              </b-dropdown-item>
            </b-dropdown>
            <b-table-simple v-for="(pr, val) in rl.produitsFiltred" :key="val + 'produits'" bordered caption-top class="mb-4" hover responsive>
              <b-thead class="offere_thClass">
                <b-tr>
                  <b-th class="text-center" style="width: 15%;">
                    produit
                  </b-th>
                  <b-th class="text-center" style="width: 15%;">
                    documents
                  </b-th>
                  <b-th class="text-center" style="width: 25%;">
                    Garanties et franchises
                  </b-th>
                  <b-th class="text-center" style="width: 25%;">
                    Options
                  </b-th>
                  <b-th class="text-center" style="width: 20%;">
                    prix
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="offere_tdClass">
                <b-td class="text-center">
                  <p class="box-custom-text">
                    {{ pr.produit.produit_nom }}
                  </p>
                  <template v-if="pr.produit.produit_ws_nom == 'LGECRD'">
                    <b-img :src="require('@/assets/images/logo/assureur/Harmonie-assurance.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else-if="pr.produit.produit_ws_nom == 'ALTUS'">
                    <b-img :src="require('@/assets/images/logo/assureur/mutlog.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <p class="box-custom-text">
                    {{ pr.produit.assureur.nom_com_assureur }}
                  </p>
                </b-td>
                <b-td>
                  <div v-for="(pda, compt) in pr.produit.document_assureur" :key="compt + 'pda'">
                    <span v-if="pda.document" class="text-nowrap cursor-pointer" @click="showFile(pda.document.id, pda.document.document_type.type)">
                      <feather-icon color="#bf2626" icon="FileIcon" size="14" style="margin-bottom: 4px;" />
                      <span class="text-nowrap">{{ pda.document.document_type.type }}</span>
                    </span>
                  </div>
                </b-td>
                <b-td class="align-top">
                  <div v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc')" :key="indexDTBP + ' - GARANTIES ET FRANCHISES'" class="py-1">
                    <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.garanties_et_franchises" :key="indexDTBP + ' - ' + indexgarantie + ' - GARANTIES ET FRANCHISES' ">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0">
                        <span v-if="garantieEtFranchise.isParent" :style="garantieEtFranchise.style"> {{ garantieEtFranchise.value }} </span>
                        <b-list-group v-else-if="!garantieEtFranchise.isParent && garantieEtFranchise.isShowing" :style="garantieEtFranchise.style">
                          <b-list-group-item class="border-0 no-hover-bg py-0">
                            <div class="d-flex bd-highlight align-items-center">
                              <div class="w-75 bd-highlight">
                                {{ garantieEtFranchise.key }}
                              </div>
                              <div class="flex-shrink-1 bd-highlight text-left">
                                {{ garantieEtFranchise.value }}
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-td>
                <b-td class="align-top">
                  <div v-for="(DTBP, indexDTBP) in $_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc')" :key="indexDTBP + ' - OPTIONS'" class="py-1 align-items-start">
                    <b-list-group v-for="(garantieEtFranchise, indexgarantie) in DTBP.options" :key="indexDTBP + ' - ' + indexgarantie + ' - OPTIONS' ">
                      <b-list-group-item class="border-0 p-0 no-hover-bg py-0">
                        <span v-if="garantieEtFranchise.isParent" :style="garantieEtFranchise.style"> {{ garantieEtFranchise.value }} </span>
                        <b-list-group v-else :style="garantieEtFranchise.style">
                          <b-list-group-item class="border-0 no-hover-bg py-0">
                            <div class="d-flex bd-highlight align-items-center">
                              <div class="w-100 bd-highlight">
                                {{ garantieEtFranchise.key }}
                              </div>
                              <div class="flex-shrink-1 bd-highlight">
                                <feather-icon :icon="garantieEtFranchise.value" size="16" :color="garantieEtFranchise.style" />
                              </div>
                            </div>
                          </b-list-group-item>
                        </b-list-group>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-td>
                <b-td>
                  <div class="text-center">
                    <div class="px-0">
                      <h4>{{ pr.tarif.formule_commercial }}</h4>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <div class="pricing-basic-value font-weight-bolder text-primary mb-1" v-for="(total, keyTotal, indexTotal) in pr.tarif.totalBase" :key="indexTotal + ' - total'">
                            <sup class="font-weight-bold text-primary">€ </sup>{{ Number(total.price).toFixed(2) + ' /an (' + total.label + ')' }}
                          </div>
                        </div>
                      </div>

                      <h6> {{ pr.tarif.formule =='CI' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : pr.tarif.formule =='CI' && pr.produit.assureur_id == '2632' ?  "cotisation fixe annuelle sur l'ensemble du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '2632'? "Cotisation annuelle moyenne calculée sur le montant total du prêt" : pr.tarif.formule =='CRD' && pr.produit.assureur_id == '4' ? "Cotisation annualisée (1ère année)" : "" }} </h6> 
                      <b-button
                        v-if="!pr.tarif.presente"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-2"
                        size="sm"
                        style="border: 1px solid #7367f0;"
                        variant="white"
                        @click.prevent="selectionnerProduit(index, val)"
                      >
                        Sélectionner
                      </b-button>
                      <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" size="sm" variant="primary" @click.prevent="deselectionnerProduit(index, val)"> Déselectionner </b-button>
                    </div>
                  </div>
                </b-td>
              </b-tbody>
              <b-tfoot class="offere_tdClass">
                <b-tr>
                  <b-td colspan="7">
                    <app-collapse id="collapse-besoin" type="margin">
                      <app-collapse-item ref="tab-collapse-item-0" :is-visible="false" title="<h4 class='title-custom-wizard'><u>Détails de l’offre</u></h4>">
                        <b-row>
                          <b-col cols="12" class="mb-2" style="text-align: justify;">
                            <b-table-simple striped hover offere_thClass text-center>
                              <template v-slot:default>
                                <b-thead class="offere_thClass text-center">
                                  <b-tr v-if = "pr.produit.assureur_id == '4'">
                                    <b-th>ASSURE</b-th>
                                    <b-th>PRET</b-th>
                                    <b-th>DECES/PTIA</b-th>
                                    <b-th>ITT/IPT/IPP</b-th>
                                    <b-th>MNO</b-th>
                                    <b-th>EXO</b-th>
                                    <b-th>TAEA</b-th>
                                    <b-th>BUDGET</b-th>
                                    <b-th>BUDGET COUVERTURE DE PRET SUR 8 ANS</b-th>
                                  </b-tr>
                                  <b-tr v-else-if = "pr.produit.assureur_id == '2632'">
                                      <b-th>ASSURE</b-th>
                                      <b-th>PRET</b-th>
                                      <b-th>DECES/PTIA</b-th>
                                      <b-th>ITT/IPT</b-th>
                                      <b-th>IPP</b-th>
                                      <b-th>MNO</b-th>
                                      <b-th>ACC</b-th>
                                      <b-th>PEI</b-th>
                                      <b-th>TAEA</b-th>
                                      <b-th>BUDGET</b-th>
                                      <b-th>BUDGET COUVERTURE DE PRET SUR 8 ANS</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody v-if = "pr.produit.assureur_id == '4'">
                                  <template v-for="(group, indexDTBP) in mergedData($_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc'))">
                                    <b-tr  v-for="(detail, idx) in group.detail" :key="indexDTBP + ' - ' + idx + ' - detail tarif par pret'">
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ group.assure }}</b-td>
                                      <b-td>{{ detail.pret }}</b-td>
                                      <b-td>{{ detail.deces_ptia }}</b-td>
                                      <b-td>{{ detail.itt_ipt }}</b-td>
                                      <b-td>{{ detail.mno }}</b-td>
                                      <b-td>-</b-td>
                                      <b-td>{{ detail.taea }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget).toFixed(2) }}</b-td>
                                      <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget_couverture_de_pret_sur_8_ans).toFixed(2) }}</b-td>
                                    </b-tr>
                                  </template>
                                </b-tbody>
                                <b-tbody v-if = "pr.produit.assureur_id == '2632'">
                                    <template v-for="(group, indexDTBP) in mergedData($_.orderBy(pr.tarif.detailTarifByPret, 'is_assure', 'desc'))">
                                      <b-tr  v-for="(detail, idx) in group.detail" :key="indexDTBP + ' - ' + idx + ' - detail tarif par pret'">
                                        <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ group.assure }}</b-td>
                                        <b-td>{{ detail.pret }}</b-td>
                                        <b-td>{{ detail.deces_ptia }}</b-td>
                                        <b-td>{{ detail.itt_ipt }}</b-td>
                                        <b-td>{{ detail.ipp }}</b-td>
                                        <b-td>{{ detail.mno }}</b-td>
                                        <b-td>{{ detail.acc }}</b-td>
                                        <b-td>{{ detail.pei }}</b-td>
                                        <b-td>{{ detail.taea }}</b-td>
                                        <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget).toFixed(2) }}</b-td>
                                        <b-td v-if="idx === 0" :rowspan="group.detail.length">{{ Number(group.budget_couverture_de_pret_sur_8_ans).toFixed(2) }}</b-td>
                                      </b-tr>
                                    </template>
                                  </b-tbody>
                              </template>
                            </b-table-simple>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <span v-if = "pr.produit.assureur_id == '4'">Le budget sur 8 ans est calculé à partir des cotisations annuelles fractionnées.</span>
                          </b-col>
                        </b-row>
                      </app-collapse-item>
                    </app-collapse>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </div>
        </b-col>
      </transition>
    </b-row>
    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="getAssureursRisque(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col  lg="12">
        <app-collapse id="collapse-besoin" type="margin">
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>Lexique<u></h4>">
          <ul>
            <li> Les montants affichés au niveau des garanties, options et budgets (hors budget sur 8 ans) portent sur la durée total du prêt.</li>
            <li><feather-icon icon="MinusIcon" size="24" /> : Garantie non envisagée</li>
            <li><feather-icon icon="CheckIcon" size="24" color="green"/> : Soit la garantie est envisagée et proposée soit la garantie est incluse</li>
            <li><feather-icon icon="XIcon" size="24" color="red"/> : La garantie envisagée n’est pas proposée (ou ne l’est pas pour cet assuré/ce projet)</li>
          </ul>
            <p>Chez Harmonie mutuelle, les options (EXO, DOS/PSY) sont sélectionnées par assuré (pas de distinction par prêt). Si une option est demandée sur un prêt, elle sera demandée sur les deux prêts.</p>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    

    <b-row style="margin-top:50px;">
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep">
          Précédent
        </b-button>
        <b-button v-if="canNext" class="float-right" size="lg" variant="primary" @click="submitProduit"> Suivant </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document" :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only ok-title="Fermer" ok-variant="outline-secondary" @ok="clearDocumentDisplay">
      <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BImg,
  BModal,
  BOverlay,
  BRow,
  BTable,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BTfoot,
  BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { selectRisqueAndProduct, updateProduitTarif } from '../../../../shared/utils/TarifCalcul'

export default {
  components: {
    BOverlay,
    BRow,
    BImg,
    BCol,
    BListGroup,
    BListGroupItem,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BDropdown,
    BDropdownItem,
    AppCollapseItem,
    AppCollapse,
    BModal,
    BTfoot,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      fieldsDTBP: ['assure', 'pret', 'deces_ptia', 'itt_ipt', 'ipp', 'mno', 'acc', 'pei', 'taea', 'cotisation_annulle_1ere_annee', 'budget', 'budget_couverture_de_pret_sur_8_ans'],
      risqueLocal: [],
      produitGarantieNiveau: [],
      tarif: Math.floor(Math.random() * 100) + 60,
      loading: false,
      indexRisque: 0,
      hasProduct: false,
      showCompareTable: true,
      showLoading: false,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null,
      },
    }
  },
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['Tiers']),
    ...mapGetters(['Conjoint']),
    canNext() {
      const nbrPresente = _.filter(this.risqueLocal[0].produitsFiltred, p => p.tarif.presente)

      console.log('nbrPresente' , nbrPresente.length)
      if (nbrPresente.length >= 1) {
        return true
      }

      return false
    },
  },
  watch: {
    getItemsRisque: {
      handler() {
        this.risqueLocal = this.getItemsRisque.ItemsRisque
        this.arrayProduit()
        this.hasProduct = false
        if (this.risqueLocal.length > 0) {
          this.risqueLocal.map(risque => {
            if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
              this.hasProduct = true
            }
            this.updateOrderTarification(risque.produitsFiltred)
          })
          this.filterDocumentByFormule()
        }
      },
      immediate: true,
    },
    getSelectedRisque() {
      this.indexRisque = this.getSelectedRisque
    },
  },
  methods: {
    filterDocumentByFormule() {
      this.risqueLocal[0].produitsFiltred.forEach(function (produitFiltred) {
        if (produitFiltred.tarif.formule == "CRD") {
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CRD" || d.document.nom_custom !== "Conditions générales LGE CI";
          });
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CRD" || d.document.nom_custom !== "IPID LGE CI";
          });
        }
        else if (produitFiltred.tarif.formule == "CI") {
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CI" || d.document.nom_custom !== "Conditions générales LGE CRD" ;
          });
          produitFiltred.produit.document_assureur = produitFiltred.produit.document_assureur.filter(function (d) {
            return produitFiltred.tarif.formule !== "CI" || d.document.nom_custom !== "IPID LGE CRD";
          });
        }
      });
    },
     getAssureursRisque(produits) {
      var innerHTML = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0) {
          assureurs.push(pr.assureur.nom_com_assureur);
        }
        return assureurs;
      }, []);

      if(assureurs.length > 0)
        innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les compagnies'} : <strong>${assureurs.join(", ")}</strong>`
      
      return innerHTML
    }, 
    sumBudget(items) {
      return items.reduce((total, item) => total + item.budget, 0)
    },
    sumBudgetCoverage(items) {
      const sum = items.reduce(
          (total, item) =>
              item.budget_couverture_de_pret_sur_8_ans === '-'
                  ? total
                  : total + item.budget_couverture_de_pret_sur_8_ans,
          0
      );
      return sum !== 0 ? sum : '-'
    },
    mergedData(detailTarifByPret) {
      /// Group and sum the data by the "assure" column
      const groupedData = {}

      detailTarifByPret.forEach(item => {
        if (!groupedData[item.assure]) {
          groupedData[item.assure] = {
            assure: item.assure,
            budget: 0,
            budget_couverture_de_pret_sur_8_ans: 0,
            detail: []
          }
        }

        const group = groupedData[item.assure]
        if (item.budget == '-') { item.budget = 0 }
        if (item.budget_couverture_de_pret_sur_8_ans == '-') { item.budget_couverture_de_pret_sur_8_ans = 0 }

        group.budget += Number(item.budget) || 0
        group.budget_couverture_de_pret_sur_8_ans += Number(item.budget_couverture_de_pret_sur_8_ans) || 0

        group.detail.push(item)
      })

      return Object.values(groupedData)
    },
    selectionnerProduit(risque, produit) {
      const produitsCpt = this.risqueLocal[risque].produitsFiltred.filter(pr => pr.tarif.presente)
      if (produitsCpt.length < 3) {
        this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = true
      }
      if (produitsCpt.length == 3) {
        this.messageToast('', 'Vous ne pouvez pas sélectionner plus de 3 offres', 'danger', 'EditIcon')
      }
    },
    deselectionnerProduit(risque, produit) {
      this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = false
      this.risqueLocal[risque].produitsFiltred[produit].tarif.preconise = false
    },
    async submitProduit() {
      this.showLoading = true
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      const produisPresente = []
      await this.$http
          .put(`devis/updateFractionnementByDevis/${this.risqueLocal[this.indexRisque].devis.id}`, {fractionnement: 'ANNEE'})
          .then(async res => {
            if (res.data.success) {
              if (this.risqueLocal[this.indexRisque].produitsFiltred.length > 0) {
                this.risqueLocal[this.indexRisque].produitsFiltred.map(rlp => {
                  produisPresente.push({
                    presente: rlp.tarif.presente,
                    taux_commission: rlp.tarif.taux_commission ? rlp.tarif.taux_commission : '10',
                    prime: rlp.tarif.tarif,
                    tarification_id: rlp.tarif.tarification_id,
                  })
                })
              }
              if (produisPresente.length > 0) {
                const data = {
                  produitsPresentes: produisPresente,
                  devis_id: this.risqueLocal[this.indexRisque].devis.id,
                }
                await this.$http.post('portefeuilles/updateTarifPresente', data).then(response => {
                  if (response.data) {
                    console.log('updated')
                  }
                })
              }
              this.$store.commit('setStepTarif', {
                step: {
                  indexStep: 3,
                  active: 'resume',
                },
              })
            }
          })
          .catch(err => { console.error(err) })
          .finally(() => { this.showLoading = false })
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'profil',
        },
      })
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.risqueLocal.length > 0) {
        this.risqueLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const {tarif, ...newProduit} = produit
                const garantiesLocal = tf.garanties
                const produitsGarantiesLocal = tf.produitsgaranties
                const {garanties, ...tarifLocal} = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  produitsgaranties: produitsGarantiesLocal,
                  tarif: tarifLocal,
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            const data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets,
            }
            const tmpProduits = selectRisqueAndProduct(data)

            risque.produitsFiltred = this.sortByTauxDec(tmpProduits)
          }
        })

        this.filtreDownUp(0)
        
        this.risqueLocal[0].produitsFiltred.forEach(e => {
          e.produit.document_assureur.sort((a, b) => {
            let fa = a.document.document_type.type.toLowerCase(),
              fb = b.document.document_type.type.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }

            return 0;
          });
        })
      }
    },

    // SORT
    sortByTauxDec(data) {
      return data.sort((a, b) => b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif.tarif - b.tarif.tarif.tarif)
    },
    sortByTauxCr(data) {
      function DownUpcompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return -1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return 1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return -1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return 1
          }
        }
        return 0
      }

      data.sort(DownUpcompare)
      return data
    },
    updateOrderTarification(tarification) {
      console.log(tarification)
      let data = []
      tarification.forEach((el, index) => {
        data.push({ "tarificationId": el.tarif.tarification_id, index })
      })
      this.$http.post('portefeuilles/updateOrderTarification', data).then(response => {
        if (response.data.success) { console.log('updated') }
      }).catch(e => {console.error(e)})
    },
    filtreDownUp(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.totalBase.Assure.price - b.tarif.totalBase.Assure.price)
        this.showCompareTable = true
      }, 10)
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    filtreUpDown(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => b.tarif.totalBase.Assure.price - a.tarif.totalBase.Assure.price)
        this.showCompareTable = true
      }, 10)
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },

    // DOCUMENTS
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
          .get(`/document/generate_base64_for_document/${id}`)
          .then(res => {
            if (res.data.success) {
              this.clearDocumentDisplay()
              if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
                this.document.base64 = res.data.data.base64
                this.document.name = NomDuDocument || name
                this.document.nameToDownload = name
                this.document.extensionDocument = res.data.data.extensionDocument
                this.$refs['modal-display-document'].show()
              }
            } else {
              this.clearDocumentDisplay()
              this.messageToast(res.data.message, 'Erreur', 'error')
            }
          })
          .catch(err => {
            this.clearDocumentDisplay()
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    showFile(id, type) {
      this.displayDocument(id, type, type)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
.no-hover-bg {
  background-color: transparent !important;
}

#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}

.small-text {
  font-size: 12px;
  text-align: left;

}
</style>

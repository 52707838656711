<template>
  <div>
    <div id="addprojectform" class="vue-form-wizard mb-3 md">
      <div class="wizard-header">
        <h4 class="wizard-title"></h4>
        <p class="category"></p>
      </div>
      <div class="wizard-navigation">
        <div class="wizard-progress-with-circle">
          <div class="wizard-progress-bar" style="background-color: rgb(115, 103, 240); color: rgb(115, 103, 240); width: 10%"></div>
        </div>
        <ul class="wizard-nav wizard-nav-pills" role="tablist">
          <li class="active" step-size="md">
            <a :class="!activeStep.find(as => as.active == 'choix') || getStepTarif == 'etude' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-RISQUES0" aria-controls="RISQUES0" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="0">
                <div :class="indexStep == 'choix' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">1</i>
                </div>
              </div>
              <span :class="indexStep == 'choix' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'choix')">RISQUES</span>
            </a>
          </li>
          <li class="" step-size="md">
            <a :class="!activeStep.find(as => as.active == 'profil') || getStepTarif == 'etude' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-PROFIL1" aria-controls="PROFIL1" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="1">
                <div :class="indexStep == 'profil' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">2</i>
                </div>
              </div>
              <span :class="indexStep == 'profil' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'profil')">BESOINS</span>
            </a>
          </li>
          <li class="" step-size="md">
            <a :class="!activeStep.find(as => as.active == 'comparatif') || getStepTarif == 'etude' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-COMPARATIF2" aria-controls="COMPARATIF2" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="">
                <div :class="indexStep == 'comparatif' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">3</i>
                </div>
              </div>
              <span :class="indexStep == 'comparatif' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'comparatif')">TARIFS</span>
            </a>
          </li>
          <li class="" step-size="md">
            <a :class="!activeStep.find(as => as.active == 'resume') || getStepTarif == 'etude' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-PROPOSITIONS3" aria-controls="PROPOSITIONS3" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="">
                <div :class="indexStep == 'resume' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">4</i>
                </div>
              </div>
              <span :class="indexStep == 'resume' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'resume')">PRECONISATION</span>
            </a>
          </li>
          <li class="" step-size="md" v-if="isAnCourtierByRisque()">
            <a :class="!activeStep.find(as => as.active == 'courtier') || getStepTarif == 'etude' ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-COURTIERS4" aria-controls="COURTIERS4" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="">
                <div :class="indexStep == 'courtier' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">5</i>
                </div>
              </div>
              <span :class="indexStep == 'courtier' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'courtier')">COURTIER</span>
            </a>
          </li>
          <li class="" step-size="md">
            <a :class="!activeStep.find(as => as.active == 'etude') ? 'disabled' : 'enabled'" href="javascript:void(0)">
              <div id="step-CONFIRMATION6" aria-controls="CONFIRMATION6" aria-disabled="true" class="wizard-icon-circle md square_shape" role="tab" tabindex="">
                <div :class="indexStep == 'etude' ? 'classActive' : 'classCheck'" class="wizard-icon-container square_shape">
                  <i class="wizard-icon">
                    {{ isAnCourtierByRisque() ? '6' : '5' }}
                  </i>
                </div>
              </div>
              <span :class="indexStep == 'etude' ? 'classActiveText' : ''" class="stepTitle active" @click="switchStep(0, 'etude')">CONFIRMATION</span>
            </a>
          </li>
        </ul>
        <div style="padding: 1.42rem 1.42rem 2.14rem 1.42rem">
          <div v-show="indexStep == 'choix'">
            <Choix-risque ref="choix"></Choix-risque>
          </div>
          <div v-show="indexStep == 'profil'">
            <Saisie-info ref="profil"></Saisie-info>
          </div>
          <div v-show="indexStep == 'comparatif'">
            <Comparatif ref="comparatif"></Comparatif>
          </div>
          <div v-show="indexStep == 'resume'">
            <Resume ref="resume"></Resume>
          </div>
          <div v-if="isAnCourtierByRisque()" v-show="indexStep == 'courtier'">
            <courtier-step ref="courtier"></courtier-step>
          </div>
          <div v-show="indexStep == 'etude'">
            <DevisEtude ref="etude"></DevisEtude>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ChoixRisque from './Tarification/ChoixRisque.vue'
import SaisieInfo from './Tarification/SaisieInfo.vue'
import Comparatif from './Tarification/Comparatif.vue'
import Resume from './Tarification/Resume.vue'
import DevisEtude from './Tarification/DevisEtude.vue'
import courtierStep from './Tarification/courtierStep.vue'
import { mapGetters } from 'vuex'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BButton, BCard, BCardText, BCol, BFormCheckboxGroup, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BFormRadioGroup, BFormSelect, BFormTextarea, BListGroup, BListGroupItem, BRow, BTab, BTable, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    ChoixRisque,
    SaisieInfo,
    Comparatif,
    Resume,
    DevisEtude,
    courtierStep
  },
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getStepTarif'])
  },
  data() {
    return {
      indexStep: 'choix',
      activeStep: [
        {
          indexStep: 0,
          active: 'choix'
        }
      ],
      tarifStep: 0,
      rl: null,
      devis_avancement: null
    }
  },
  watch: {
    '$store.state.tarifModule.etude': {
      handler: function (val, oldVal) {
        this.indexStep = val.step
        this.activeStep = val.activeStep
      },
      deep: true,
      immediate: true
    },
    getItemsRisque: {
      handler: function (val, oldVal) {
        // alert('Bubble-in')
        this.rl = this.getItemsRisque.ItemsRisque[0]
        this.devis_avancement = this.rl.devis.devis_etape_avancement
      },
      // immediate: true,
      deep: true
    }
  },
  methods: {
    switchStep(index, step) {
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // })
      // this.$store.commit('setStepTarif', {
      //   step: {
      //     indexStep: index,
      //     active: step
      //   },
      // })
      // this.indexStep = step
    },
    switchStepNext(index, step) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: index,
          active: step
        }
      })
      this.indexStep = step
    },
    isAnCourtierByRisque() {
      if (this.rl) {
        switch (this.formatText(this.rl.risque.label)) {
          case 'EMPRUNTEUR':
          case 'SANTESENIORS':
          case 'SANTEACTIFS':
          case '2-ROUES':
          case 'SANTETNS':
            return true
          default:
            return false
        }
      } else {
        return false
      }
    },
    getRisqueStatut(value) {
      // console.log(this.formatText(this.rl.risque.label))
      if(this.rl){
        return value ? this.formatText(this.rl?.risque?.label) == value : ''
      }
    },
    switchStepTarif(step, index) {
      this.tarifStep = index
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: index,
          active: step
        }
      })
      this.indexStep = step
    }
  },
  beforeDestroy() {
    this.$store.dispatch('clearData')
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.box-wizard-custom {
  padding: 2rem 4.5rem 4.5rem !important;
}

// .class2{
//     background-color: #fff !important;
//     border: 2px solid;
//     border-color: #4d25bc !important;
// }
// .class2 > div{
//     background-color: rgb(115, 103, 240);
//     color: #4d25bc !important;
//     background-color: #fff !important;
// }
// .class2 > div > div{
//     color: #4d25bc !important;
// }

// .class3{
//     background-color: #fff;
//     border: 2px solid;
// }
// .class3 .child{
//     background-color: rgb(115, 103, 240);
//     background-color: #fff !important;
// }
// .class3 .icon{
//     color: #4d25bc !important;
// }
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.classCheck {
  border-color: rgb(115, 103, 240);
  background-color: rgba(115, 103, 240, 0.08);
}

.classCheck > i {
  color: #4d25bc !important;
}

.classActive {
  border-color: #4d25bc;
  background-color: #4d25bc;
}

.classActive > i {
  color: #fff !important;
}

.classActiveText {
  color: #4d25bc !important;
}
</style>

<template>
  <div>
    <b-overlay
      :show="showLoading"
      no-wrap
    />

    <b-row>
      <b-col
        cols="8"
        class="mb-2"
      >
        <h2 class="mb-0 text-uppercase font-weight-bolder">
          étape 1 : Choix du risque
        </h2>
        <h6>
          Sélectionnez le risque pour lequel vous souhaitez créer un devis
        </h6>
      </b-col>
      <b-col
        cols="4"
        class="text-right"
      >
        <b-button
          variant="primary"
          size="lg"
          :disabled="disabled"
          @click="submitCheckedRisque"
        >
          Suivant
        </b-button>
      </b-col>
    </b-row>
    <b-row class="px-0">
      <b-col
        md="12"
        lg="3"
      >
        <b-card class="mb-3">
          <h4 class="text-primary font-weight-bolder">
            <b>SANTÉ / PRÉVOYANCE</b>
          </h4>
          <b-card-text>
            {{ getCountByRisque('Santé') }} solution(s)
          </b-card-text>
          <h4 class="text-primary font-weight-bolder">
            <b>IARD</b>
          </h4>
          <b-card-text>
            {{ getCountByRisque('Rou') }} solution(s)
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        md="12"
        lg="9"
      >
        <b-card style="margin-bottom:20%">
          <b-row id="box-custom-modale-sm">
            <b-col
              cols="12"
              class="mb-2 mt-2"
            >
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                SANTÉ / PRÉVOYANCE :
              </h4>
            </b-col>

            <b-col
              md="4"
              @click="changeRisque('Santé TNS')"
            >
              <label
                :class="[checkedRisque[0]=='Santé TNS' ? 'btn-primary' : '' , canShowRisque('Santé TNS') ? 'btn-outline-primary-enabled' : 'btn-outline-primary-disabled custom-disabled']"
                class="btn btn-outline-primary   box-custom-modale-child w-100 custom-label"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  ><path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z"
                  /></svg>
                  SANTE TNS
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ risques_info[0].assureurs_count }}
                {{ risques_info[0].assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ risques_info[0].formules }}
                {{ risques_info[0].formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>

            <b-col
              md="4"
              @click="changeRisque('Santé Séniors')"
            >
              <label
                :class="[checkedRisque[0]=='Santé Séniors' ? 'btn-primary' : '' , canShowRisque('Santé Séniors') ? 'btn-outline-primary-enabled' : 'btn-outline-primary-disabled custom-disabled']"
                class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  ><path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z"
                  /></svg>
                  SANTE SENIOR
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ risques_info[1].assureurs_count }}
                {{ risques_info[1].assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ risques_info[1].formules }}
                {{ risques_info[1].formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>
            <b-col
              md="4"
              @click="changeRisque('Santé Actifs')"
            >
              <label
                :class="[checkedRisque[0]=='Santé Actifs' ? 'btn-primary' : '' , canShowRisque('Santé Actifs') ? 'btn-outline-primary-enabled' : 'btn-outline-primary-disabled custom-disabled']"
                class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  ><path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z"
                  /></svg>
                  SANTÉ ACTIF
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ risques_info[2].assureurs_count }}
                {{ risques_info[2].assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ risques_info[2].formules }}
                {{ risques_info[2].formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>
  
          </b-row>
          <b-row id="box-custom-modale-sm">
            <b-col
              cols="12"
              class="mb-2 mt-2"
            >
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                EMPRUNTEURS :
              </h4>
            </b-col>
            <b-col
              md="4"
              @click="changeRisque('Emprunteur')"
            >
              <label
                :class="[checkedRisque[0]=='Emprunteur' ? 'btn-primary' : '' , canShowRisque('Emprunteur') ? 'btn-outline-primary-enabled' : 'btn-outline-primary-disabled custom-disabled']"
                class="btn btn-outline-primary  box-custom-modale-child w-100 custom-label"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  ><path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 3C4.239 3 2 5.216 2 7.95c0 2.207.875 7.445 9.488 12.74a.985.985 0 0 0 1.024 0C21.125 15.395 22 10.157 22 7.95C22 5.216 19.761 3 17 3s-5 3-5 3s-2.239-3-5-3Z"
                  /></svg>
                  EMPRUNTEUR
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ risques_info[3].assureurs_count }}
                {{ risques_info[3].assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ risques_info[3].formules }}
                {{ risques_info[3].formules > 1 ? 'formules' : 'formule' }}</p>

            </b-col>
          </b-row>
          <b-row id="box-custom-modale-sm">
            <b-col
              cols="12"
              class="mb-2 mt-2"
            >
              <h4 class="mb-0 text-uppercase text-primary font-weight-bolder text-underline">
                IARD :
              </h4>
            </b-col>

            <b-col
              md="4"
              @click="changeRisque('2-Roues')"
            >
              <label
                :class="[checkedRisque[0]=='2-Roues' ? 'btn-primary' : '' , canShowRisque('2-Roues') ? 'btn-outline-primary-enabled' : 'btn-outline-primary-disabled custom-disabled']"
                class="btn btn-outline-primary   box-custom-modale-child w-100 custom-label"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 2304 1280"
                  >
                    <path
                      fill="currentColor"
                      d="M2301 780q12 103-22 198.5t-99 163.5t-158.5 106t-196.5 31q-161-11-279.5-125T1411 880q-12-111 27.5-210.5T1557 499l-71-107q-96 80-151 194t-55 244q0 27-18.5 46.5T1216 896H891q-23 164-149 274t-294 110q-185 0-316.5-131.5T0 832t131.5-316.5T448 384q76 0 152 27l24-45Q501 256 320 256h-64q-26 0-45-19t-19-45t19-45t45-19h128q78 0 145 13.5T645.5 180t71.5 39.5t51 36.5h627l-85-128h-222q-30 0-49-22.5T1025 53q4-23 23-38t43-15h253q33 0 53 28l70 105l114-114q19-19 46-19h101q26 0 45 19t19 45v128q0 26-19 45t-45 19h-179l115 172q131-63 275-36q143 26 244 134.5T2301 780zM448 1152q115 0 203-72.5T762 896H448q-35 0-55-31q-18-32-1-63l147-277q-47-13-91-13q-132 0-226 94t-94 226t94 226t226 94zm1408 0q132 0 226-94t94-226t-94-226t-226-94q-60 0-121 24l174 260q15 23 10 49t-27 40q-15 11-36 11q-35 0-53-29l-174-260q-93 95-93 225q0 132 94 226t226 94z"
                    /></svg>
                  2 ROUES
                </span>
              </label>
              <p class="text-center font-weight-bolder">{{ risques_info[4].assureurs_count }}
                {{ risques_info[4].assureurs_count > 1 ? 'assureurs' : 'assureur' }} / {{ risques_info[4].formules }}
                {{ risques_info[4].formules > 1 ? 'formules' : 'formule' }}</p>
            </b-col>
          </b-row>

        </b-card>
        <b-row class="float-right ">
          <b-col lg="4">
            <b-button
              variant="primary"
              size="lg"
              :disabled="disabled"
              @click="submitCheckedRisque"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BOverlay,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
  },
  data() {
    return {
      risquePrevSanteDisabled: [
        { text: 'SANTÉ SENIOR', value: 'Santé sénior' },
        { text: 'SANTÉ INDIVIDUELLE', value: 'Santé individuelle' },
        { text: 'PRÉVOYANCE TNS', value: 'Prévoyance TNS' },
      ],
      risquePrevSante: [
        { text: 'SANTÉ TNS', value: 'Santé TNS' },
      ],
      risqueIard: [
        { text: 'SANTÉ ANIMAUX', value: 'Santé animaux' },
        { text: 'MRH', value: 'Mrh' },
        { text: 'AUTO', value: 'Auto' },
        { text: '2-ROUES', value: '2-roues' },

      ],
      risqueEpargneRetraite: [
        { text: 'ASSURANCE VIE', value: 'Assurance vie' },
        { text: 'PERCO', value: 'Perco' },
        { text: 'PEE', value: 'PEE' },
        { text: 'PERP', value: 'PERP' },
      ],
      risqueEmprunteur: [
        { text: 'EMPRUNTEUR', value: 'Emprunteur' },
      ],
      risquesApi: {
        adp: [],
        iard: [],
        epargneRetraite: [],
      },
      risquesApiDiff: {
        adp: [],
        iard: [],
        epargneRetraite: [],
      },
      checkedRisque: [],
      checkedBesoin: {},
      prospect: null,
      garantiesLocal: [],
      produitsGarantiesLocal: [],
      exigencesLocal: [],
      risquesWithChampsLocal: [],
      besoinsLocal: [],
      risquesChamps: [],
      besoinsLocalFiltre: {},
      emptyRisque: false,
      showLoading: false,
      activeStepLocal: [
        { indexStep: 0, active: 'choix' },
        { indexStep: 1, active: 'profil' },
        { indexStep: 2, active: 'comparatif' },
        { indexStep: 3, active: 'resume' },
        { indexStep: 4, active: 'etude' },
      ],
      produitAutorise: [],
      disabled: true,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      risques_info: [],
    }
  },
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getEtude']),
    ...mapGetters(['getCheckedRisque']),
  },
  watch: {
    'getCheckedRisque.checkedRisque': {
      handler(val) {
        if (val) {
          val.forEach(cr => {
            if (cr.label) {
              this.checkedRisque.push(cr.label)
              this.changeRisque(this.checkedRisque[0])
            }
          })
        }
      },
      immediate: true,
    },
    checkedRisque: {
      handler(val) {
        if (val.length > 1) this.checkedRisque.shift()
      },
      deep: true,
    },
  },
  created() {
    this.$http.get('garanties/listGarantieProduit').then(res => { this.garantiesLocal = res.data })
    this.$http.get('exigences/getExigences').then(res => { this.exigencesLocal = res.data })
    this.$http.get('produit/getProduitAutoriseByRisque').then(res => { this.produitAutorise = res.data })
    this.getRisqueInfoAssureurFormules()
  },
  mounted() {
    if (this.$route.params.id) {
      this.getEtudeData(this.$route.params.id)
    } else {
      this.prospect = this.getEtude.Tiers
    }
  },
  methods: {
    changeRisque(value) {
      if (this.canShowRisque(value)) {
        this.disabled = false
        this.checkedRisque = []
        this.checkedRisque[0] = value
      }
    },
    getCountByRisque(risque) {
      if (this.produitAutorise.length > 0) {
        let count = this.produitAutorise.filter(el => el.includes(risque)).length
        if (risque === 'Santé') {
          count++
        }

        return count
      }

      // Return 0 if produitAutorise is empty
      return 0
    },

    getEtudeData(etude) {
      this.showLoading = true
      this.$http
        .get(`portefeuilles/getEtude/${etude}`)
        .then(r => {
          this.updateTarifModule(r.data.data)
          this.prospect = this.Tiers
          this.$store.commit('setChangedRisque', {})
        })
        .catch(e => {
          this.showLoading = false
          console.log(e)
        })
    },
    updateTarifModule(data) {
      const tmpProspect = data.Tiers
      tmpProspect.date_naissance = tmpProspect.date_naissance ? moment(tmpProspect.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
      this.$store.state.tarifModule.etude = {
        etudeID: data.etudeID,
        etudeNom: data.etudeNom,
        created_at: data.created_at,
        etude_no: data.etude_no,
        etude_date: data.etude_date,
        ipid: data.ipid,
        cg: data.cg,
        tg: data.tg,
        Tiers: tmpProspect,
        checkedRisque: data.checkedRisque,
        itemsRisque: data.itemsRisque,
        Conjoint: data.Conjoint,
        Enfant: data.Enfant,
        Parent: data.Parent,
        Ami: data.Ami,
        Entreprise: data.Entreprise,
        changedRisque: false,
        step: data.itemsRisque.ItemsRisque.length > 0 ? 'profil' : 'choix',
        etudeAvancement: null,
        conseilpath: {
          path: null,
          document: null,
        },
        selectedRisque: 0,
        activeStep: data.itemsRisque.ItemsRisque.length > 0 ? this.getActiveStep('profil') : this.getActiveStep('choix'),
      }
      this.showLoading = false
    },
    getActiveStep(value) {
      const arrayStep = []
      const step = _.find(this.activeStepLocal, as => as.active == value)
      if (step) {
        this.activeStepLocal.forEach(st => {
          if (st.indexStep <= step.indexStep) {
            arrayStep.push(st)
          }
        })
      }

      return arrayStep
    },
    async addNewRisqueItem(cr, temp) {
      let risque = null

      await this.$http.get(`risques/findByLabel/${cr}`).then(res => {
        risque = {
          id: res.data.id,
          label: res.data.label,
          type: res.data.type,
        }
      })

      const garanties = []
      this.garantiesLocal.map((gl, key) => {
        if (gl.risque.label === risque.label) {
          let foundgnNiveauValue = []
          if (key !== 0) {
            const foundGnNiveau = gl.garantieniveaux.find(gn => gn.garantie_niveau_libel == 'Non')
            foundgnNiveauValue = foundGnNiveau ? [foundGnNiveau.id] : []
          } else {
            foundgnNiveauValue = []
          }
          garanties.push({
            id: gl.id,
            label: gl.label,
            valChamp: 'non',
            garantieNiveauID: foundgnNiveauValue,
            garantieniveaux: gl.garantieniveaux,
            produit_garantie: gl.produit_garantie ? gl.produit_garantie : null,
          })
        }
      })

      const produit_garanties = []
      await this.$http.get(`produits_garanties/fetchProduitsGaranties/${garanties[0].produit_garantie[0].produit_id}`)
        .then(res => {
          this.produitsGarantiesLocal = res.data
        })
        .catch(err => {
          console.error(err)
        })
      this.produitsGarantiesLocal.forEach(element => {
        let foundpgnNiveauValue = []
        let foundPGnNiveau = ''
        if (risque.label === '2-Roues') {
          const listChecker = ['nonEQU', 'EQU1*', 'RAFRx', 'RAFRy']
          foundPGnNiveau = element.produitgarantiesniveaux.find(pgn => listChecker.includes(pgn.libelle))
          foundpgnNiveauValue = foundPGnNiveau ? [foundPGnNiveau.id] : []
        }

        produit_garanties.push({
          id: element.id,
          garantie_id: element.garantie_id,
          libelle: element.libelle,
          libelle_comm: element.libelle_comm,
          produit_id: element.produit_id,
          produitGarantieNiveauID: foundpgnNiveauValue,
          produitgarantiesniveaux: element.produitgarantiesniveaux,
          created_at: element.created_at,
          updated_at: element.updated_at,

        })
      })

      const exigences = []
      this.exigencesLocal.map(el => {
        if (el.risque.label === risque.label) {
          exigences.push({
            id: el.id,
            label: el.label,
            exigence_details: el.exigence_details,
            exigence_produits_details: this.mapExigencesProduitsDetails(el.exigence_produits_details),
            exigenceNiveauID: [],
            exigenceniveaux: el.exigenceniveaux,
          })
        }
      })

      temp.push({
        risque: {
          id: risque.id,
          label: risque.label,
          type: risque.type,
        },
        devis: null,
        objets: [],
        errors: [],
        villes: [],
        produits: [],
        produitsFiltred: [],
        garanties,
        produit_garanties,
        garantiesError: false,
        exigences,
      })
      return temp
    },
    mapExigencesProduitsDetails(exProduits) {
      const exigencesProduitsDetails = []
      exProduits.map(exp => {
        exigencesProduitsDetails.push({
          produit_id: exp.produit_id,
          info: exp.pivot.info,
        })
      })

      return exigencesProduitsDetails
    },
    async recordData() {
      if (!this.getEtude.etudeID) {
        const etudeData = {}
        const etudeNom = `Devis ${this.checkedRisque.map(cr => cr).join(' ')
        } Pour ${this.prospect.nom ? this.prospect.nom : this.prospect.denomination_commercial}`
        etudeData.prospect = this.prospect
        etudeData.etude = etudeNom

        const response = (await this.$http.post('portefeuilles/initEtude', etudeData)).data

        this.getEtude.etudeID = response
        this.getEtude.etudeNom = etudeNom

        this.$http.get(`portefeuilles/getEtudeSingle/${response}`).then(res => {
          this.getEtude.etude_no = res.data.data.etude_no
          this.getEtude.created_at = res.data.data.created_at
        }).catch(e => {
          console.log(e)
        })
      }
      const temp = []
      const getData = async () => Promise.all(this.checkedRisque.map(cr => this.addNewRisqueItem(cr, temp)))

      getData().then(() => {
        const data = {
          risques: temp,
          prospect: this.prospect,
          etudeID: this.getEtude.etudeID,
          fractionnement: this.fractionnementByRisque()
        }

        this.$http.post('portefeuilles/getObjectPortefeuille', data).then(res => {
          for (let i = 0; i < temp.length; i++) {
            temp[i].devis = res.data[0][i].devis
            temp[i].objets = res.data[0][i].objets
            temp[i].produits = res.data[0][i].produits
          }
          this.$store.commit('setItemsRisque', { ItemsRisque: temp })
          this.$store.commit('setObjetEnfant', { Enfant: res.data[1] })
          this.$store.commit('setObjetParent', { Parent: res.data[4] })
          this.$store.commit('setObjetAmi', { Ami: res.data[5] })
          this.$store.commit('setObjetConjoint', { Conjoint: res.data[2] })
          this.$store.commit('setObjetEntreprise', { Entreprise: res.data[3] })
          this.$store.commit('setcheckedRisque', { checkedRisque: this.checkedRisque })
          this.$store.commit('setEtudeAvancement', { etudeAvancement: 'PROFIL' })
          this.$store.commit('setStepTarif', { step: { indexStep: 1, active: 'profil' }})

          this.showLoading = false
        }).catch(() => {
          this.showLoading = false
        })
      })
    },
    fractionnementByRisque() {
      switch (this.checkedRisque[0]) {
        case 'Santé Séniors':
        case 'Santé TNS':
        case '2-Roues':
          return 'MOIS'
        case 'Emprunteur':
          return 'ANNEE'
        default:
          return 'MOIS'
      }
    },
    submitCheckedRisque() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.showLoading = true
      if (this.getEtude.Tiers.categorie.toUpperCase() == 'PARTICULIER' && this.checkedRisque[0] == 'Santé TNS') {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Le risque Santé TNS étant dédié aux professionnels, pour réaliser un devis, nous devons modifier la catégorie de votre client/prospect (actuellement enregistré comme particulier).
                <br><br>
                Souhaitez-vous poursuivre ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            html: 'text-left',
          },
          buttonsStyling: false,
        })
          .then(confirmed => {
            if (confirmed.value) {
              this.$http.put(`prospect/setCategorieProspect/${this.prospect.id}/${this.prospect.type}/PROFESSIONNEL`)
                .then(res => {
                  if (res.data.success) {
                    this.prospect.categorie = 'PROFESSIONNEL'
                    this.$store.commit('setProspect', { Tiers: this.prospect })
                    this.recordData()
                  }
                })
                .catch(err => {
                  console.error(err)
                })
            }
          })
          .catch(() => {
            this.showLoading = false
          }).finally(() => {
            this.showLoading = false
          })
      } else if (this.getEtude.Tiers.categorie.toUpperCase() == 'PROFESSIONNEL' && this.checkedRisque[0] == 'Santé TNS') {
        this.prospect.categorie = 'PROFESSIONNEL'
        this.$store.commit('setProspect', { Tiers: this.prospect })                
        this.recordData()
      } else {
        this.recordData()
      }
    },
    canShowRisque(risque) {
      if (this.produitAutorise.length > 0) {
        const found = this.produitAutorise.some(el => el === risque)
        return found
      }

      return false // Return false if produitAutorise is empty
    },
    getRisqueInfoAssureurFormules() {
      if (!this.currentUser.risques_info || this.currentUser.risques_info.length === 0) {
        this.$http('risques/getInfoAssureurFormules').then(res => {
          this.risques_info = res.data
          this.currentUser.risques_info = this.risques_info
          localStorage.setItem('userData', JSON.stringify(this.currentUser))
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.risques_info = this.currentUser.risques_info
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

// div#risque_prevoyance > .btn-outline-primary.disabled, [dir] .btn-outline-primary:disabled {
//     background-color: #cdcdcd;
// }

.custom-disabled {
  background-color: #f1efef;
}
</style>

<style lang="scss" scoped>

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard
.wizard-navigation
.wizard-nav
li
.wizard-icon-circle
.wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard
.wizard-navigation
.wizard-nav
li.active
a
.checked
~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

#box-custom-modale > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
  background-color: #fff;
}

#box-custom-modale-sm > .box-custom-modale-child {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
  background-color: #fff;
}

#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}

#box-custom-modale > .box-custom-modale-child.active {
  background-color: #4d25bc;
  color: #fff;
}

#box-custom-modale-sm .btn-outline-primary-enabled:hover {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#box-custom-modale-sm .btn-outline-primary-disabled:hover {
  background-color: #f1efef;
  transition: 0.4s;
}

#box-custom-modale-sm .btn-outline-primary-disabled {
  border: none !important;
}

.custom-label {
  margin: 1rem;
  padding: 25px 15px;
}

</style>

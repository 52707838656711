<template>
    <div style="margin-top:30px">
      <b-overlay :show="showLoading" no-wrap />
      <validation-observer ref="AssureRules">
      <b-form>
       <app-collapse
          id="collapse-besoin"
          type="margin"
        >
        <b-row>
          <b-col md="12">
            <b-alert variant="danger" show v-show="errorsTarif.length > 0" class="mt-1">
              <div class="alert-body">
                <ul>
                  <li v-for="(error, index) in errorsTarif" :key="index">
                    <strong v-if="error.attribut != 'ERREUR_CRM'">{{ error.attribut }} :</strong>
                    {{ error.message }}
                  </li>
                </ul>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
            <b-form-group>
              <label class="float-left pr-4 mb-1">Cette souscription s'effectue-t-elle dans le cadre d'une vente à distance ?</label>
              <b-form-radio-group v-model="devis.is_a_distance" :options="optionsVenteDistance" name="radio-vente-a-distance" />
            </b-form-group>

            <b-form-group v-if="devis.is_a_distance">
              <label class="float-left pr-4 mb-1">De quel type de vente s'agit-il ?</label>
              <validation-provider #default="{ errors }" name="type de vente" rules="required">
                <b-form-radio-group v-model="devis.type_vente" name="radio-type-de-vente" :state="errors.length > 0 ? false : null">
                  <b-form-radio :value="option.value" v-for="option in optionsTypeVente" :key="option.value">
                    {{ option.text }}
                    <span v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </b-form-radio>
                </b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <label class="float-left pr-4 mb-1">Le proposant souhait-il souscrire dans le cadre de la loi Hamon ?</label>
              <validation-provider #default="{ errors }" name="loi hamon" rules="required">
                <b-form-radio-group v-model="conducteur.hamon" :options="optionsVenteDistance" name="radio-loi-hamon" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-alert variant="danger" show v-show="conducteur.hamon" class="mb-2">
              <div class="alert-body">
                <p>
                  <strong>Attention :</strong> la Loi Hamon ne concerne que les contrats souscrits depuis plus d’un an. La date d'effet du nouveau contrat doit être au plus tôt le <strong>{{ dateHamon }}</strong>.
                  </br>
                  Si le contrat a moins d'un an, l’assuré doit procéder lui même à la résiliation de son contrat à l'échéance anniversaire, en respectant les délais de résiliation prévus au contrat.
                  </br>
                  Merci de vérifier et modifier le cas échéant l’option et la date renseignées ci-dessus.
                </p>
              </div>
            </b-alert>
          </b-col>
          <b-col md="3">
            <b-form-group label="Date d'effet " label-for="date-effet">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required" ref="dateEffet">
              <flat-pickr
                class="form-control"
                :class="errors.length > 0 ? 'is-invalid' : ''"
                v-model="objetMoto.date_effet"
                id="date-effet"
                :config="configDateEffet"
                placeholder="Date d'effet"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Le véhicule -->
        <app-collapse-item ref="tab-collapse-item-0" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Le véhicule</u></h4>">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="4">
                    <validation-provider #default="{ errors }" name="modèle" :rules="`requiredModele:${JSON.stringify(initialState().conducteur.vehicule.modele)}`">
                        <b-form-group label="Modèle " label-for="modele_vehicule" :class="errors.length > 0 ? 'is-invalid' : ''">
                          <v-select v-model="conducteur.vehicule.modele" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :state="errors.length > 0 ? false : null" :filterable="false" :options="modelesOptions" autocomplete @search="autocompleteModeles" label="modele" :reduce="modele => mapModele(modele)" placeholder="Saisissez votre véhicule (Marque, cylindrée, modèle)">
                            <template #option="{ recherche }">
                              <span class="ml-50">{{ recherche }}</span>
                            </template>

                            <template #selected-option="{ marque,modele,cylindree }">
                              <span class="ml-50" v-if="marque && modele"> {{marque +" "+cylindree+" "+ modele }}</span>
                              <span class="ml-50" v-else> </span>
                            </template>
                            <div slot="no-options">Aucun modèle disponible.</div>
                          </v-select>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Date de 1ère mise en circulation " label-for="date-premier-circulation">
                    <validation-provider #default="{ errors }" name="date de 1ère mise en circulation" rules="required">
                      <flat-pickr id="date-premier-circulation" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.vehicule.date_premier_circulation"  class="form-control" placeholder="Date de 1ère mise en circulation" rules="required" autocomplete="off" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Date d’acquisition / livraison du véhicule " label-for="date-acquisition">
                    <validation-provider #default="{ errors }" name="date d'acquisition/livraison" rules="required">
                      <flat-pickr id="date-acquisition" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.vehicule.date_acquisition"  class="form-control" placeholder="Date d’acquisition / livraison du véhicule" rules="required" autocomplete="off" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-row>
                <b-col md="8">
                  <b-form-group>
                    <label class="mb-1 mr-1 float-left">Mode d’acquisition </label>
                    <validation-provider #default="{ errors }" name="mode d'acquisition" rules="required">
                      <b-form-radio-group v-model="conducteur.vehicule.mode_acquisition" name="radio-mode-acquisition" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                        <b-form-radio :value="option.value" v-for="option in optionsModeAcquisition" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label class="mb-1 mr-1 float-left">Usage du véhicule </label>
                    <validation-provider #default="{ errors }" name="usage du véhicule" rules="required">
                      <b-form-radio-group name="radio-usage-vehicule" v-model="conducteur.vehicule.usage_vehicule" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                        <b-form-radio :value="option.value" v-for="option in optionsUsageVehicule" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-b-popover.top.hover.html="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-row>
                <b-col md="7">
                  <b-form-group>
                    <label class="mb-1 mr-1 float-left">Le titulaire de la carte grise est  <b class="text-lowercase">{{ getTextOfTitulaire(conducteur.vehicule.titulaire_carte_grise) }}</b></label>
                  </b-form-group>
                  <div v-show="false">
                    <b-form-select   v-model="conducteur.vehicule.titulaire_carte_grise" :options="TitulaireCarteGriseOptions" :disabled="checkModeAcquisition" class="d-inline-block w-50" />
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="py-1" v-if="checkVehiculeUsageParticulier">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                  <validation-provider #default="{ errors }" name="isUsage" rules="required">
                    <b-form-checkbox id="is_usage_pp" v-model="conducteur.vehicule.is_usage_pp" :value="1" :unchecked-value="null">
                    En cochant cette case, vous reconnaissez que <strong>l'usage "privé"</strong> que vous avez
                        sélectionné est autorisé uniquement pour un véhicule appartenant à une personne physique. Si le
                        véhicule appartient à une société (titulaire et/ou co-titulaire de la carte grise), vous devez
                        impérativement choisir <strong>l'usage "Professionnel (avec véhicule de société)"</strong>.
                    </b-form-checkbox>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">La case à cocher est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-row>
                <b-col md="8">
                  <b-form-group>
                    <label class="mb-1 float-left mr-1">Type de garage </label>
                    <validation-provider #default="{ errors }" name="type de garage" rules="required">
                      <b-form-radio-group v-model="conducteur.vehicule.type_garage" name="radio-type-garage" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                        <b-form-radio :value="option.value" v-for="option in optionsTypeGarage" :key="option.value" class="mt-0">
                            {{ option.text }}
                            <span v-if="option.infoBool" v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                              <feather-icon icon="InfoIcon" size="20" />
                            </span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" class="pt-1">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Code postal de stationnement " label-for="code-postal-stationnement">
                    <validation-provider #default="{ errors }" name="code postal de stationnement" rules="required|length:5">
                      <cleave id="code-postal-stationnement" v-model="conducteur.vehicule.code_postal_stationnement" :class="errors.length > 0 ? 'is-invalid' : ''" :options="options.codePostal" :raw="false" class="form-control" placeholder="Code postal de stationnement" type="number" />                    
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Ville de stationnement " label-for="ville-stationnement">
                  <validation-provider #default="{ errors }" name="ville de stationnement" rules="required">
                    <b-form-select v-model="conducteur.vehicule.ville_stationnement" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                      <b-form-select-option :value="null">Choisissez</b-form-select-option>
                      <b-form-select-option v-for="ville in villeOptions" :value="ville.ville" :key="ville.id">{{ ville.ville }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="9" v-if="hasDateAcquisitionToday">
                  <b-row class="align-items-center">
                    <b-col md="5" class="py-3">
                      <b-form-group class="mb-0">
                        <label class="float-left pr-4">Le véhicule est-il assuré actuellement ?</label>
                        <validation-provider v-slot="{ errors }" name="véhicule à assurer" rules="required" vid="is_vehicule_a_assurer">
                          <b-form-radio-group v-model="conducteur.vehicule.is_assure" id="vehicule_assurer_option" :options="BooleanValue" label="Véhicule à assurer"></b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="conducteur.vehicule.is_assure != null && !conducteur.vehicule.is_assure">
                      <b-form-group label="Depuis quand n’est-il pas assuré ?" label-for="date-non-assurer">
                        <validation-provider #default="{ errors }" name="date depuis quand" rules="required">
                          <flat-pickr id="date-non-assurer" @on-change="checkDateQAssure" :class="(errors.length > 0 || errorDateQAssure) ? 'is-invalid' : ''" :config="configdateqAssure" v-model="conducteur.vehicule.duree_non_assure"  class="form-control" placeholder="Date" rules="required" autocomplete="off" />
                          <b-form-invalid-feedback :state="(errors.length > 0 || errorDateQAssure) ? false : null"> {{ errorDateQAssure ? errorDateQAssure : 'Le champ est obligatoire' }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" v-if="checkDateExpirationAssure && !conducteur.vehicule.is_assure">
              <b-row class="align-items-center">
                <b-col md="5">
                  <b-form-group>
                    <label for="vehicule-expertise" class="mb-1 float-left mr-1">Le véhicule a-t-il été expertisé ?</label>
                    <validation-provider #default="{ errors }" name="vehicule-expertise" rules="required">
                      <b-form-radio-group v-model="conducteur.vehicule.is_expertise" name="radio-vehicule-expertise" :state="errors.length > 0 ? false : null">
                        <b-form-radio :value="option.value" v-for="option in BooleanValue" :key="option.value">
                            {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </app-collapse-item>
        <!-- Le souscripteur -->
        <app-collapse-item ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Le souscripteur</u></h4>">
          <b-row>
              <b-col md="12">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Civilité " label-for="assure_civilite">
                        <validation-provider #default="{ errors }" name="civilité" rules="required">
                          <b-form-select v-model="souscripteur.civilite" :options="civilite" id="assure_civilite" :state="errors.length > 0 ? false : null" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Nom " label-for="assure_nom">
                        <div class="form-control" readonly disabled v-if="souscripteur.statut_gda==='VERT' || souscripteur.statut_gda==='ROUGE' || souscripteur.statut_gda==='ORANGE' ">{{ souscripteur.nom }}</div>
                        <validation-provider v-else #default="{ errors }" name="nom" rules="required">
                          <b-form-input v-model="souscripteur.nom" :state="errors.length > 0 ? false : null" id="assure_nom" class="text-capitalize" placeholder="Nom" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Nom de naissance " label-for="assure_nom_naissance">
                        <validation-provider #default="{ errors }" name="nom de naissance" rules="required">
                          <b-form-input v-model="souscripteur.nom_naissance" :state="errors.length > 0 ? false : null" id="assure_nom_naissance" class="text-capitalize" placeholder="Nom de naissance" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Prénom " label-for="assure_prenom">
                        <div class="form-control" readonly disabled v-if="souscripteur.statut_gda==='VERT' || souscripteur.statut_gda==='ROUGE' || souscripteur.statut_gda==='ORANGE' ">{{ souscripteur.prenom }}</div>
                        <validation-provider v-else #default="{ errors }" name="prénom" rules="required">
                          <b-form-input v-model="souscripteur.prenom" :state="errors.length > 0 ? false : null" id="assure_prenom" class="text-capitalize" placeholder="Prénom" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Date de naissance " label-for="date-naissance">
                        <div class="form-control" readonly disabled v-if="souscripteur.statut_gda==='VERT' || souscripteur.statut_gda==='ROUGE' || souscripteur.statut_gda==='ORANGE' ">{{ souscripteur.date_naissance }}</div>
                        <validation-provider v-else #default="{ errors }" name="date de naissance" rules="required">
                          <flat-pickr id="date-naissance" v-model="souscripteur.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date de naissance" autocomplete="off" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                          <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null"> {{ errorDateNaissanceProspect }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Code postal de naissance " label-for="code-postal-naissance">
                        <validation-provider #default="{ errors }" name="code postal de naissance" rules="required">
                          <b-form-input id="code-postal-naissance" v-model="souscripteur.code_postal_naissance" :class="errors.length > 0 ? 'is-invalid' : ''" type="text" :raw="false" class="text-uppercase" placeholder="Code postal de naissance" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Commune de naissance " label-for="commune-naissance">
                        <validation-provider #default="{ errors }" name="commune de naissance" rules="required">
                          <b-form-input v-model="souscripteur.commune_naissance" :state="errors.length > 0 ? false : null" class="text-capitalize" id="commune-naissance" placeholder="Commune de naissance" type="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <validation-provider #default="{ errors }" name="pays de naissance" rules="required">
                        <b-form-group label="Pays de naissance " label-for="pays-naissance" :class="errors.length > 0 ? 'is-invalid' : ''">
                          <v-select v-model="souscripteur.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" :state="errors.length > 0 ? false : null" input-id="pays" label="pays" placeholder="Pays de naissance">
                            <template #option="{ pays }">
                              <span class="ml-50"> {{ pays }}</span>
                            </template>

                            <template #selected-option="{ pays }">
                              <span class="ml-50"> {{ pays }}</span>
                            </template>
                            <div slot="no-options">Aucune pays disponible.</div>
                          </v-select>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3" v-if="conducteur.s_is_conducteur">
                      <b-form-group label="Situation familiale " label-for="situation_familiale">
                        <validation-provider v-slot="{ errors }" name="situation familiale" rules="required" vid="situation_familiale">
                          <b-form-select v-model="souscripteur.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3" v-if="conducteur.s_is_conducteur">
                      <b-form-group label="Activité professionnelle " label-for="activite_professionnelle">
                        <validation-provider v-slot="{ errors }" name="activité professionnelle du souscripteur" rules="required">
                          <b-form-select v-model="souscripteur.statut"  :options="ActiviteProfessionnelle" id="activite_professionnelle" :state="errors.length > 0 ? false : null" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" class="pt-1">
                      <b-form-group class="mb-0">
                        <label class="float-left pr-4 mb-2">Le souscripteur est-il le conducteur ?</label>
                        <validation-provider v-slot="{ errors }" name="is conducteur" rules="required" vid="is_conducteur">
                          <b-form-radio-group id="is_conducteur_option" v-model="conducteur.s_is_conducteur" :options="BooleanValue" @change="toggleIsSouscipteur"></b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
            </b-col>
          </b-row>
        </app-collapse-item>
        <!-- Le conducteur -->
        <app-collapse-item ref="tab-collapse-item-2" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Le conducteur</u></h4>">
        <b-row>
          <b-col md="12">
            <b-row v-if="!conducteur.s_is_conducteur">
              <b-col md="12">
                <b-form-group>
                  <label class="mr-1 float-left mt-1">Quel est le lien du conducteur avec le souscripteur ?</label>
                  <validation-provider #default="{ errors }" name="lien du conducteur" 
                    rules="required" vid="lien-du-conducteur"  class="d-inline-block min-w-150">
                    <b-form-select  v-model="conducteur.lien" :options="LienOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="py-2" v-if="conducteur.lien">
                <b-row v-if="conducteur.lien != 'CONJOINT'">
                  <div class="col-12 mb-1">
                    <label>Qui est le conducteur ?</label>
                  </div>
                </b-row>
                <div v-if="conducteur.lien == 'ENFANT'">
                  <b-row v-for="(enf,index) in enfantsArray" :key="index" class="mb-1">
                    <b-col md="12">
                      <b-row>
                        <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                      <b-form-group class="d-inline">
                        <b-form-radio
                          :value="true"
                          @change="toggleConnexite(enf.state, index, conducteur.lien)"
                          v-model="enf.state"
                          name="enfant"
                          class="d-inline"
                        >
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                    <b-col md="10">
                      <b-row>
                        <b-col md="3">
                          <b-form-group>
                            <label for="civilité">Civilité </label>
                            <validation-provider #default="{ errors }" :name="`civilité enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                              <b-form-select v-model="enf.civilite" :options="civilite" id="enfant_civilite" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="nom">Nom </label>
                            <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.nom }}</div>
                            <validation-provider v-else #default="{ errors }" :name="`nom enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                              <b-form-input
                                id="nom"
                                :state="errors.length > 0 ? false : null"
                                v-model="enf.nom"
                                autocomplete="nope"
                                type="text"
                                placeholder="Nom"
                                class="text-capitalize"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="nom">Nom de naissance </label>
                            <validation-provider  #default="{ errors }" :name="`nom de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                            <b-form-input
                              id="nom"
                              v-model="enf.nom_naissance"
                              :state="errors.length > 0 ? false : null"
                              autocomplete="nope"
                              type="text"
                              placeholder="Nom de naissance"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="nom">Prénom </label>
                            <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.prenom }}</div>
                            <validation-provider v-else #default="{ errors }" :name="`prénom enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                            <b-form-input
                              id="nom"
                              v-model="enf.prenom"
                              :state="errors.length > 0 ? false : null"
                              autocomplete="nope"
                              type="text"
                              placeholder="Prénom"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="1" class="text-center" style="margin-top:30px" v-if="!enf.id">
                      <div class="d-inline" @click="removeConnexite(index, conducteur.lien)">
                        <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                      </div>
                    </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="10" offset="1">
                      <b-row>
                        <b-col md="3">
                      <b-form-group>
                        <label for="date_naissance">Date de naissance </label>
                        <div class="form-control" readonly disabled v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' ">{{ enf.date_naissance }}</div>
                        <validation-provider v-else #default="{ errors }" :name="`date de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                        <flat-pickr
                          class="form-control"
                          v-model="enf.date_naissance"
                          :class="errors.length > 0 ? 'is-invalid' : ''"
                          id="date-naissance"
                          :config="configDate"
                          placeholder="Date de naissance"
                          data-max-date="today"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label for="code-postal-de-naissance">Code postal de naissance </label>
                        <validation-provider #default="{ errors }" :name="`code postal de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                        <b-form-input
                          id="code-postal-de-naissance"
                          :state="errors.length > 0 ? false : null"
                          v-model="enf.code_postal_naissance"
                          autocomplete="nope"
                          type="text"
                          placeholder="Code postal de naissance"
                          class="text-capitalize"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <label for="nom">Commune de naissance </label>
                        <validation-provider #default="{ errors }" :name="`commune de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                        <b-form-input
                          id="nom"
                          v-model="enf.commune_naissance"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="nope"
                          type="text"
                          placeholder="Commune de naissance"
                          class="text-capitalize"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <validation-provider #default="{ errors }" :name="`pays de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                          <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''">
                            <label for="pays de naissance">Pays de naissance</label>
                            <v-select v-model="enf.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" :state="errors.length > 0 ? false : null" input-id="pays" label="pays" placeholder="Pays de naissance">
                              <template #option="{ pays }">
                                <span class="ml-50"> {{ pays }}</span>
                              </template>

                              <template #selected-option="{ pays }">
                                <span class="ml-50"> {{ pays }}</span>
                              </template>
                              <div slot="no-options">Aucune pays disponible.</div>
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                    </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="10" offset="1">
                      <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <label for="situation familiale">Situation familiale</label>
                            <validation-provider v-slot="{ errors }" :name="`situation familiale enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`" vid="enf_situation_familiale">
                              <b-form-select v-model="enf.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire</b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <label for="activité professionnelle">Activité professionnelle</label>
                          <validation-provider #default="{ errors }" :name="`activité professionnelle enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                            <b-form-select v-model="enf.statut"  :options="ActiviteProfessionnelle" id="activite_professionnelle_enfant" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="12" sm="12">
                      <b-button
                          @click="addConnexite(conducteur.lien)"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="outline-primary"
                      >
                        <feather-icon icon="PlusIcon"/>
                        <span class="align-middle">Ajouter</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="conducteur.lien == 'PARENT'">
                <b-row v-for="(par,index) in parentsArray" :key="index" class="mb-1">
                  <b-col md="12">
                    <b-row>
                      <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                        <b-form-group class="d-inline">
                          <b-form-radio :value="true" @change="toggleConnexite(par.state, index, conducteur.lien)" v-model="par.state"
                            name="parent" class="d-inline">
                          </b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col md="10">
                        <b-row>
                          <b-col md="3">
                            <b-form-group>
                              <label for="civilité">Civilité </label>
                              <validation-provider #default="{ errors }" :name="`civilité parent{${index}}`"
                                :rules="`requiredIfChecked:${par.state}`">
                                <b-form-select v-model="par.civilite" :options="civilite" id="parent_civilite"
                                  :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group>
                              <label for="nom">Nom </label>
                              <div class="form-control" readonly disabled v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' ">{{ par.nom }}</div>
                              <validation-provider v-else #default="{ errors }" :name="`nom parent{${index}}`"
                                :rules="`requiredIfChecked:${par.state}`">
                                <b-form-input id="nom" :state="errors.length > 0 ? false : null" v-model="par.nom" autocomplete="nope"
                                  type="text" placeholder="Nom" class="text-capitalize" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group>
                              <label for="nom">Nom de naissance </label>
                              <validation-provider #default="{ errors }" :name="`nom de naissance parent{${index}}`"
                                :rules="`requiredIfChecked:${par.state}`">
                                <b-form-input id="nom" v-model="par.nom_naissance" :state="errors.length > 0 ? false : null"
                                  autocomplete="nope" type="text" placeholder="Nom de naissance" class="text-capitalize" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="3">
                            <b-form-group>
                              <label for="nom">Prénom </label>
                              <div class="form-control" readonly disabled v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' ">{{ par.prenom }}</div>
                              <validation-provider v-else #default="{ errors }" :name="`prénom parent{${index}}`"
                                :rules="`requiredIfChecked:${par.state}`">
                                <b-form-input id="nom" v-model="par.prenom" :state="errors.length > 0 ? false : null" autocomplete="nope"
                                  type="text" placeholder="Prénom" class="text-capitalize" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="1" class="text-center" style="margin-top:30px" v-if="!par.id">
                        <div class="d-inline" @click="removeConnexite(index, conducteur.lien)">
                          <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="10" offset="1">
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <label for="date_naissance">Date de naissance </label>
                          <div class="form-control" readonly disabled v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' ">{{ par.date_naissance }}</div>

                          <validation-provider v-else #default="{ errors }" :name="`date de naissance parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`">
                            <flat-pickr class="form-control" v-model="par.date_naissance" :class="errors.length > 0 ? 'is-invalid' : ''"
                              id="date-naissance" :config="configDate" placeholder="Date de naissance" data-max-date="today" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <label for="code-postal-de-naissance">Code postal de naissance </label>
                          <validation-provider #default="{ errors }" :name="`code postal de naissance parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`">
                            <b-form-input id="code-postal-de-naissance" :state="errors.length > 0 ? false : null"
                              v-model="par.code_postal_naissance" autocomplete="nope" type="text" placeholder="Code postal de naissance"
                              class="text-capitalize" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <label for="nom">Commune de naissance </label>
                          <validation-provider #default="{ errors }" :name="`commune de naissance parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`">
                            <b-form-input id="nom" v-model="par.commune_naissance" :state="errors.length > 0 ? false : null"
                              autocomplete="nope" type="text" placeholder="Commune de naissance" class="text-capitalize" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <validation-provider #default="{ errors }" :name="`pays de naissance parent{${index}}`"
                          :rules="`requiredIfChecked:${par.state}`">
                          <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''">
                            <label for="pays de naissance">Pays de naissance</label>
                            <v-select v-model="par.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1"
                              :state="errors.length > 0 ? false : null" input-id="pays" label="pays" placeholder="Pays de naissance">
                              <template #option="{ pays }">
                                <span class="ml-50"> {{ pays }}</span>
                              </template>

                              <template #selected-option="{ pays }">
                                <span class="ml-50"> {{ pays }}</span>
                              </template>
                              <div slot="no-options">Aucune pays disponible.</div>
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="10" offset="1">
                    <b-row>
                      <b-col md="3">
                        <b-form-group>
                          <label for="situation familiale">Situation familiale</label>
                          <validation-provider #default="{ errors }" :name="`situation familiale parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`" vid="par_situation_familiale">
                            <b-form-select v-model="par.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale"
                              :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <label for="activité professionnelle">Activité professionnelle</label>
                          <validation-provider #default="{ errors }" :name="`activité professionnelle parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`">
                            <b-form-select v-model="par.statut" :options="ActiviteProfessionnelle" id="activite_professionnelle_parent"
                              :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <b-button
                        @click="addConnexite(conducteur.lien)"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                    >
                      <feather-icon icon="PlusIcon"/>
                      <span class="align-middle">Ajouter</span>
                    </b-button>
                  </b-col>
                </b-row>
                </div>
                <div v-if="conducteur.lien == 'CONJOINT'">
                  <b-row class="mb-1">
                    <b-col md="12">
                      <b-row>
                        <b-col md="1" class="d-inline text-center" style="margin-top:30px">
                          <b-form-group class="d-inline">
                            <b-form-radio
                              :checked="true"
                              :value="true"
                              class="d-inline"
                              name="conjoint"
                            >
                            </b-form-radio>
                          </b-form-group>
                        </b-col>
                        <b-col md="10">
                          <b-row>
                            <b-col md="3">
                              <b-form-group>
                                <label for="civilité">Civilité </label>
                                <validation-provider #default="{ errors }" name="civilité conjoint" rules="required">
                                  <b-form-select v-model="conjoint.civilite" :options="civilite" id="conjoint_civilite" :state="errors.length > 0 ? false : null" />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group>
                                <label for="nom">Nom </label>
                                <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.nom }}</div>
                                <validation-provider v-else #default="{ errors }" name="nom conjoint" rules="required">
                                <b-form-input
                                  id="nom"
                                  v-model="conjoint.nom"
                                  :state="errors.length > 0 ? false : null"
                                  autocomplete="nope"
                                  type="text"
                                  placeholder="Nom"
                                  class="text-capitalize"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group>
                                <label for="nom-de-naissance">Nom de naissance </label>
                                <validation-provider #default="{ errors }" name="nom de naissance conjoint" rules="required">
                                <b-form-input
                                  id="nom-de-naissance"
                                  v-model="conjoint.nom_naissance"
                                  autocomplete="nope"
                                  :state="errors.length > 0 ? false : null"
                                  type="text"
                                  placeholder="Nom de naissance"
                                  class="text-capitalize"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group>
                                <label for="prenom">Prénom </label>
                                <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.prenom }}</div>
                                <validation-provider v-else #default="{ errors }" name="prénom conjoint" rules="required">
                                <b-form-input
                                  id="prenom"
                                  v-model="conjoint.prenom"
                                  :state="errors.length > 0 ? false : null"
                                  autocomplete="nope"
                                  type="text"
                                  placeholder="Prénom"
                                  class="text-capitalize"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="1" class="text-center" style="margin-top:30px" v-if="!conjoint.id">
                          <div class="d-inline" @click="removeConnexite(index, conducteur.lien)">
                            <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="10" offset="1">
                      <b-row>
                        <b-col md="3">
                          <b-form-group>
                            <label for="date_naissance">Date de naissance </label>
                            <div class="form-control" readonly disabled v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' ">{{ conjoint.date_naissance }}</div>
                            <validation-provider v-else #default="{ errors }" name="date naissance conjoint" rules="required">
                            <flat-pickr
                              class="form-control"
                              v-model="conjoint.date_naissance"
                              id="date-naissance"
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                              :config="configDate"
                              placeholder="Date de naissance"
                              data-max-date="today"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="code postal naissance">Code postal de naissance </label>
                            <validation-provider #default="{ errors }" name="code postal naissance conjoint" rules="required">
                            <b-form-input
                              id="code postal naissance"
                              v-model="conjoint.code_postal_naissance"
                              :state="errors.length > 0 ? false : null"
                              autocomplete="nope"
                              type="text"
                              placeholder="Code postal de naissance"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="nom">Commune de naissance </label>
                            <validation-provider #default="{ errors }" name="commune de naissance conjoint" rules="required">
                            <b-form-input
                              id="nom"
                              v-model="conjoint.commune_naissance"
                              autocomplete="nope"
                              :state="errors.length > 0 ? false : null"
                              type="text"
                              placeholder="Commune de naissance"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <validation-provider #default="{ errors }" name="pays de naissance conjoint" rules="required">
                            <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''">
                              <label for="pays de naissance">Pays de naissance</label>
                              <v-select v-model="conjoint.pays_naissance" :close-on-select="true" :reduce="pays => pays.pays" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pays" class="flex-grow-1" :state="errors.length > 0 ? false : null" input-id="pays" label="pays" placeholder="Pays de naissance">
                                <template #option="{ pays }">
                                  <span class="ml-50"> {{ pays }}</span>
                                </template>

                                <template #selected-option="{ pays }">
                                  <span class="ml-50"> {{ pays }}</span>
                                </template>
                                <div slot="no-options">Aucune pays disponible.</div>
                              </v-select>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="10" offset="1">
                      <b-row>
                        <b-col md="3">
                          <b-form-group>
                            <label for="situation familiale">Situation familiale </label>
                            <validation-provider v-slot="{ errors }" name="situation familiale conjoint" rules="required" vid="conjoint_situation_familiale">
                              <b-form-select v-model="conjoint.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group>
                            <label for="activité professionnelle">Activité professionnelle</label>
                            <validation-provider #default="{ errors }" name="activité professionnelle conjoint" rules="required">
                              <b-form-select v-model="conjoint.statut"  :options="ActiviteProfessionnelle" id="activite_professionnelle_enfant" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="pt-1">
                <label class="mb-1">Le conducteur est-il ?</label>
              </b-col>
              <b-col md="12" class="pl-4 pt-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2 w-125">Coursier, livreur</label>
                  <validation-provider v-slot="{ errors }" name="coursier, livreur" rules="required" vid="coursier_livreur">
                    <b-form-radio-group id="coursier_livreur" :options="BooleanValue" v-model="conducteur.is_coursier" @change="toggleConducteurType('coursier', conducteur.is_coursier)"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="pl-4 pt-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2 w-125">Taxi</label>
                  <validation-provider v-slot="{ errors }" name="taxi" rules="required" vid="taxi">
                    <b-form-radio-group id="taxi" :options="BooleanValue" v-model="conducteur.is_taxi" @change="toggleConducteurType('taxi', conducteur.is_taxi)"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le choix est obligatoire </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="pl-4 pt-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2 w-125">VRP</label>
                  <validation-provider v-slot="{ errors }" name="vrp" rules="required" vid="vrp">
                    <b-form-radio-group id="vrp" :options="BooleanValue" v-model="conducteur.is_vrp" @change="toggleConducteurType('vrp', conducteur.is_vrp)"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="pt-1" md="12">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label for="type-permis">Quels permis (en cours de validité obtenu en France ou en UE) possède le conducteur ?</label>
                    <b-form-checkbox-group name="checkbox-type-permis" :checked="permisSelected()" >
                    <validation-provider v-slot="{ errors }" :rules="`requiredPermis:${JSON.stringify(conducteur.permis)}`" class="demo-inline-spacing align-items-start" ref="PermisSelected"> 
                      <div v-for="(option, index) in optionsTypesPermis" :key="option.value" class="mt-1">
                        <b-form-checkbox  @change="updateSelectedPermis(option.value)"  :value="option.value">
                          {{ option.text }}
                          <span v-b-popover.hover.html.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                            <feather-icon icon="InfoIcon" size="20" />
                          </span>
                      </b-form-checkbox>
                      <b-form-group class="mt-1 btn-date-permis" v-if="conducteur.permis[index].checked">
                        <validation-provider #default="{ errors }" :name="`date ${option.text}`" rules="required">
                          <flat-pickr :id="`date-permis-${index}`" :config="configDate" v-model="conducteur.permis[index].date" :class="errors.length > 0 ? 'is-invalid' : ''"  class="form-control w-125" placeholder="Date" rules="required" autocomplete="off" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                      </div>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null" class="mt-1">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                    </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row class="mt-2 align-items-end">
              <b-col md="5">
                <b-form-group class="mb-0">
                  <label>Quel est le bonus moto actuel du conducteur ? (de 0,5 à 3,50)</label>
                  <validation-provider #default="{ errors }" name="bonus moto" rules="required|between:0.5,3.5">
                    <b-form-input v-model="conducteur.bonus_moto" :state="errors.length > 0 ? false : null"  placeholder="Bonus" type="text"  class="mx-1 w-100px text-center d-inline-block" v-mask="['#', '.##']"/>
                    <span class="d-inline-block text-center mt-0 align-middle w-80px">
                      <b class="text-success d-block" v-if="conducteur.bonus_moto >= 0.50 && conducteur.bonus_moto <= 1.00">Bonus</b>
                      <b class="text-warning d-block" v-else>Malus</b>
                      {{ calcPourcentageBonus(conducteur.bonus_moto) }}
                    </span>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="7" v-if="conducteur.bonus_moto == 0.50">
                <b-form-group >
                  <label class="float-left mr-1">Depuis quand le conducteur a-t-il un bonus 50 en moto ?</label>
                  <validation-provider #default="{ errors }" name="bonus-moto-years" rules="required">
                    <b-form-radio-group v-model="conducteur.bonus_moto_years" name="radio-bonus-moto-years" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                      <b-form-radio :value="option.value" v-for="option in bonusYearsOptions" :key="option.value" class="mt-0">
                          {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row class="mt-2 align-items-end" v-if="permisBSelected">
              <b-col md="5">
                <b-form-group class="mb-0">
                  <label>Quel est le bonus auto actuel du conducteur ? (de 0,5 à 3,50)</label>
                  <validation-provider #default="{ errors }" name="bonus auto" rules="required|between:0.5,3.5">
                    <b-form-input v-model="conducteur.bonus_auto" :state="errors.length > 0 ? false : null"  placeholder="Bonus" type="text"  class="mx-1 w-100px text-center d-inline-block" v-mask="['#', '.##']" />
                    <span class="d-inline-block text-center mt-0 align-middle w-80px">
                      <b class="text-success d-block" v-if="conducteur.bonus_auto >= 0.50 && conducteur.bonus_auto <= 1.00">Bonus</b>
                      <b class="text-warning d-block" v-else>Malus</b>
                      {{ calcPourcentageBonus(conducteur.bonus_auto) }}
                    </span>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="7" v-if="conducteur.bonus_auto == 0.50">
                <b-form-group >
                  <label class="float-left mr-1">Depuis quand le conducteur a-t-il un bonus 50 en auto ?</label>
                  <validation-provider #default="{ errors }" name="bonus-auto-years" rules="required">
                    <b-form-radio-group v-model="conducteur.bonus_auto_years" name="radio-bonus-auto-years" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                      <b-form-radio :value="option.value" v-for="option in bonusYearsOptions" :key="option.value" class="mt-0">
                          {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="permisBOnlySelected">
              <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-2">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le conducteur a-t-il effectué le stage obligatoire pour la conduite d’un 2 ou 3 roues ?</label>
                  <validation-provider v-slot="{ errors }" name="stage" rules="required" vid="stage">
                    <b-form-radio-group id="stage" v-model="conducteur.stage" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Peut-il justifier d'une expérience et d'antécédents d'assurance sur un véhicule de la même catégorie entre le 01/01/2006 et le 31/12/2010 ?</label>
                  <validation-provider v-slot="{ errors }" name="exp-in-vehicule" rules="required" vid="exp-in-vehicule">
                    <b-form-radio-group id="exp-in-vehicule" v-model="conducteur.exp_in_vehicule" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        </app-collapse-item>
        <!-- Expérience du conducteur -->
        <app-collapse-item ref="tab-collapse-item-3" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Expérience du conducteur</u></h4>">
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le conducteur a-t-il été assuré en 2 roues au cours des 3 dernières années ?</label>
                  <validation-provider v-slot="{ errors }" name="conducteur à assurer" rules="required" vid="is_conducteur_assure">
                    <b-form-radio-group id="conducteur_assurer_experience_option" v-model="conducteur.experience.is_assure" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="conducteur.experience.is_assure">
              <b-col md="12">
                <b-form-group class="block-cylindre">
                  <label class="mb-2">Sur quelle cylindrée ?</label>
                  <span v-b-popover.hover.top="`Plusieurs expériences peuvent être renseignées si le conducteur a été assuré pour plusieurs motos de différentes cylindrées`" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :title="`Sur quelle cylindrée ?`" class="ml-1 cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                      <b-form-checkbox-group name="checkbox-type-cylindre" :checked="cylindresSelected()" >
                    <validation-provider v-slot="{ errors }" :rules="`requiredCylindres:${JSON.stringify(conducteur.experience.cylindres)}`" ref="cylindresSelected"> 
                        <b-form-checkbox @change="updateSelectedCylindre(option.value)" @click.native="preventForAttribute" :value="option.value"  v-for="(option, index) in optionsTypesCylindre" :key="option.value" class="h-40p mb-2 d-block">
                              <span class="d-inline-block label-cylindre">{{ option.text }}</span>
                              <validation-provider #default="{ errors }" :name="`nombre de mois{${index}}`" rules="required|between:1,36" v-if="conducteur.experience.cylindres[index].checked">
                                <b-form-input v-model="conducteur.experience.cylindres[index].mois" :state="errors.length > 0 ? false : null"  placeholder="Mois" type="text"  class="mx-1 w-100px text-center d-inline-block" v-mask="'##'" />
                                <span>mois</span>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ clearError(errors[0]) }} </b-form-invalid-feedback>
                              </validation-provider>
                        </b-form-checkbox>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                      </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="permisBSelected">
              <b-col md="6" class="justify-content-center flex-column d-flex mr-0 mt-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le conducteur a-t-il été assuré en auto au cours des 3 dernières années ?</label>
                  <validation-provider v-slot="{ errors }" name="conducteur à assurer auto" rules="required" vid="is_conducteur_assure_auto">
                    <b-form-radio-group id="conducteur_assurer_auto_experience_option" v-model="conducteur.experience.is_assure_auto" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mb-1" v-if="conducteur.experience.is_assure_auto">
                <b-form-group class="mb-0">
                  <label>Pendant combien de temps ?</label>
                  <validation-provider #default="{ errors }" name="conducteur à assurer auto pendant" rules="required|integer|between:1,36">
                    <b-form-input v-model="conducteur.experience.p_assure_auto" :state="errors.length > 0 ? false : null"  placeholder="Mois" type="text"  class="mx-1 w-100px text-center d-inline-block" v-mask="'##'"/>
                    <span>mois</span>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="pt-1">
              <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le conducteur a-il-fait l'objet d'une résiliation de contrat moto et/ou auto par un précédent assureur ?</label>
                  <validation-provider v-slot="{ errors }" name="contrat précédent assureur" rules="required" vid="is_precedent_assure">
                    <b-form-radio-group id="contrat_precedent_assureur_option" v-model="conducteur.experience.precedent_assureur" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="conducteur.experience.precedent_assureur">
                <b-form-group label="Pour quel motif ? " label-for="motif">
                  <validation-provider #default="{ errors }" name="motif" rules="required" vid="motif">
                    <b-form-select :options="MotifOptions" v-model="conducteur.experience.motif" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="conducteur.experience.motif == 'NON_PAIEMENT'">
              <b-col md="6" class="justify-content-center flex-column d-flex mr-0">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Le contentieux est-il soldé ?</label>
                  <validation-provider v-slot="{ errors }" name="contentieux soldé" rules="required" vid="contentieux_solde">
                    <b-form-radio-group id="contentieux_solde_option" v-model="conducteur.experience.contentieux_solde" :options="BooleanValue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        </app-collapse-item>
        <!-- Antécédents de sinistres du conducteur -->
        <app-collapse-item ref="tab-collapse-item-4" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Antécédents de sinistres du conducteur</u></h4>">
        <b-row>
          <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
            <b-form-group class="mb-0">
              <label class="float-left pr-4 mb-2">Le conducteur a-t-il eu des sinistres auto ou moto au cours des 3 dernières années ?</label>
              <validation-provider v-slot="{ errors }" name="sinistre" rules="required" vid="sinistre">
                <b-form-radio-group id="sinistre_option" v-model="conducteur.antecedents_sinistres.has_sinistre" :options="BooleanValue"></b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="conducteur.antecedents_sinistres.has_sinistre">
            <b-row class="repeater-form" :style="{height: trHeight}" ref="cylindresRef">
              <b-col md="12" class="flex-column d-flex mb-2">
                <b-form-group class="mb-0">
                  <label>Nombre total de sinistres (hors Bris des Glaces) ?</label>
                  <validation-provider #default="{ errors }" name="bonus-actuel" rules="required">
                    <b-form-spinbutton id="sb-inline" class="mx-2" v-model="conducteur.antecedents_sinistres.nbr_sinistre" step="1" min="1"
                      max="6" inline @change="updateSinistres" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" v-for="option in conducteur.antecedents_sinistres.nbr_sinistre" :key="option" class="mb-1" ref="cylindreItemRef">
                <b-row>
                  <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
                <b-form-group class="mb-0">
                  <h3 class="mb-1">{{ `Sinistre ${option}` }}</h3>
                  <label class="float-left pr-4 mb-2">Type de véhicule ?</label>
                  <validation-provider v-slot="{ errors }" :name="'type vehicule de sinistre '+option" rules="required" :vid="'type_vehicule'+option" >
                    <b-form-radio-group :id="'type_vehicule-option_'+option" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].type_vehicule"  :options="TypesVehicule"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Date de survenance " :label-for="`date_survenance_${option}`">
                      <validation-provider v-slot="{ errors }" :name="`date de survenance sinistre ${option}`" rules="required" >
                          <flat-pickr class="form-control" :id="`date-survenance-${option}`" :config="configDate"
                            placeholder="Date de survenance" :class="errors.length > 0 ? 'is-invalid' : ''" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].date_survenance" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                      </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Nature du sinistre " :label-for="`nature_sinistre_${option}`">
                        <validation-provider #default="{ errors }" :name="`nature sinistre ${option}`" rules="required">
                          <b-form-select :options="NaturesSinistreOptions" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].nature_sinistre" :state="errors.length > 0 ? false : null" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                 <b-col md="12" class="justify-content-center flex-column d-flex mr-0 mt-1" v-if="checkNatureSinistre(conducteur.antecedents_sinistres.sinistres[option - 1].nature_sinistre)">
                <b-form-group class="mb-0">
                  <label class="float-left pr-4 mb-2">Responsabilité</label>
                  <validation-provider v-slot="{ errors }" :name="'responsabilité '+option" rules="required" :vid="'responsabilite-'+option" >
                    <b-form-radio-group :id="'responsabilite-option_'+option" v-model="conducteur.antecedents_sinistres.sinistres[option - 1].is_responsable"  :options="ResponsableOptions"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        </app-collapse-item>
        <!-- Antécédents d'aggravation du conducteur -->
        <app-collapse-item ref="tab-collapse-item-5" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Antécédents d'aggravation du conducteur</u></h4>">
        <b-row>
          <b-col md="12" class="justify-content-center flex-column d-flex mb-2">
            <b-form-group class="mb-0">
              <label class="pr-4 mb-2">Le conducteur fait-il l'objet, sur les 60 derniers mois, d'un retrait (suspension ou annulation) de permis de conduire pour une infraction au Code de la Route ?</label>
              <validation-provider v-slot="{ errors }" name="retrait permis" rules="required" vid="retrait-permis" >
                <b-form-radio-group id="retrait-permis-option" v-model="conducteur.antecedents_aggravation.retrait_permis" @change="initDataAntecedents()">
                  <b-form-radio :value="option.value" v-for="option in RetraitOptions" :key="option.value">
                          {{ option.text }}
                    <span v-b-popover.hover.top="option.infoBool" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </b-form-radio>
                </b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12"> 
              <b-row v-if="conducteur.antecedents_aggravation.retrait_permis == 'SUSPENSION' || conducteur.antecedents_aggravation.retrait_permis == 'ANNULATION'">
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour contrôle positif d'alcoolémie ?</label>
                  <validation-provider v-slot="{ errors }" name="positif d'alcoolémie" rules="required" vid="alcoolemie">
                    <b-form-radio-group id="alcoolemie_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.alcoolemie"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour usage de stupéfiants ou de drogue ?</label>
                  <validation-provider v-slot="{ errors }" name="drogue" rules="required" vid="drogue">
                    <b-form-radio-group id="drogue_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.drogue"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour refus de se soumettre à un contrôle de dépistage d'alcoolémie ou stupéfiants ?</label>
                  <validation-provider v-slot="{ errors }" name="contrôle dépistage d'alcoolémie" rules="required" vid="controle-depistage">
                    <b-form-radio-group id="controle-depistage_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.refus_controle"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour refus d'obtempérer ?</label>
                  <validation-provider v-slot="{ errors }" name="refus d'obtempérer" rules="required" vid="obtemperer">
                    <b-form-radio-group id="obtemperer_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.refus_obtemperer"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour délit de fuite ?</label>
                  <validation-provider v-slot="{ errors }" name="délit de fuite" rules="required" vid="delit-fuite">
                    <b-form-radio-group id="delit_fuite_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.delit_fuite"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour défaut d'assurance ?</label>
                  <validation-provider v-slot="{ errors }" name="défaut d'assurance" rules="required" vid="defaut-assurance">
                    <b-form-radio-group id="defaut_assurance_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.defaut_assurance"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                <b-form-group class="mb-0">
                  <label class="float-left pr-2 mb-2">pour autre infraction au Code de la Route ?
                     <span v-b-popover.hover.top="'Excès de vitesse, feu grillé, sens interdit, refus de priorité, dépassement dangereux.'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Info" class="cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </label>
                  <validation-provider v-slot="{ errors }" name="infraction au code de la route" rules="required" vid="infraction">
                    <b-form-radio-group id="infraction_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.autre_infraction"></b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              </b-row>
              <b-row v-if="conducteur.antecedents_aggravation.retrait_permis == 'ANNULATION'">
                <b-col md="12" class="justify-content-center flex-column d-flex">
                  <b-form-group class="mb-0">
                    <label class="float-left pr-2 mb-2">pour perte totale de points ?</label>
                    <validation-provider v-slot="{ errors }" name="totale de points" rules="required" vid="totale-points">
                      <b-form-radio-group id="totale_points_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.perte_totale_points"></b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="mb-1" v-if="conducteur.antecedents_aggravation.perte_totale_points">
                  <b-row class="align-items-center">
                    <b-col md="3">
                      <b-form-group label="Date d'effet du retrait ? " label-for="date-effet-retrait">
                        <validation-provider #default="{ errors }" name="date d'effet de retrait" rules="required">
                          <flat-pickr id="date-effet-retrait" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" v-model="conducteur.antecedents_aggravation.date_retrait"  class="form-control" placeholder="Date d'effet de retrait" rules="required" autocomplete="off" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> {{ errors[0] }} </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group class="mb-0 d-flex flex-column">
                        <label>Durée du retrait ?</label>
                        <validation-provider #default="{ errors }" name="durée du retrait" rules="required|integer|between:1,24">
                            <b-form-input v-model="conducteur.antecedents_aggravation.duree_retrait" :state="errors.length > 0 ? false : null"  placeholder="Durée" type="text"  class="mx-1 w-100px text-center d-inline-block" v-mask="'##'"/>
                            <span>mois</span>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="justify-content-center flex-column d-flex mb-1">
                  <b-form-group class="mb-0">
                    <label class="float-left pr-2 mb-2">Le conducteur a-t-il fait l'objet, sur les 60 derniers mois, d'une sanction pour conduite en état alcoolique et/ou usage de stupéfiants ?</label>
                    <validation-provider v-slot="{ errors }" name="sanction pour conduite en état" rules="required" vid="sanction">
                      <b-form-radio-group id="sanction_option" :options="BooleanValue" v-model="conducteur.antecedents_aggravation.sanction_conduite"></b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
          </b-col>
        </b-row>
        </app-collapse-item>
        <!-- Garanties -->
        <app-collapse-item ref="tab-collapse-item-6" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Garanties</u></h4>">
          <b-row v-for="(garantie, gr) in garanties.slice(0, 1)" :key="gr + 'garanties'">
            <b-col md="12">
              <label>Quel est le besoin exprimé par le client ?</label>
              <validation-provider #default="{ errors }" name="garantie" rules="required">
                <b-form-radio-group v-model="garantie.garantieNiveauID" :state="errors.length > 0 ? false : null" class="demo-inline-spacing mt-0 mb-2">
                  <b-form-radio v-for="(gn, cpt) in garantie.garantieniveaux" :key="cpt + 'garantiesNiveaux'"
                    :value="[gn.id]" class="custom-control-primary">
                    <span>{{gn.garantie_niveau_libel}}</span>
                    <span v-b-popover.hover.top="gn.garantie_details" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :title="gn.garantie_niveau_libel" class="ml-1 cursor-pointer" variant="outline-primary">
                      <feather-icon icon="InfoIcon" size="20" />
                    </span>
                  </b-form-radio>
                </b-form-radio-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le choix est obligatoire</b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
        </app-collapse-item>
        <!-- Informations complémentaires -->
        <app-collapse-item v-if="currentUser.courtier_user[0].courtier_id == courtier_id_host" ref="tab-collapse-item-5" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Informations complémentaires du courtier</u></h4>">
            <b-row class="mb-2">
              <b-col md="3">
                <label for="mtFrais">Frais de dossier</label>
                            <validation-provider #default="{ errors }" name="frais de dossier" rules="required">
                            <b-form-input
                              id="mtFrais"
                              v-model="objetMoto.frais"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              number
                              disabled
                              min="20"
                              step="0.01"
                              placeholder="Frais de dossier"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                            </validation-provider>
                    </b-col>
                    <!-- <b-col md="3">
                <label for="mtFraisMax">Frais Max </label>
                            <validation-provider #default="{ errors }" name="Frais Max" rules="required">
                            <b-form-input
                              id="mtFraisMax"
                              v-model="objetMoto.mtFraisMax"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="mtFraisMax"
                              class="text-capitalize"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                            </validation-provider>
                    </b-col> -->
                    
                    
                  </b-row>
                  
          </app-collapse-item>
          <!--  -->
        </app-collapse>
      </b-form>
      </validation-observer>
        <b-row class="mt-4">
          <b-col lg="12">
            <b-button
              variant="primary"
              class="float-left"
              size="lg"
              @click="backStep"
            >
              Précédent
            </b-button>
            
            <b-button
              variant="primary"
              class="float-right"
              size="lg"
              @click="checkValidateField(0)"
            >
              Suivant
            </b-button>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from "vue-select"
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TheFormSpinbuttonWithInput from '@core/components/input-number/TheFormSpinbuttonWithInput.vue'
import { BFormSpinbutton } from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
import {required,requiredIf,minLength,maxLength,between,email} from "vuelidate/lib/validators";
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { rules } from '@core/libs/validations'
import host from '@/shared/constants/broker_by_host'

import {
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BFormSelectOption
} from "bootstrap-vue";

//import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    VueSlider,
    BFormSpinbutton,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,
    TheFormSpinbuttonWithInput,
    BFormSelectOption
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters(["getEtude"]),
    ...mapGetters(["Tiers"]),
    ...mapGetters(["Conjoint"]),
    hasSelectedCylindre: function () {
      let hasSelected = this.conducteur.experience.cylindres.findIndex((cylindre) => cylindre.checked == true)
      if(hasSelected == -1) {
        return "required"
      }
      else if(this.$refs.typeCylindre != undefined) {
        this.$refs.typeCylindre.reset()
      }

      return ""
    },
    hasDateAcquisitionToday: function() {
      const dateDuJour = moment().format('DD/MM/YYYY');
      if(this.conducteur.vehicule.date_acquisition) {
        return moment(this.conducteur.vehicule.date_acquisition, 'DD/MM/YYYY').isBefore(moment(dateDuJour, 'DD/MM/YYYY'))
      }
      return false
    },
    checkDateExpirationAssure: function()  {
      const dateCheckExpertiser = moment().subtract(3, 'months');
      let dateNoAssure = this.conducteur.vehicule.duree_non_assure
      if(dateNoAssure) {
        return moment(dateNoAssure, 'DD/MM/YYYY').startOf('day').diff(moment(dateCheckExpertiser, 'DD/MM/YYYY').startOf('day'), 'days') < 0
      }
      return false
    },
    checkModeAcquisition() {
      if(this.conducteur.vehicule.mode_acquisition) {
        if(this.conducteur.vehicule.mode_acquisition == 'LLD' || this.conducteur.vehicule.mode_acquisition == 'LOA') {
           this.conducteur.vehicule.titulaire_carte_grise = 'SOCIETE_CREDIT'
        } else if(this.conducteur.vehicule.mode_acquisition == 'COMPTANT') {
          if(this.conducteur.vehicule.usage_vehicule == 'SOCIETE')
            this.conducteur.vehicule.titulaire_carte_grise = 'SOCIETE_VEHICULE'
          else this.conducteur.vehicule.titulaire_carte_grise = 'SOUSCRIPTEUR_CONJOINT_PARENT'
        }
      } else this.conducteur.vehicule.titulaire_carte_grise = 'SOUSCRIPTEUR_CONJOINT_PARENT'
      return true
    },
    checkVehiculeUsageParticulier() {
      if((this.conducteur.vehicule.mode_acquisition == 'LLD' || this.conducteur.vehicule.mode_acquisition == 'LOA') &&
      (this.conducteur.vehicule.usage_vehicule == 'PRIVE_TRAVAIL' || this.conducteur.vehicule.usage_vehicule == 'PRIVE')) {
        return true
      } else {
        this.conducteur.vehicule.is_usage_pp = this.initialState().conducteur.vehicule.is_usage_pp
        return false
      }
    },
    checkConducteurType() {
      return (this.conducteur.is_coursier || this.conducteur.is_taxi || this.conducteur.is_vrp) ? true : false
    },
    conducteurChecked: function () {
      if(!this.conducteur.s_is_conducteur) {
        let conducteurChecked = false
        if(this.conducteur.lien == 'ENFANT') {
          conducteurChecked = _.find(this.enfantsArray, enf => enf.state );
          return conducteurChecked !== undefined ? true : false
        } else if(this.conducteur.lien == 'PARENT') {
          conducteurChecked = _.find(this.parentsArray, par => par.state );
          return conducteurChecked !== undefined ? true : false
        }
      }

      return true
    },
    checkSinistresHasTwoVol: function () {
      if (this.conducteur.antecedents_sinistres.has_sinistre && this.conducteur.antecedents_sinistres.sinistres.length > 0) {
        const dateSinistre = moment().subtract(12, 'months').startOf('day');
        let count = 0
        var countfiltered = this.conducteur.antecedents_sinistres.sinistres.filter(function (sinistre) {
          let t = false
          t = sinistre.nature_sinistre == 'VOL' && sinistre.type_vehicule == 'MOTO'
          if (t && moment(dateSinistre, 'DD/MM/YYYY').diff(moment(sinistre.date_survenance, 'DD/MM/YYYY').startOf('day'), 'days') <= 0) {
            count++
          }
          return t
        }).length

        return (countfiltered > 1 && count > 1) ? true : false
      }
      return false
    }
  },
  watch: {
    'conducteur.vehicule.is_assure': {
      handler(val) {
        if(val) {
          this.errorDateQAssure = null
        }
      }
    },
    'conducteur.experience.cylindres': {
      immediate: true,
      deep: true,
      handler(val) {
        if(this.$refs.cylindresSelected != undefined) {
          setTimeout(() => {
            this.$refs.cylindresSelected.validate()
          }, 500);
        }
      }
    },
    'conducteur.permis': {
      immediate: true,
      deep: true,
      handler(val) {
        let permisSelected = val.filter((permis) => permis.checked == true)
        .map(function(permis) { return permis.type; });

        if(permisSelected.find(per => per == 'B') != undefined && permisSelected.length == 1) {
          this.permisBSelected = true
          this.permisBOnlySelected = true
        }
        else if(permisSelected.find(per => per == 'B') != undefined) {
          this.permisBSelected = true
          this.permisBOnlySelected = false
        }
        else {
          this.permisBSelected = false
          this.permisBOnlySelected = false
        }

        if(!this.permisBOnlySelected) {
          this.conducteur.exp_in_vehicule = null
          this.conducteur.stage = null
        }

        if(this.$refs.PermisSelected != undefined) {
          setTimeout(() => {
            this.$refs.PermisSelected.validate()
          }, 500);
        }
      }
    },
    'conducteur.experience.is_assure_auto': {
      handler(val) {
        if(val) {
          var per = this.conducteur.experience.p_assure_auto
          if(!this.devis.date_effet || per == 0) {
            this.conducteur.experience.p_assure_auto  = 1
          }
        } else {
          this.conducteur.experience.p_assure_auto  = this.initialState().conducteur.experience.p_assure_auto
        }
      }
    },
    'conducteur.experience.is_assure': {
      handler(val) {
        if(!val)
          this.conducteur.experience.cylindres = this.initialState().conducteur.experience.cylindres
      }
    },
    'conducteur.experience.precedent_assureur': {
      handler(val) {
        if(!val)
          this.conducteur.experience.motif = this.initialState().conducteur.experience.motif
      }
    },
    'conducteur.antecedents_sinistres.has_sinistre': {
      handler(val) {
        if(!val) {
            this.conducteur.antecedents_sinistres.sinistres = this.initialState().conducteur.antecedents_sinistres.sinistres
            this.conducteur.antecedents_sinistres.nbr_sinistre = this.initialState().conducteur.antecedents_sinistres.nbr_sinistre
            this.initTrHeight()
        } else if(val && this.conducteur.antecedents_sinistres.sinistres.length <= 0) {
            this.conducteur.antecedents_sinistres.sinistres = this.initialState().conducteur.antecedents_sinistres.sinistres
            this.conducteur.antecedents_sinistres.nbr_sinistre = this.initialState().conducteur.antecedents_sinistres.nbr_sinistre
        }
      }
    },
    'conducteur.antecedents_sinistres.sinistres': {
      handler(val) {
        this.nbrSinitreKeep = val.length
      }
    },
    'conducteur.vehicule.code_postal_stationnement': {
      immediate: true,
      handler(val) {
        if (val && val.length === 5) {
        this.$http
            .get(`/helpers/${val}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                let obj = this.villeOptions.find(o => o.ville === this.conducteur.vehicule.ville_stationnement);
                if(typeof obj === 'undefined' || obj === null)
                {
                  this.conducteur.vehicule.ville_stationnement = this.initialState().conducteur.vehicule.ville_stationnement
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.villeOptions = []
        }
      }
    },
    // 'conducteur.bonus_moto': {
    //   handler(val) {
    //     if(val == 0.50)
    //       this.conducteur.bonus_moto_years = this.initialState().conducteur.bonus_moto_years
    //   }
    // },
    'conducteur.lien': {
      handler(val) {
        this.resetStateCoAssure(val)
      }
    },
    getEtude: {
      handler: function (old, nld) {
        this.initialSouscripteur(old.Tiers)
        this.initialConjoint(old.Conjoint)  // this.conjoint                 = old.Conjoint
        this.objetMoto                = old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].objets.objet_moto[0] : null
        this.garanties                = old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].garanties : null
        this.exigences                = old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].exigences : null
        this.devis                    = old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].devis     : null
        this.enfantStore              = old.Enfant.Enfant
        this.parentStore              = old.Parent.Parent
        if(old.itemsRisque.ItemsRisque.length>0 && old.itemsRisque.ItemsRisque[0].objets.objet_moto[1] != undefined) {
          this.conducteur               = old.itemsRisque.ItemsRisque.length>0 ? old.itemsRisque.ItemsRisque[0].objets.objet_moto[1] : null
        }
        if(!this.objetMoto.frais && this.courtier_id_host == this.currentUser.courtier_user[0].courtier_id){
          this.objetMoto.frais = 20;
        }
      },
      deep: true,
      immediate: true
    },
    enfantStore: {
      handler: function (old, nld) {
        this.$http
          .get(`devis/getProspectDevisEnfants/${this.devis.id}`)
          .then(r => {
            this.mapConnexiteForStore(r.data, 'ENFANT')
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate:true
    },
    parentStore: {
      handler: function (old, nld) {
        this.$http
          .get(`devis/getProspectDevisParents/${this.devis.id}`)
          .then(r => {
            this.mapConnexiteForStore(r.data, 'PARENT')
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate:true
    },
    'devis.is_a_distance': {
      handler(val) {
        if (!val) {
          this.devis.type_vente = null
        }
      }
    },
    'devis.type_vente': {
      handler(val) {
        let date = moment().add(33, 'days')
        if(this.conducteur.hamon) {
          if(val && val == 'optout') {
            this.dateHamon = date.add(24, 'hours').format('DD/MM/YYYY')
            this.changeDisabledDate(34)
          } else {
            this.dateHamon = date.format('DD/MM/YYYY')
            this.changeDisabledDate(33)
          }
        } else {
          this.dateHamon = null
          this.changeDisabledDate(0)
        }
      }
    },
    'conducteur.hamon': {
      handler(val) {
        let date = moment().add(33, 'days')
        if(val) {
          if(this.devis.type_vente == 'optout') {
            this.dateHamon = date.add(24, 'hours').format('DD/MM/YYYY')
            this.changeDisabledDate(34)
          } else {
            this.dateHamon = date.format('DD/MM/YYYY')
            this.changeDisabledDate(33)
          }
        } else {
          this.dateHamon = null
          this.changeDisabledDate(0)
        }
      }
    }
  },
  data() {
    return this.initialState();
  },
  validations: {
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.getPays();
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initConfigDateEffet()
    if ((this.conducteur.hamon && moment(this.objetMoto.date_effet,'DD/MM/YYYY').diff(moment(this.dateHamon,'DD/MM/YYYY'), 'days') < 0) ||
    (!this.conducteur.hamon && moment(this.objetMoto.date_effet,'DD/MM/YYYY').diff(moment(moment().startOf('day'),'DD/MM/YYYY'), 'days') < 0)) {
      this.objetMoto.date_effet = null
      this.$refs.dateEffet.validate()
    }
    setTimeout(() => {
      this.$refs.AssureRules.reset()
    }, 1000);
    this.initTrHeight()
  },
   beforeMount() {
     this.initOriginalData(this.souscripteur)
     this.initOriginalDataConjoint(this.getEtude.Conjoint)  
  },
  methods: {
    initialState() {
      return {
        courtier_id_host:host.broker_id,
        currentUser: JSON.parse(localStorage.getItem('userData')),
        permisBSelected: false,
        permisBOnlySelected: false,
        dateHamon: null,
        originalData: {
          souscripteur: {
            civilite: null,
            nom:null,
            nom_naissance:null,
            prenom:null,
            code_postal_naissance:null,
            commune_naissance:null,
            pays_naissance:null,
            situation_familiale: null,
            date_naissance: null,
            statut: null
          },
          conjoint: {
            civilite: null,
            nom:null,
            nom_naissance:null,
            prenom:null,
            code_postal_naissance:null,
            commune_naissance:null,
            pays_naissance:null,
            situation_familiale: null,
            date_naissance: null,
            statut: null
          }
        },
        hasSelectedGarantie:false,
        modelesOptions: [],
        errorsTarif: [],
        enfantsArray: [],
        parentsArray: [],
        enfantsLocal: [],
        parentsLocal: [],
        enfantStore: [],
        parentStore: [],
        villeOptions: [],
        pays: [],
        options: {
          codePostal: {
            blocks: [5]
          },
          number: {
            numeral: true
          },
        },
        heightSinistreWrap: 240,
        errorDateQAssure: null,
        errorDateNaissanceProspect: null,
        configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          }
        },
        configDateEffet: {
            dateFormat: 'd/m/Y',
            disable: [ function(date) {
                let localyDayHours = new Date().setHours(0,0,0,0)
                let localDay=new Date(localyDayHours);
                return date < localDay
            }, function(date) {
                let localyDayHours = new Date().setHours(0,0,0,0)
                let localDayThreeMonths = new Date(new Date(localyDayHours).setMonth(new Date(localyDayHours).getMonth()+3));
                return date > localDayThreeMonths
            }],
            allowInput: true,
            onKeyDown: (selectedDates, dateStr, instance, event) => {
              if (event.keyCode != 8) {
                var ele = document.activeElement;
                var val = ele.value;

                if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                  val += "/";
                }
                ele.value = val;
                ele.dispatchEvent(new Event("input"));
              }
            },
        },
        configdateqAssure: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          }
        },
        objetMoto: {
          date_effet: null
        },
        enfant: {
          id: null,
          nom: null,
          civilite: null,
          situation_familiale: null,
          nom_naissance: null,
          prenom: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut:null,
          date_naissance: null,
          state: false,
          type_lien_familial: 'ENFANT',
          statut_gda:null,

        },
        parent: {
          id: null,
          nom: null,
          civilite: null,
          situation_familiale: null,
          nom_naissance: null,
          prenom: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut:null,
          date_naissance: null,
          state: false,
          type_lien_familial: 'PARENT',
          statut_gda:null,

        },
        conjoint: {
          id: null,
          nom: null,
          nom_naissance: null,
          prenom: null,
          date_naissance: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut: null,
          civilite: null,
          num_secu: null,
          numeroOrganisme: null,
          type_lien_familial: 'CONJOINT',
          situation_familiale: null,
          statut_gda:null,
        },
        garanties: [],
        exigences: [],
        conducteur: {
          hamon: 0,
          s_is_conducteur: 1,
          stage: null,
          exp_in_vehicule: null,
          lien: null,
          vehicule: {
            modele: {
              modele: null,
              marque: null,
              cylindree: null,
            },
            usage_particulier: null,
            date_premier_circulation: null,
            date_acquisition: null,
            mode_acquisition: null,
            type_garage: null,
            code_postal_stationnement: null,
            ville_stationnement: null,
            usage_vehicule: null,
            is_usage_pp:null,
            titulaire_carte_grise: null,
            is_assure: null,
            duree_non_assure: null,
            is_expertise: null
          },
          permis: [{
            type: 'A',
            label: 'Permis A',
            date: null,
            checked: false
          }, {
            type: 'AL',
            label: 'Permis A1, AL',
            date: null,
            checked: false
          }, {
            type: 'A2',
            label: 'Permis A2',
            date: null,
            checked: false
          }, {
            type: 'B',
            label: 'Permis B',
            date: null,
            checked: false
          }],
          is_coursier: null,
          is_taxi: null,
          is_vrp: null,
          bonus_moto: '1.00',
          bonus_moto_years: null,
          bonus_auto: '1.00',
          bonus_auto_years: null,
          experience: {
            is_assure: null,
            is_assure_auto: null,
            p_assure_auto: 0,
            cylindres: [{
              type: '125',
              label: '80 à 125 cm3',
              mois: 1,
              checked: false
            }, {
              type: '399',
              label: '126 à 399 cm3',
              mois: 1,
              checked: false
            }, {
              type: '599',
              label: '400 à 599 cm3',
              mois: 1,
              checked: false
            }, {
              type: '9999',
              label: '600 et +',
              mois: 1,
              checked: false
            }],
            precedent_assureur: null,
            motif: null,
            contentieux_solde: null
          },
          antecedents_sinistres: {
            has_sinistre: null,
            nbr_sinistre: 1,
            sinistres: [{
              type_vehicule: null,
              date_survenance: null,
              nature_sinistre: null,
              is_responsable: null
            }]
          },
          antecedents_aggravation: {
            retrait_permis: null,
            alcoolemie: null,
            drogue: null,
            refus_controle: null,
            refus_obtemperer: null,
            delit_fuite: null,
            defaut_assurance: null,
            autre_infraction: null,
            perte_totale_points: null,
            sanction_conduite: null,
            date_retrait: null,
            duree_retrait: 1
          }
        },
        souscripteur: {},
        nbrSinitreKeep: 1,
        BonusActuel: [
          '0.50', '1.00', '1.50', '2.00', '2.50', '3.00', '3.50'
        ],
        civilite: [{
            value: null,
            text: 'Choisissez'
          },
          {
            value: 'MR',
            text: 'M.'
          },
          {
            value: 'MME',
            text: 'Mme'
          }
        ],
        LienOptions: [{
            value: null,
            text: 'Choisissez',

          },
          {
            value: 'CONJOINT',
            text: 'Conjoint'
          },
          {
            value: 'ENFANT',
            text: 'Enfant'
          },
          {
            value: 'PARENT',
            text: 'Parent'
          }
        ],
        TitulaireCarteGriseOptions: [{
            value: null,
            text: '',
          },
          {
            value: 'SOUSCRIPTEUR_CONJOINT_PARENT',
            text: 'Le souscripteur, le conjoint, un parent'
          },
          {
            value: 'SOCIETE_CREDIT',
            text: 'Une société de crédit-bail ou de leasing'
          },
          {
            value: 'SOCIETE_VEHICULE',
            text: 'Une société (véhicule de société)'
          }
        ],
        MotifOptions: [{
            value: null,
            text: 'Choisissez',

          },
          {
            value: 'SURVEILLANCE',
            text: 'Sinistre'
          },
          {
            value: 'NON_PAIEMENT',
            text: 'Non paiement de prime'
          },
          {
            value: 'DEFAUT_PIECES',
            text: 'Défaut de pièces'
          },
          {
            value: 'FAUSSE_DECLA',
            text: 'Fausse déclaration'
          },
          {
            value: 'AGGRAVATION',
            text: 'Aggravation de risque'
          },
          {
            value: 'NULLITE',
            text: 'Nullité de contrat'
          }
        ],
        NaturesSinistreOptions: [{
            value: null,
            text: 'Choisissez',

          },
          {
            value: 'MATERIEL',
            text: 'Matériel'
          },
          {
            value: 'CORPOREL',
            text: 'Corporel'
          },
          {
            value: 'VOL',
            text: 'Vol ou tentative de vol'
          },
          {
            value: 'INCENDIE',
            text: 'Incendie'
          },
          {
            value: 'AUTRE',
            text: 'Autre (catastrophes naturelles, tempête, etc.)'
          },
        ],
        optionsModeAcquisition: [{
            text: 'Comptant / Crédit',
            value: 'COMPTANT',
            infoBool: 'Vous avez acheté votre véhicule comptant ou avec crédit personnel.'
          },
          {
            text: 'L.L.D',
            value: 'LLD',
            infoBool: 'Vous avez fait l’acquisition de votre véhicule en Location Longue Durée.'
          },
          {
            text: 'L.O.A',
            value: 'LOA',
            infoBool: 'Vous avez fait l’acquisition de votre véhicule en Location avec Option d\'Achat.'
          }
        ],
        optionsTypesPermis: [{
            text: 'Permis A',
            value: 'A',
            infoBool: `Accessible à partir de 20 ans, à une double condition : <br><ul><li>être titulaire du permis A2 depuis 2 ans minimum et avoir suivi une formation de 7 heures</li><li>il permet de conduire toutes les motos, avec ou sans side-car et tous les tricycles, quelle que soit leur puissance</li></ul>`
          },
          {
            text: 'Permis A1, AL',
            value: 'AL',
            infoBool: `Accessible à partir de 16 ans, il permet de conduire : <br><ul><li>les motos légères : motocyclette avec ou sans side-car SAUF genre TM (tricycles), d’une cylindrée maximale de 125 cm3 et d’une puissance de 11 kw maximum</li><li>les tricycles d’une puissance n’excédant 15 kw, quelle que soit leur cylindrée</li></ul>`
          },
          {
            text: 'Permis A2',
            value: 'A2',
            infoBool: `Accessible à partir de 18 ans, il permet de conduire : <br> <ul><li>les motos n’excédant pas 35 kw</li><li>les tricycles n’excédant pas 15 kw</li></ul>`
          },
          {
            text: 'Permis B',
            value: 'B',
            infoBool: `Accessible à partir de 18 ans. Il permet de conduire : <br><ul><li>les motos légères : motocyclette avec ou sans side-car, d’une cylindrée maximale de 125 cm3 et d’une puissance de 11 kw maximum</li><li>les tricycles d’une puissance n’excédant pas 15 kw à la double condition : <ul><li>être titulaire du permis B depuis 2 ans minimum</li><li>et avoir suivi une formation de 7 heures</li></ul> </li></ul>La formation de 7 heure n’est pas exigée si le permis a été obtenu avant le 1er mars 1980 ou si le conducteur peut justifier d’une expérience sur une moto ou un tricycle entre le 01/01/2006 et le 31/12/2010`
          }
        ],
        optionsTypesCylindre: [{
            text: '80 à 125 cm3',
            value: '125'
          },
          {
            text: '126 à 399 cm3',
            value: '399'
          },
          {
            text: '400 à 599 cm3',
            value: '599'
          },
          {
            text: '600 et +',
            value: '9999'
          }
        ],
        optionsTypeGarage: [{
            text: 'Garage clos et couvert',
            value: 'GARAGE',
            infoBool: 'Votre véhicule est stationné la nuit dans un garage ou un box, individuel ou collectif, avec un accès protégé par une clé, un badge magnétique ou un code, dont vous pouvez justifier être le propriétaire, copropriétaire ou locataire.'
          },
          {
            text: 'Terrain privé clos',
            value: 'TERRAIN',
            infoBool: null
          },
          {
            text: 'Voie publique',
            value: 'VOIE',
            infoBool: 'Votre véhicule est stationné la nuit sur la voie publique.'
          }
        ],
        bonusYearsOptions: [{
            text: 'Moins d’1 an',
            value: -1
          },
          {
            text: '1 an',
            value: 1
          },
          {
            text: '2 ans',
            value: 2
          },
          {
            text: '3 ans ou plus',
            value: 3
          }
        ],
        optionsUsageVehicule: [{
            text: 'Vie privée et/ou trajet travail',
            value: 'PRIVE_TRAVAIL',
            infoBool: 'Vous utilisez votre véhicule pour les déplacements privés ou pour les trajets domicile - lieu de travail <strong>à l’exclusion de tout autre, même occasionnel. Le transport rémunéré de marchandises ou de personnes est exclu</strong>.'
          },
          {
            text: 'Professionnel (avec véhicule privé)',
            value: 'PRIVE',
            infoBool: 'Le véhicule est utilisée pour tout type de déplacements privés et professionnels. Il ne sert en aucun cas, même occasionnellement, au transport à titre onéreux de marchandises ou de voyageurs.'
          },
        ],
        SituationFamilialeOptions: [{
            value: null,
            text: 'Choisissez'
          },
          {
            text: 'Concubin(e)',
            value: 'CONCUBIN'
          },
          {
            text: 'Célibataire',
            value: 'CELIBATAIRE'
          },
          {
            text: 'Divorcé(e)',
            value: 'DIVORCE'
          },
          {
            text: 'Marié(e)',
            value: 'MARIE'
          },
          {
            text: 'Séparé(e)',
            value: 'SEPARE'
          },
          {
            text: 'Veuf(ve)',
            value: 'VEUF'
          },
          {
            text: 'Pacsé(e)',
            value: 'PACSE'
          }
        ],
        optionsVenteDistance: [
          { text: 'Oui', value: 1 },
          { text: 'Non', value: 0 }
        ],
        optionsInformationComplnt: [
          { text: 'Oui', value: 'OUI' },
          { text: 'Non', value: 'NON' }
        ],
        optionsTypeVente: [
          { text: 'Vente à distance en 1 temps', value: 'optin', infoBool: 'Prospect ayant préalablement consenti à l’appel ou client.' },
          { text: 'Vente à distance en 2 temps', value: 'optout', infoBool: 'Prospect n’ayant pas consenti à l’appel. Un délai de 24h s’appliquera avant la mise en place de la signature électronique.' }
        ],
        BooleanValue: [{
            value: 1,
            text: "Oui"
          },
          {
            value: 0,
            text: "Non"
          },
        ],
        TypesVehicule: [{
            value: "AUTO",
            text: "4 roues (Auto ou vsp)"
          },
          {
            value: "MOTO",
            text: "2 roues (Moto ou cyclo)"
          },
        ],
        ResponsableOptions: [{
            value: 0,
            text: "Non responsable"
          },
          {
            value: 1,
            text: "Responsable"
          },
        ],
        RetraitOptions: [{
            value: "AUCUN",
            text: "Aucun retrait de permis",
            infoBool: "Le conducteur n'a eu aucun retrait de permis de conduire suite à une infraction au Code de la Route."
          },
          {
            value: "SUSPENSION",
            text: "Suspension",
            infoBool: "Le conducteur a eu un retrait temporaire de permis de conduire suite à une infraction au Code de la Route ; une suspension administrative prononcée par le préfet, ou une suspension judiciaire, décidée par un juge."
          },
          {
            value: "ANNULATION",
            text: "Annulation",
            infoBool: "Le conducteur a eu un retrait définitif de permis de conduire suite à une infraction au Code de la Route ou pour perte totale de points. La date indiquée pour l'obtention du permis de conduire doit être celle du permis repassé."
          }
        ],
        ActiviteProfessionnelle: [{
            value: null,
            text: 'Choisissez'
          },
          {
            value: "AGRICULTEUR",
            text: "Agriculteur"
          },
          {
            value: "ARTISAN",
            text: "Artisan"
          },
          {
            value: "CHEF_ENTREPRISE",
            text: "Chef d’entreprise"
          },
          {
            value: "COMMERCANT",
            text: "Commerçant"
          },
          {
            value: "ETUDIANT",
            text: "Etudiant"
          },
          {
            value: "FONCTIONNAIRE_ASSIMILE",
            text: "Fonctionnaire ou assimilé"
          },
          {
            value: "PROFESSION_LIBERALE",
            text: "Profession libérale"
          },
          {
            value: "RETRAITE",
            text: "Retraité"
          },
          {
            value: "SALARIE_NON_CADRE",
            text: "Salarié non cadre"
          },
          {
            value: "SALARIE_CADRE",
            text: "Salarié cadre"
          },
          {
            value: "SANS_EMPLOI",
            text: "Sans emploi"
          },
        ],
        showLoading: false,
        devis: null,
      }
    },
    initDataAntecedents() {
      let initData = null
      initData = this.initialState().conducteur.antecedents_aggravation
      initData.retrait_permis = this.conducteur.antecedents_aggravation.retrait_permis
      initData.sanction_conduite = this.conducteur.antecedents_aggravation.sanction_conduite
      this.conducteur.antecedents_aggravation = initData
    },
    initOriginalData(tiers){
    console.log('mounted')
    this.originalData.souscripteur.civilite = tiers.civilite
    this.originalData.souscripteur.nom = tiers.nom
    this.originalData.souscripteur.prenom = tiers.prenom
    this.originalData.souscripteur.nom_naissance = tiers.nom_naissance
    this.originalData.souscripteur.code_postal_naissance = tiers.code_postal_naissance
    this.originalData.souscripteur.commune_naissance = tiers.commune_naissance
    this.originalData.souscripteur.pays_naissance = tiers.pays_naissance
    this.originalData.souscripteur.situation_familiale = tiers.situation_familiale
    this.originalData.souscripteur.statut = tiers.statut
    this.originalData.souscripteur.date_naissance = tiers.date_naissance
    },
    initOriginalDataConjoint(conjoint){
    this.originalData.conjoint.civilite = conjoint.civilite
    this.originalData.conjoint.nom = conjoint.nom
    this.originalData.conjoint.prenom = conjoint.prenom
    this.originalData.conjoint.nom_naissance = conjoint.nom_naissance
    this.originalData.conjoint.code_postal_naissance = conjoint.code_postal_naissance
    this.originalData.conjoint.commune_naissance = conjoint.commune_naissance
    this.originalData.conjoint.pays_naissance = conjoint.pays_naissance
    this.originalData.conjoint.situation_familiale = conjoint.situation_familiale
    this.originalData.conjoint.statut = conjoint.statut
    this.originalData.conjoint.date_naissance = conjoint.date_naissance
    this.originalData.conjoint.statut_gda = conjoint.statut_gda
    },
        
    initConfigDateEffet() {
      if(this.conducteur.hamon) {
        if(this.devis.type_vente && this.devis.type_vente == 'optout') {
          this.changeDisabledDate(34)
        } else {
          this.changeDisabledDate(33)
        }
      } else {
        this.changeDisabledDate(0)
        }
    },
    changeDisabledDate(day) {
      this.configDateEffet.disable = [
        function (date) {
          let localyDayHours = new Date().setHours(0,0,0,0)
          let localDay=new Date(localyDayHours);
          return date < localDay.setDate(localDay.getDate() + day)
      },
      this.initialState().configDateEffet.disable[1]
      ]
    },
    clearError(error) {
      if(error)
        error = error.replace(/{[0-9]*}/i,'');
      
      return error
    },
    getTextOfTitulaire(value) {
      return this.TitulaireCarteGriseOptions.find((titulaire) => titulaire.value == value).text
    },
    checkNatureSinistre(nature) {
      return (nature == 'MATERIEL' || nature == 'CORPOREL') ? true : false
    },
    toggleIsSouscipteur(val) {
      if(val) {
        this.conducteur.lien = null
      }
    },
    mapModele(modele) {
      let modeleObjet = {
        cylindree: modele.cylindree,
        marque: modele.marque,
        modele: modele.modele
      }
      return modeleObjet
    },
    autocompleteModeles(filtre, loading) {
      if(filtre && filtre.length > 2) {
            loading(true)
            this.search(loading, filtre, this);
      }
    },
    search: _.debounce((loading, filtre, vm) => {
      vm.$http
      .post(`/portefeuilles/getModeles`, {
        filtre: filtre
      })
      .then(res => {
        vm.modelesOptions = res.data.resultat
        loading(false);
      })
      .catch(err => {
        vm.modelesOptions = vm.initialState().modelesOptions
        loading(false);
      })
    }, 350),
    updateSinistres(nbrSinistre) {
      let listSinistres = this.conducteur.antecedents_sinistres.sinistres
      let nbrSinistreUpdated = this.conducteur.antecedents_sinistres.nbr_sinistre
      if(nbrSinistreUpdated >= 1 && nbrSinistreUpdated <= 6) {
        if(nbrSinistre > this.nbrSinitreKeep && listSinistres.length < 6) {
          this.repeateAgain()
          this.nbrSinitreKeep = nbrSinistre
        } else if(nbrSinistre < this.nbrSinitreKeep && listSinistres.length > 1) {
          this.removeItem()
          this.nbrSinitreKeep = nbrSinistre
        }
      }
    },
    toggleConnexite(statut, indexConnexite, type) {
      if(statut) {
        if(type == 'ENFANT') {
          this.enfantsArray.forEach((enf, index) => {
            if(index !== indexConnexite) {
              enf.state = false
            }
          })
        } else if(type == 'PARENT') {
          this.parentsArray.forEach((par, index) => {
            if(index !== indexConnexite) {
              par.state = false
            }
          })
        }
      }
    },
    initialSouscripteur(tiers) {
        this.souscripteur = tiers
    },
    initialConjoint(tiers){
      this.conjoint = tiers
    },
    toggleConducteurType(type, value) {
      if(value) {
        switch(type) { 
          case "coursier": { 
              this.conducteur.is_taxi = 0
              this.conducteur.is_vrp = 0
              break; 
          } 
          case "taxi": { 
              this.conducteur.is_coursier = 0
              this.conducteur.is_vrp = 0
              break; 
          }
          case "vrp": { 
              this.conducteur.is_coursier = 0
              this.conducteur.is_taxi = 0
              break; 
          }  
          default: { 
              this.conducteur.is_coursier = 0
              this.conducteur.is_taxi = 0
              this.conducteur.is_vrp = 0
              break; 
          } 
        } 
      }
    },
    permisSelected() {
      let permisSelected = this.conducteur.permis.filter((permis) => permis.checked == true)
      .map(function(permis) { return permis.type; });

      return permisSelected
    },
    cylindresSelected() {
      let cylindresSelected = this.conducteur.experience.cylindres.filter((cylindre) => cylindre.checked == true)
      .map(function(cylindre) { return cylindre.type; });

      return cylindresSelected
    },
    getPays() {
      this.$http
      .get(`tiers/getPays`)
      .then(r => {
        this.pays = r.data
      })
      .catch(e => {
        console.log(e)
      })
    },
    calcPourcentageBonus(bonus) {
      let pourcent = 0
      let bonusActuel = +bonus
      let int_part = Math.trunc(bonusActuel);
      let float_part = Number((bonusActuel-int_part).toFixed(2));
      if(bonusActuel < 1.00 && bonusActuel != 0.50) {
        bonusActuel = -(float_part - 1)
      } else if(bonusActuel > 1.99) {
        bonusActuel = float_part + (int_part - 1)
      } else bonusActuel = float_part

      pourcent = (bonusActuel * 100).toFixed(0)
      return `soit ${pourcent}%`
    },
    MonthFormatter(value, maxValue) {
      return (value == maxValue) ? value+' mois et plus' : value+' mois'
    },
    updateSelectedPermis(typePermis) {
      let index = this.conducteur.permis.findIndex((p) => p.type === typePermis)
      this.conducteur.permis[index].checked = !this.conducteur.permis[index].checked
    },
    updateSelectedCylindre(typeCylindre) {
      let index = this.conducteur.experience.cylindres.findIndex((p) => p.type === typeCylindre)
      this.conducteur.experience.cylindres[index].checked = !this.conducteur.experience.cylindres[index].checked
    },
    backStep(){
      this.$store.commit("setStepTarif", {
          step: {
            indexStep:0,
            active:'choix'
          },
      });
    },
    checkEnfantValidateField(){
      let state=false;
      this.enfantsArray.map((enf)=>{
        if( enf.civilite!=enf.original_civilite ||(enf.date_naissance!=enf.original_date_naissance)||(enf.nom!=enf.original_nom) || (enf.nom_naissance!=enf.original_nom_naissance) 
          || (enf.prenom!=enf.original_prenom) || (enf.code_postal_naissance!=enf.original_code_postal_naissance) 
          || (enf.commune_naissance!=enf.original_commune_naissance) || (enf.pays_naissance!=enf.original_pays_naissance)
          || (enf.situation_familiale!=enf.original_situation_familiale) || (enf.statut != enf.original_statut) && enf.id ){
          state=true
          return state
        }
      })
      return state
    },
    checkParentValideField(){
      let state=false;
      this.parentsArray.map((par)=>{
        if( par.civilite!=par.original_civilite ||(par.date_naissance!=par.original_date_naissance)||(par.nom!=par.original_nom) || (par.nom_naissance!=par.original_nom_naissance) 
          || (par.prenom!=par.original_prenom) || (par.code_postal_naissance!=par.original_code_postal_naissance) 
          || (par.commune_naissance!=par.original_commune_naissance) || (par.pays_naissance!=par.original_pays_naissance)
          || (par.situation_familiale!=par.original_situation_familiale) || (par.statut!=par.original_statut) && par.id ){
          state=true
          return state
        }
      })
      return state
    },
    checkDateQAssure() {
      let dateAcquisition = this.conducteur.vehicule.date_acquisition
      let dateQAssure     = this.conducteur.vehicule.duree_non_assure
      if(dateAcquisition != null && dateQAssure != null) {
        dateAcquisition = moment(dateAcquisition, 'DD/MM/YYYY')
        dateQAssure     = moment(dateQAssure, 'DD/MM/YYYY')

        if(dateQAssure < dateAcquisition)
          this.errorDateQAssure = 'La date ne peut pas être antérieur à la date d’acquisition'
        else this.errorDateQAssure = null
      }
    },
    async checkValidateField(index){
      this.errorsTarif = []
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    
    if(!this.conducteurChecked){
        this.$toast({
          component: ToastificationContent,
          props: {
          title: 'Veuillez sélectionner le conducteur',
          icon: 'XIcon',
          variant: 'danger',
          },
        })
        return 0
    }

    if(this.conducteur.hamon && moment(this.objetMoto.date_effet,'DD/MM/YYYY') < moment(this.dateHamon,'DD/MM/YYYY')){
        this.$toast({
          component: ToastificationContent,
          props: {
          title: 'La date d’effet doit être postérieure au '+this.dateHamon+' pour respecter le délai légal Hamon.',
          icon: 'XIcon',
          variant: 'danger',
          },
        })
        return 0
    }

    if(this.souscripteur.date_naissance && moment().diff(moment(this.souscripteur.date_naissance,'DD-MM-YYYY'),'years',true) < 18  ){
          this.$toast({
            component: ToastificationContent,
            props: {
            title: 'Le souscripteur doit être majeur (égale ou supérieur à 18 ans).',
            icon: 'XIcon',
            variant: 'danger',
            },
          })
          return 0
      }

    if(this.checkSinistresHasTwoVol) {
      let erreurs = [{
        "attribut": "ERREUR_CRM",
        "message": "Compte-tenu de vos antécédents, nous ne pouvons vous délivrer de tarif."
      }];
      this.errorsTarif = this.filterErrorsTarif(erreurs)
      return 0
    }

    const isValid = await this.$refs.AssureRules.validate()
      if (!isValid || this.errorDateQAssure) {
        this.checkCollapseHasErrors()
        this.showLoading = false
        return 0
      }
      //Enfant
      let resultEnfant = await this.checkEnfantValidateField()
      //Parent
      let resultParent = await this.checkParentValideField()
      //souscripteur
      this.souscripteur.original_civilite =this.originalData.souscripteur.civilite
      this.souscripteur.original_nom = this.originalData.souscripteur.nom
      this.souscripteur.original_nom_naissance = this.originalData.souscripteur.nom_naissance
      this.souscripteur.original_prenom = this.originalData.souscripteur.prenom
      this.souscripteur.original_code_postal_naissance = this.originalData.souscripteur.code_postal_naissance
      this.souscripteur.original_commune_naissance = this.originalData.souscripteur.commune_naissance
      this.souscripteur.original_pays_naissance = this.originalData.souscripteur.pays_naissance
      this.souscripteur.original_statut =this.originalData.souscripteur.statut
      this.souscripteur.original_situation_familiale =this.originalData.souscripteur.situation_familiale
      this.souscripteur.original_date_naissance =this.originalData.souscripteur.date_naissance
      //conjoint
      this.conjoint.original_civilite =this.originalData.conjoint.civilite
      this.conjoint.original_nom = this.originalData.conjoint.nom
      this.conjoint.original_nom_naissance = this.originalData.conjoint.nom_naissance
      this.conjoint.original_prenom = this.originalData.conjoint.prenom
      this.conjoint.original_code_postal_naissance = this.originalData.conjoint.code_postal_naissance
      this.conjoint.original_commune_naissance = this.originalData.conjoint.commune_naissance
      this.conjoint.original_pays_naissance = this.originalData.conjoint.pays_naissance
      this.conjoint.original_statut =this.originalData.conjoint.statut
      this.conjoint.original_situation_familiale =this.originalData.conjoint.situation_familiale
      this.conjoint.original_date_naissance =this.originalData.conjoint.date_naissance
 
    if (
      // original souscripteur
       (this.souscripteur.statut != this.souscripteur.original_statut && this.souscripteur.original_statut != '' && this.souscripteur.original_statut != null)
      ||(this.souscripteur.date_naissance != this.souscripteur.original_date_naissance && this.souscripteur.original_date_naissance != '' && this.souscripteur.original_date_naissance != null)
      ||(this.souscripteur.civilite != this.souscripteur.original_civilite && this.souscripteur.original_civilite != '' && this.souscripteur.original_civilite != null)
      ||(this.souscripteur.situation_familiale != this.souscripteur.original_situation_familiale && this.souscripteur.original_situation_familiale != '' && this.souscripteur.original_situation_familiale != null)
      ||(this.souscripteur.nom != this.souscripteur.original_nom && this.souscripteur.original_nom != '' && this.souscripteur.original_nom != null)
      ||(this.souscripteur.nom_naissance != this.souscripteur.original_nom_naissance && this.souscripteur.original_nom_naissance != '' && this.souscripteur.original_nom_naissance != null)
      ||(this.souscripteur.prenom != this.souscripteur.original_prenom && this.souscripteur.original_prenom != '' && this.souscripteur.original_prenom != null)
      ||(this.souscripteur.code_postal_naissance != this.souscripteur.original_code_postal_naissance && this.souscripteur.original_code_postal_naissance != '' && this.souscripteur.original_code_postal_naissance != null)
      ||(this.souscripteur.commune_naissance != this.souscripteur.original_commune_naissance && this.souscripteur.original_commune_naissance != '' && this.souscripteur.original_commune_naissance != null)
      ||(this.souscripteur.pays_naissance != this.souscripteur.original_pays_naissance && this.souscripteur.original_pays_naissance != '' && this.souscripteur.original_pays_naissance != null)
      // original conjoint
      ||(this.conjoint.civilite != this.conjoint.original_civilite && this.conjoint.original_civilite != '' && this.conjoint.original_civilite != null)
      ||(this.conjoint.date_naissance != this.conjoint.original_date_naissance && this.conjoint.original_date_naissance != '' && this.conjoint.original_date_naissance != null)
      ||(this.conjoint.statut != this.conjoint.original_statut && this.conjoint.original_statut != '' && this.conjoint.original_statut != null)
      ||(this.conjoint.situation_familiale != this.conjoint.original_situation_familiale && this.conjoint.original_situation_familiale != '' && this.conjoint.original_situation_familiale != null)
      ||(this.conjoint.nom != this.conjoint.original_nom && this.conjoint.original_nom != '' && this.conjoint.original_nom != null)
      ||(this.conjoint.nom_naissance != this.conjoint.original_nom_naissance && this.conjoint.original_nom_naissance != '' && this.conjoint.original_nom_naissance != null)
      ||(this.conjoint.prenom != this.conjoint.original_prenom && this.conjoint.original_prenom != '' && this.conjoint.original_prenom != null)
      ||(this.conjoint.code_postal_naissance != this.conjoint.original_code_postal_naissance && this.conjoint.original_code_postal_naissance != '' && this.conjoint.original_code_postal_naissance != null)
      ||(this.conjoint.commune_naissance != this.conjoint.original_commune_naissance && this.conjoint.original_commune_naissance != '' && this.conjoint.original_commune_naissance != null)
      ||(this.conjoint.pays_naissance != this.conjoint.original_pays_naissance && this.conjoint.original_pays_naissance != '' && this.conjoint.original_pays_naissance != null)
      || resultEnfant || resultParent
      
    ){
       this.$swal({
            html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
                  <br><br>
                  Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
                  <br><br>
                  Souhaitez-vous confirmer cette modification ?</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
              html: 'text-left'
            },
            buttonsStyling: false
          })
           .then(confirmed => {
            if (confirmed.value) {
              this.faireTarif(index)
                this.originalData.souscripteur.civilite =this.souscripteur.civilite
                this.originalData.souscripteur.nom = this.souscripteur.nom
                this.originalData.souscripteur.nom_naissance = this.souscripteur.nom_naissance
                this.originalData.souscripteur.prenom = this.souscripteur.prenom
                this.originalData.souscripteur.code_postal_naissance = this.souscripteur.code_postal_naissance
                this.originalData.souscripteur.commune_naissance = this.souscripteur.commune_naissance
                this.originalData.souscripteur.pays_naissance = this.souscripteur.pays_naissance
                this.originalData.souscripteur.statut =this.souscripteur.statut
                this.originalData.souscripteur.situation_familiale =this.souscripteur.situation_familiale
                this.originalData.souscripteur.date_naissance =this.souscripteur.date_naissance
                //conjoint
                this.originalData.conjoint.civilite =this.conjoint.civilite
                this.originalData.conjoint.nom = this.conjoint.nom
                this.originalData.conjoint.nom_naissance = this.conjoint.nom_naissance
                this.originalData.conjoint.prenom = this.conjoint.prenom
                this.originalData.conjoint.code_postal_naissance = this.conjoint.code_postal_naissance
                this.originalData.conjoint.commune_naissance = this.conjoint.commune_naissance
                this.originalData.conjoint.pays_naissance = this.conjoint.pays_naissance
                this.originalData.conjoint.statut =this.conjoint.statut
                this.originalData.conjoint.situation_familiale =this.conjoint.situation_familiale
                this.originalData.conjoint.date_naissance =this.conjoint.date_naissance
            }
          })
      }
      else this.faireTarif(index)
    },
    checkCollapseHasErrors() {
      for (let index = 0; index < 7; index++) {
        const hasErrors = this.$refs[`tab-collapse-item-${index}`]?.$el.querySelector('div.d-block.invalid-feedback, input.is-invalid')
        if(hasErrors !== undefined) {
          if (hasErrors) {
            this.$refs[`tab-collapse-item-${index}`].visible = true
          } else this.$refs[`tab-collapse-item-${index}`].visible = false
        }
      }
    },
    async faireTarif(index) {
      this.getEtude.itemsRisque.ItemsRisque[index].objets.objet_moto=[this.objetMoto, this.conducteur]
      this.getEtude.Tiers=this.souscripteur
      let chooseRisque= this.getEtude.itemsRisque.ItemsRisque[0]
      let risqueItem=this.getEtude
      risqueItem.Enfant.Enfant = this.enfantsArray
      risqueItem.Parent.Parent = this.parentsArray
      risqueItem.itemsRisque.ItemsRisque=[]
      risqueItem.itemsRisque.ItemsRisque.push(chooseRisque)

      this.showLoading=true
      await this.persistObject(risqueItem)
        return new Promise((resolve, reject) => {
          resolve(true);
      });
    },
    async persistObject(risqueItem) {
      try {
        await this.$http.post(`portefeuilles/addDevisPortefeuille`,risqueItem).then((response)=>{
          let enfants=response.data[1];
          let conjoint=response.data[2];
          let parents=response.data[3];

          this.$store.commit("setObjetEnfant", {
            Enfant: enfants,
          });
          this.$store.commit("setObjetConjoint", {
            Conjoint: conjoint,
          });
          this.$store.commit("setObjetParent", {
            Parent: parents,
          });
          return this.getTarifData(risqueItem,0,enfants, parents,conjoint)
        })
        return true
      } catch (e) {
      this.showLoading=false
          this.errors = e.response.data.errors;
      }
    },
    async getTarifData(risqueItem,risque,enfants,parents,conjoint){
      try {
        const garantiesValues = this.getEtude.itemsRisque.ItemsRisque[risque].garanties
        garantiesValues.forEach((item, index) => {
          
          if (garantiesValues[index].garantieNiveauID.length <= 0) {
            this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID = []
            // this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID.push(
            //   garantiesValues[index].garantieniveaux.find(item => item.garantie_niveau_libel == 'Non').id
            // )
          }
        })
        risqueItem.itemsRisque.ItemsRisque[risque].garanties = this.getEtude.itemsRisque.ItemsRisque[risque].garanties

        let data={
          conjoint:conjoint,
          enfant:enfants,
          parent:parents,
          assure:this.souscripteur,
          tauxCommissions: 10,
          risque:{
            objet:risqueItem.itemsRisque.ItemsRisque[risque].objets,
            produits:risqueItem.itemsRisque.ItemsRisque[risque].produits,
            garanties:risqueItem.itemsRisque.ItemsRisque[risque].garanties,
            produits_garanties:risqueItem.itemsRisque.ItemsRisque[risque].produit_garanties,
            risque:risqueItem.itemsRisque.ItemsRisque[risque].risque,
            devis:risqueItem.itemsRisque.ItemsRisque[risque].devis.id
          }
        }
        console.log(data)
        await this.$http.post(`portefeuilles/getTarif`,data).then((response)=>{
          this.errorsTarif = []
          if(!response.data[0].hasOwnProperty('errors')) {
            this.getEtude.itemsRisque.ItemsRisque[risque].produits.tarif=[]
            this.getEtude.itemsRisque.ItemsRisque[risque].produits=response.data
            this.$store.commit("setItemsRisqueGlobal", {
              ItemsRisque: risqueItem.itemsRisque.ItemsRisque,
            });
          } else {
            this.errorsTarif = this.filterErrorsTarif(response.data[0]['errors'])
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
        })

        if(this.errorsTarif.length <= 0) {
          this.$store.commit("setStepTarif", {
            step: {
              indexStep:2,
              active:'comparatif'
            },
          });
        }

        this.showLoading=false

        } catch (e) {
          this.showLoading=false
          this.errors = e.response.data.errors;
        }
    },
    filterErrorsTarif(arrayErrors) {
      arrayErrors = arrayErrors.filter((value, index, self) =>
        index === self.findIndex((e) => (
          e.attribut === value.attribut && e.message === value.message
        ))
      )
      return arrayErrors
    },
    repeateAgain() {
      let sinistres = this.conducteur.antecedents_sinistres.sinistres
      sinistres.push({
        type_vehicule: null,
        date_survenance: null,
        nature_sinistre: null,
        is_responsable: null
      })

      this.$nextTick(() => {
        this.trAddHeight(this.heightSinistreWrap)
      })
    },
    removeItem() {
      let sinistres = this.conducteur.antecedents_sinistres.sinistres
      sinistres.splice(-1)
      this.trTrimHeight(this.heightSinistreWrap)
    },
    initTrHeight() {
      this.trSetHeight((this.conducteur.antecedents_sinistres.nbr_sinistre * this.heightSinistreWrap) + 20)
      this.$nextTick(() => {
        if(this.$refs.cylindresRef !== undefined && this.$refs.cylindresRef.scrollHeight != 0) {
          this.trSetHeight(this.$refs.cylindresRef.scrollHeight)
        }
      })
    },
    preventForAttribute(evt) {
      let exeptEvent = ['BDI', 'DIV']
      if (exeptEvent.find((el) => el == evt.target.tagName)) {
        evt.preventDefault()
      }
    },
    addConnexite(typeConnexite){
      if(typeConnexite == 'ENFANT')
        this.enfantsArray.push(this.initialState().enfant)
      else if(typeConnexite == 'PARENT')
        this.parentsArray.push(this.initialState().parent)
    },
    removeConnexite(index, typeConnexite){
      if(typeConnexite == 'ENFANT')
        this.enfantsArray.splice(index, 1)
      else if(typeConnexite == 'PARENT')
        this.parentsArray.splice(index, 1)
    },
    mapConnexiteForStore(prospects, typeConnexite){
      if(typeConnexite == 'ENFANT') {
        this.enfantsArray=[]
        Object.values(this.enfantStore).forEach((enf, index) => {
          let enfant={
            id:enf.lienable.id,
            civilite:enf.lienable.civilite,
            nom:enf.lienable.nom,
            prenom:enf.lienable.prenom,
            num_secu:enf.lienable.num_secu,
            numeroOrganisme:enf.lienable.numeroOrganisme,
            date_naissance:enf.lienable.date_naissance ? moment(enf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            state:false,
            original_date_naissance:enf.lienable.date_naissance ? moment(enf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            original_civilite : enf.lienable.civilite,
            original_nom : enf.lienable.nom,
            original_nom_naissance : enf.lienable.nom_naissance,
            original_prenom : enf.lienable.prenom,
            original_code_postal_naissance : enf.lienable.code_postal_naissance,
            original_commune_naissance : enf.lienable.commune_naissance,
            original_pays_naissance : enf.lienable.pays_naissance,
            original_situation_familiale : enf.lienable.situation_familiale,
            original_statut : enf.lienable.statut,
            pays_naissance: enf.lienable.pays_naissance,
            commune_naissance: enf.lienable.commune_naissance,
            code_postal_naissance: enf.lienable.code_postal_naissance,
            nom_naissance: enf.lienable.nom_naissance,
            situation_familiale: enf.lienable.situation_familiale,
            statut: enf.lienable.statut,
            statut_gda: enf.lienable.statut_gda
          }
          if(prospects && prospects.length>0){
            let checkExist=_.find(prospects, function(e) { return e.personne_physique_id == enf.lienable.id; });
            if(checkExist)
            enfant.state=true
          }
          this.enfantsArray.push(enfant)
        });
      } else if(typeConnexite == 'PARENT') {
        this.parentsArray=[]
        Object.values(this.parentStore).forEach((par, index) => {
          let parent={
            id:par.lienable.id,
            civilite:par.lienable.civilite,
            nom:par.lienable.nom,
            prenom:par.lienable.prenom,
            num_secu:par.lienable.num_secu,
            numeroOrganisme:par.lienable.numeroOrganisme,
            date_naissance:par.lienable.date_naissance ? moment(par.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            state:false,
            original_date_naissance:par.lienable.date_naissance ? moment(par.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            original_civilite : par.lienable.civilite,
            original_nom : par.lienable.nom,
            original_nom_naissance : par.lienable.nom_naissance,
            original_prenom : par.lienable.prenom,
            original_code_postal_naissance : par.lienable.code_postal_naissance,
            original_commune_naissance : par.lienable.commune_naissance,
            original_pays_naissance : par.lienable.pays_naissance,
            original_situation_familiale : par.lienable.situation_familiale,
            original_statut : par.lienable.statut,
            pays_naissance: par.lienable.pays_naissance,
            commune_naissance: par.lienable.commune_naissance,
            code_postal_naissance: par.lienable.code_postal_naissance,
            nom_naissance: par.lienable.nom_naissance,
            situation_familiale: par.lienable.situation_familiale,
            statut: par.lienable.statut,
            statut_gda: par.lienable.statut_gda
          }
          if(prospects && prospects.length>0){
            let checkExist=_.find(prospects, function(e) { return e.personne_physique_id == par.lienable.id; });
            if(checkExist)
            parent.state=true
          }
          this.parentsArray.push(parent)
        });
      }
    },
    resetStateCoAssure($coAssureType) {
      if($coAssureType != 'PARENT') {
        this.parentsArray.forEach(par => par.state = false);
      }
      if($coAssureType != 'ENFANT') {
        this.enfantsArray.forEach(enf => enf.state = false);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
    top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard{
    color: #4d25bc ;
    margin-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.min-w-150 {
  min-width: 150px
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
    color: #ea5455;
}

.w-80px {
  width: 80px!important;
}

.w-100px {
  width: 100px!important;
}
</style>

<style lang="scss" scoped>
label{
    font-weight: 900;
}

.w-125 {
  width: 125px;
}
.w-190 {
  width: 190px!important;
}
.h-40p {
  height: 40px;
}
</style>

<!-- 
<TheFormSpinbuttonWithInput
    id="my-number"
    class="mx-2"
    :size="'sm'"
    v-model="conducteur.bonus_moto"
    :step="0.01"
    :min="0.5"
    :max="3.5"
    inline
/>
-->

<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-modal id="modal-display-prospect-data" ref="modal-display-prospect-data" hide-footer centered size="lg" title="Informations Complémentaires" no-close-on-backdrop>
      <b-card-body style="padding: 0 1.5rem 1.5rem">
        <b-form class="px-2" @submit.prevent="handleSubmitCheckData(onSubmit)" @reset.prevent="resetForm">
          <b-alert variant="warning" show class="mb-2">
            <div class="alert-body">
              <span> Merci de renseigner les informations de contact de votre [prospect/client] </span>
            </div>
          </b-alert>
          <b-form-group label="Email*" label-for="email">
            <b-form-input id="email" v-model="$v.prospectLocal.moyen_contact.email.$model" trim placeholder="Email" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.email.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group>

          <!-- <b-form-group label="Téléphone Portable*" label-for="tel">
            <b-form-input id="nom_document" v-model="$v.prospectLocal.moyen_contact.tel.$model" trim placeholder="Téléphone Portable" v-mask="'## ## ## ## ##'" />
            <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Veuillez renseigner le(s) champ(s) obligatoire(s) </b-form-invalid-feedback>
          </b-form-group> -->

          <b-form-group label="Numéro de téléphone " label-for="phone">
            <b-row>
              <b-col sm="3" class="pr-0">
                <v-select v-model="$v.prospectLocal.moyen_contact.indicatif_tel.$model" :close-on-select="true"
                      :clearable="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"
                      :reduce="indicatif => indicatif.indicatif" input-id="indicatif" label="indicatif"
                      class="indicatif-chooser-tarif-santesenior w-100">
                  <template #option="{ indicatif,code }">
                    <span> {{`${code.toUpperCase()} ` + indicatif }}</span>
                  </template>
                  <template #selected-option="{ indicatif }">
                    <span> {{ indicatif }}</span>
                  </template>
                  <div slot="no-options">Aucune indicatif disponible.</div>
                </v-select>
              </b-col>
              <b-col sm="9" class="pl-0">
                <cleave id="phone" v-model="$v.prospectLocal.moyen_contact.tel.$model" :options="options.phone" :raw="false"
                      class="form-control" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;"
                      placeholder="ex: 06 00 00 00 00" />
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.tel.$error"> Le numéro de téléphone est obligatoire </b-form-invalid-feedback>
              </b-col>
              <b-col sm="12">
                <b-form-invalid-feedback :state="!$v.prospectLocal.moyen_contact.indicatif_tel.$error"> L'indicatif téléphonique est obligatoire </b-form-invalid-feedback>
              </b-col>
            </b-row>

          </b-form-group>
          <b-row>
            <b-col md="12">
              <div class="demo-inline-spacing">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" @click="addEmailAndTel"> Enregistrer </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-modal>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 4 : préconisation d'une formule</h2>
        <h6>Préconisez une formule parmi les propositions (commentaire obligatoire)</h6>
      </b-col>
    </b-row>
    <b-row class="pricing-card" v-for="(rl, index) in resumeLocal" :key="index + 'content'">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row>
          <b-col md="12" lg="12" xl="4" class="resume" v-for="(pr, val) in filtreProduit(rl.produitsFiltred)" :key="val + 'produits'">
            <b-card align="center" :header="pr.produit.produit_nom" class="text-center">
              <!--/ Produit -->
              <h3>{{ pr.tarif.formule_commercial }}</h3>
              <span class="d-block" style="font-size: 12px; height: 15px" v-if="ifHasSurComp(pr) ? pr.hasSurComp : false"> {{ formatSurComplementaire(pr.niveauLabel) }} </span>
              <span class="d-block" style="font-size: 12px; height: 15px" v-else></span>
              <br />
              <b-badge :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.garanties[0], pr.tarif.formule))" class="mb-1">
                {{ getGtNvByNiveau(pr.garanties[0], pr.tarif.formule) }}
              </b-badge>
              <b-card-text v-if="formatText(rl.risque.label) === 'SANTETNS'">
                {{ pr.tarif.tarif.libelle == 'Enfant' ? 'Adulte Enfant' : pr.tarif.tarif.libelle }}
              </b-card-text>
              <!-- Budget -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem;" v-if="formatText(rl.risque.label) === 'SANTETNS'"> {{ Number(pr.tarif.tarif.tarif).toFixed(2) }} </span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 1.5rem;" v-else>{{ Number(pr.tarif.tarif).toFixed(2) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                </div>
                <!-- <small
                  v-show="formatText(rl.risque.label)==='SANTETNS'"
                  class="annual-pricing text-muted"
                >{{ pr.tarif.tarif.libelle}}</small> -->
              </div>
              <!-- <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" v-if="formatText(rl.risque.label)==='SANTETNS'">
                    {{ pr.tarif.tarif.tarif}}
                  </span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" v-else>{{ pr.tarif.tarif}}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                </div>
                <small
                  v-show="formatText(rl.risque.label)==='SANTETNS'"
                  class="annual-pricing text-muted"
                >{{ pr.tarif.tarif.libelle}}</small>
              </div> -->
              <!--/ Budget -->

              <!-- Exigence -->
              <b-row class="mb-1" v-for="(eg, cptValue) in rl.exigences" :key="cptValue + 'exigence'">
                <b-col md="4" >
                  <span class="cursor-pointer" v-b-tooltip.hover.v-primary v-b-tooltip.hover.top="explicationExigenceLibel(eg.exigence_produits_details, pr.produit.id)"> {{ eg.label }} </span>
                </b-col>
                <b-col md="6">
                  {{ titleClass(getPtGtNvExigCorrespondant(index, val, eg.id, pr.tarif.formule)) }} {{ eg.id == 278 || eg.id == 277 ? '*' : '' }}
                </b-col>
                <!-- <b-col md="2">{{titleClass(getPtGtNvExig(index,val,eg.id,pr.tarif.formule))}}</b-col> -->
                <b-col lg="1" v-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))>0">
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                      color="#26bf39"
                  />
                </b-col>
                <b-col  v-else-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))<0" lg="1" >
                  <feather-icon
                      icon="MinusIcon"
                      size="16"
                      color="#bf2626"
                  />
                </b-col>
                <b-col  v-else lg="1">
                  <feather-icon
                      icon="CheckIcon"
                      size="16"
                      color="#26bf39"
                  />
                </b-col>
              </b-row>
              <b-form-textarea placeholder="Commentaires..." v-model="pr.tarif.commentaire" rows="3" maxlength="255"> </b-form-textarea>
              <!--/ Exigence -->

              <!-- buttons -->
              <b-button block class="mt-2" @click="choisirProduit(index, pr.tarif.tarification_id)" :variant="pr.tarif.preconise ? 'primary' : 'outline-primary'">
                <span v-if="pr.tarif.preconise">Préconisée</span>
                <span v-else>Préconiser</span>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <b-alert variant="warning" show class="mb-2 mx-2">
          <div class="alert-body">
            <span> Commentaires : Il s’agit des raisons pour lesquelles cette formule a été recommandée. </span>
            <br />
            <span> La motivation doit être claire, compréhensible et ne pas renvoyer de façon elliptique aux Conditions Générales ou à une documentation non synthétique. La motivation doit consister à justifier la cohérence du produit avec les exigences de votre client. Elle sera reprise dans la FIC. </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <h6>* Adhérents aux dispositifs de pratique tarifaire maitrisée</h6>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="12" class="mb-2">
        <app-collapse id="collapse-besoin" type="margin">
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>SwissLife - Détail des options<u></h4>">
            <ul>
              <li>
                <strong>Le module Retraité +</strong> permet de bénéficier de forfaits supplémentaires en médecines douces, pharmacie et dentaire (non remboursés par la Sécurité sociale ou tout autre régime obligatoire) ou aide aux frais d’obsèques. Il propose également un forfait complémentaire pour la chambre particulière en cas d’hospitalisation et le versement d’un capital en cas de perte totale et irréversible d’autonomie.
              </li>
          
              <li>
                <strong>L’option Essentiel Pharma</strong> permet d’optimiser le montant de votre cotisation en limitant la prise en charge aux médicaments remboursés à 65 % par la Sécurité sociale.
              </li>
            </ul>
          </app-collapse-item>

          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>ECA - Détail des options<u></h4>">
            <ul>
              <li>
                <strong>L’option Renfort + </strong> permet de bénéficier de garanties en médecines douces et médecines non remboursées par l’Assurance Maladie obligatoire. Elle comprend des forfaits supplémentaires pour la prise en charge de la chambre particulière en secteur conventionné, pour les prothèses dentaires, l’implantologie et la parodontologie non remboursées par l’Assurance Maladie Obligatoire. Elle propose également un capital forfaitaire en cas de décès accidentel de l’Adhérent ou de l’un des assurés au contrat ainsi qu’un capital perte d’autonomie. 
              </li>
        
              <li>
                <strong>L’option Pharma +</strong> vise à prendre en charge les médicaments remboursés à 65%, 30% et 15%  par l’Assurance Maladie Obligatoire.                
              </li>
            </ul>
          </app-collapse-item>
        </app-collapse>

      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep"> Précédent </b-button>
        <b-button v-if="canNext" variant="primary" class="float-right" size="lg" @click="selectProduit"> Suivant </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BOverlay, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadioGroup, BFormRadio, BTabs, BTab, BFormSelect, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BButton, BCard, BCardText, BListGroup, BListGroupItem, BFormTextarea, VBTooltip, BForm, BCardBody, BAlert, BBadge } from 'bootstrap-vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Cleave from 'vue-cleave-component'
//import { codeIcon } from './code'

export default {
  components: {
    Cleave,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BForm,
    BCardBody,
    BAlert,
    BBadge
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      checkerExplicationOptions: {isOption1: false, isOption2: false},
      resumeLocal: [],
      indexLocal: 0,
      indexRisque: 0,
      hasProduct: false,
      hasCommentedProduit: false,
      hasSelectedProduit: false,
      showLoading: false,
      indicatif:[],
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          numericOnly: true
        },
      },
      prospectLocal: {
        id: null,
        civilite: null,
        nom: null,
        prenom: null,
        date_naissance: null,
        type: null,
        numeroOrganisme: null,
        num_secu: null,
        situation_familiale: null,
        moyen_contact: {
          libelle: null,
          lieu_dit_ou_bp: null,
          complement_adresse: null,
          indicatif_tel: null,
          tel: null,
          code_postal: null,
          ville_id: null,
          email: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['Tiers']),
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['getEtude']),
    canNext: function () {
      return _.find(this.resumeLocal[0].produitsFiltred, function (p) {
        return p.tarif.preconise
      })
    }
  },
  watch: {
    Tiers: {
      handler: function (val, oldVal) {
        this.mapProspectLocal(val)
      },
      immediate: true,
      deep: true
    },
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.resumeLocal = this.getItemsRisque.ItemsRisque
        this.checkerExplicationOptions = {isOption1: false, isOption2: false}
        this.hasProduct = false
        if (this.resumeLocal.length > 0) {
          this.resumeLocal.map(risque => {
            if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
              risque.produitsFiltred.map(rpf => {
                if (rpf.tarif.presente) this.hasProduct = true
              })
            }
          })

          // let choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)
          // this.checkerExplicationOptions = []
          // choosedSurComp.garanties.map(gn => {
          //   let result = _.find(gn.garantieniveaux, function (gnr) {
          //     if (gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui') {
          //       let index = this.checkerExplicationOptions.findIndex(object => object === el.id)
          //       if (index === -1) {
          //         this.checkerExplicationOptions.push(el.id)
          //       }
          //     }
          //   })
          // })

        }


      },
      immediate: true,
      deep: true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque
    },
  },
  validations: {
    prospectLocal: {
      moyen_contact: {
        email: { required, email },
        tel: { required, minLength: minLength(14) },
        indicatif_tel: { required }
      }
    }
  },
  created(){
    this.getPaysList()
  },
  methods: {
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      let expDetail = exProduitsDetails.find((exp) => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },
    getPaysList() {
      this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data

        if (!this.prospectLocal.moyen_contact.indicatif_tel) {
        this.prospectLocal.moyen_contact.indicatif_tel = this.indicatif[0].indicatif
        }
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    formatSurComplementaire(niveauLabel) {
      let option = ''
      niveauLabel.forEach(el => {

        // Ajouter les IDs dans Array pour créer une condition pour afficher les explications des étoiles du garanties
        // id 46 => renfort retraité +
        // id 47 => essentiel pharma
        if (el.id == 46) {
          option += 'avec ' + el.produit_garantie[0].libelle_comm
          if (!this.checkerExplicationOptions.isOption1) {
            this.checkerExplicationOptions.isOption1 = true
          }
        } else if (el.id == 47) {
          if (option == '') {
            option = 'avec ' + el.produit_garantie[0].libelle_comm
          } else {
            option += ' et ' + el.produit_garantie[0].libelle_comm
          }
          if (!this.checkerExplicationOptions.isOption2) {
            this.checkerExplicationOptions.isOption2 = true
          }

        }
      })

      return option
    },
    ifHasSurComp(produit) {
      produit.hasSurComp = false
      produit.niveauLabel = []
      if (produit.garanties && produit.garanties.length > 0) {
        produit.garanties.map(gn => {
          let result = _.find(gn.garantieniveaux, function (gnr) {
            return gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui'
          })

          if (result) {
            produit.niveauLabel.push(gn)
            produit.hasSurComp = true
          }
        })
      }
      return produit
    },
    ifChooseSurComp() {
      if (this.resumeLocal[0] && this.resumeLocal[0].produitsFiltred && this.resumeLocal[0].produitsFiltred.length > 0) {

        let choosedSurComp = _.find(this.resumeLocal[0].produitsFiltred, (value, key) => value.hasSurComp && value.tarif.presente)

        // choosedSurComp.garanties.map(gn => {
        //   let result = _.find(gn.garantieniveaux, function (gnr) {
        //     if (gnr.id == gn.garantieNiveauID[0] && gnr.garantie_niveau_libel == 'Oui') {
        //       let index = this.checkerExplicationOptions.findIndex(object => object === el.id)
        //       if (index === -1) {
        //         this.checkerExplicationOptions.push(el.id)
        //       }
        //     }
        //   })
        // })

        return choosedSurComp ? true : false
      } else {
        return false
      }
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'comparatif'
        }
      })
    },
    mapProspectLocal(tiers) {
      this.prospectLocal.id = tiers.id
      this.prospectLocal.civilite = tiers.civilite
      this.prospectLocal.nom = tiers.nom
      this.prospectLocal.prenom = tiers.prenom
      this.prospectLocal.date_naissance = tiers.date_naissance
      this.prospectLocal.numeroOrganisme = tiers.numeroOrganisme
      this.prospectLocal.type = tiers.type
      this.prospectLocal.situation_familiale = tiers.situation_familiale
      this.prospectLocal.num_secu = tiers.num_secu
      this.prospectLocal.moyen_contact.libelle = tiers.moyen_contact.libelle
      this.prospectLocal.moyen_contact.lieu_dit_ou_bp = tiers.moyen_contact.lieu_dit_ou_bp
      this.prospectLocal.moyen_contact.complement_adresse = tiers.moyen_contact.complement_adresse
      this.prospectLocal.moyen_contact.tel = tiers.moyen_contact.tel
      this.prospectLocal.moyen_contact.code_postal = tiers.moyen_contact.code_postal
      this.prospectLocal.moyen_contact.ville_id = tiers.moyen_contact.ville_id
      this.prospectLocal.moyen_contact.email = tiers.moyen_contact.email
      this.prospectLocal.moyen_contact.indicatif_tel = tiers.moyen_contact.indicatif_tel
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    getExigence(egn, egnArray) {
      let tmpOption = _.find(egnArray, (value, key) => value.id == egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    comparerExig(ex1,ex2){
      let i=this.getExByScoringNumber(ex1);
      let j=this.getExByScoringNumber(ex2);
      if(i<j) return 1;
      else if(i>j) return -1;
      else return 0;
    },
    getExByScoringNumber (ex1) {
      switch(ex1){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    },
    updateIndexDevis(value) {
      this.indexRisque = value
      this.$store.commit('setSelectedRisque', {
        selectedRisque: value
      })
    },
    choisirProduit(risque, tarification) {
      this.resumeLocal[risque].produitsFiltred.map((pr, val) => {
        if (pr.tarif.tarification_id == tarification) {
          pr.tarif.preconise = true
        } else pr.tarif.preconise = false
      })
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      let tmpProduitsFiltred = this.filtreProduit(this.resumeLocal[risque].produitsFiltred)
      if (tmpProduitsFiltred[produit]) {
        let result = tmpProduitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
          }
        } else {
          return ''
        }
      }
    },
    getGtNvByNiveau(garantie, niveau) {
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        });
      });
      // let tmpGNPOption = _.find(garantie.produit_garantie.garantiesniveaux, (value, key) => value.pivot.libelle == niveau)
      return tmpGNPOption
    },
    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      let tmpProduitsFiltred = this.filtreProduit(this.resumeLocal[risque].produitsFiltred)
      if (tmpProduitsFiltred[produit]) {
        let result = tmpProduitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(
          pgn => pgn.libelle === formule
        )

        if (result && result.length > 0) {
          if (result[0].exigencesniveaux.length > 0) {
            let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
            return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
          }
        } else {
          return ''
        }
      }
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    selectProduit() {
      // this.loadingOn()
      let produisPreconise = []
      this.hasSelectedProduit = false
      this.hasCommentedProduit = false
      let validProduit = null
      this.showLoading = true
      validProduit = this.resumeLocal[this.indexRisque].produitsFiltred.find(pr => pr.tarif.preconise)
      if (validProduit) {
        if (validProduit.tarif.commentaire !== '') {
          if (this.resumeLocal[this.indexRisque].produitsFiltred.length > 0) {
            this.resumeLocal[this.indexRisque].produitsFiltred.map(rlp => {
              produisPreconise.push({
                preconise: rlp.tarif.preconise,
                tarification_id: rlp.tarif.tarification_id,
                commentaire: rlp.tarif.commentaire,
                taux_commission: rlp.tarif.taux_commission ? rlp.tarif.taux_commission : null
              })
            })
          }
          if (produisPreconise.length > 0) {
            this.$http
              .post(`prospect/checkIfHasEmailAndTel`, this.prospectLocal)
              .then(r => {
                if (r.data.data) {
                  let data = {
                    produisPreconise: produisPreconise,
                    devis_id: this.resumeLocal[this.indexRisque].devis.id,
                    risque: this.resumeLocal[this.indexRisque].risque.label,
                    objet: this.resumeLocal[this.indexRisque].objets
                  }

                  this.$http
                    .post(`portefeuilles/updateTarifPreconise`, data)
                    .then(response => {
                      // this.$http.post(`projet/generate_project_document`,doc).then((res) => {
                      //   this.$store.commit("setEtudeConseil", {
                      //     // conseilpath: res.data.data.document.path+res.data.data.document.nom
                      //     conseilpath: res.data.data.document.id
                      //   });
                      //   this.sendMail();
                      //   this.updateDevisSouscriptionAvancement()
                      //   this.updateDevisDateEnvoi()
                      this.$store.commit('setStepTarif', {
                        step: {
                          indexStep: 4,
                          active: 'courtier'
                        }
                      })
                      //   this.showLoading=false
                      // }).catch(()=>{
                      //   this.showLoading=false
                      // })
                    })
                    .catch(() => {})
                } else {
                  this.$refs['modal-display-prospect-data'].show()
                  // this.showLoading = false
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.showLoading = false
              })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Merci de saisir un commentaire',
              icon: 'EditIcon',
              variant: 'danger'
            }
          })
          this.hasCommentedProduit = true
          this.showLoading = false
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Merci de Préconiser un produit',
            icon: 'EditIcon',
            variant: 'danger'
          }
        })
        this.hasSelectedProduit = true
        this.showLoading = false
      }
      // this.showLoading=false
    },
    filtreProduit(data) {
      return data ? data.filter(pr => pr.tarif.presente) : []
    },

    getOptionGarantie(grn, grnArray, label, options) {
      if (!_.isEmpty(options)) {
        let tmpOption = _.find(options, (v, key) => key == label)
        if (tmpOption) {
          if (grn) {
            let found = grnArray.find(gn => gn.id == grn && gn.garantie_niveau_libel == 'Oui')
            return found ? true : false
          } else {
            return false
          }
        } else return false
      } else {
        return false
      }
    },
    formatText(str) {
      var map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ'
      }
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      return str.split(' ').join('').toUpperCase()
    },
    async updateDevisSouscriptionAvancement() {
      let data = {
        id: this.resumeLocal[this.indexRisque].devis.id,
        devis_etape_avancement: 'choixclient'
      }
      await this.$http
        .post(`devis/updateDevisSouscriptionState`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async updateDevisDateEnvoi() {
      let data = {
        id: this.resumeLocal[this.indexRisque].devis.id,
        date_envoi: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }
      await this.$http
        .post(`devis/updateDevisDateEnvoi`, data)
        .then(r => {
          return true
        })
        .catch(err => {
          console.log(err)
        })
    },
    addEmailAndTel() {
      this.$v.$reset()
      this.$v.prospectLocal.$touch()
      if (this.$v.prospectLocal.$error) {
        return 0
      }
      this.$http
        .post(`portefeuilles/updateEmailAndTel`, this.prospectLocal)
        .then(res => {
          if (res.data.success) {
            this.$store.commit('setProspectEmailAndTel', { Tiers: this.prospectLocal })
            this.$refs['modal-display-prospect-data'].hide()
            this.selectProduit()
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';
.resume > .card > .card-header {
  background-color: #4d25bc !important;
  color: #fff;
  justify-content: center !important;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}
#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}
#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}
#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}
#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}
#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}
.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}
.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}
.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}
@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}
.offere_thClass th {
  background-color: #4d25bc !important;
  color: #fff;
}
.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}
.propositions .card-body {
  padding: 0 !important;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}
</style>

<style>

.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
}
.indicatif-chooser-tarif-santesenior .vs__dropdown-toggle:focus {
    border-radius: 0.357rem 0 0 0.357rem;
}
</style>

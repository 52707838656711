<template>
  <div>
    <b-overlay :show="showLoading" no-wrap />
    <b-row v-if="risqueLocal[0].produitsFiltred.length>0">
      <b-col class="mb-2" cols="12">
        <h2 class="mb-0 text-uppercase font-weight-bolder">étape 3 : Comparaison des offres </h2>
        <h6>Sélectionner les offres répondant le mieux aux besoins exprimés (2 minimum, 3 maximum)</h6>
      </b-col>
      <b-col lg="12">
        <b-button class="float-left my-2" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
        <b-button v-if="canNext" class="float-right my-2" size="lg" variant="primary" @click="submitProduit"> Suivant </b-button>
      </b-col>
      <b-col lg="12">
        <b-alert variant="warning" show v-show="getAssureursRisque(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12">
        <b-alert variant="danger" show v-show="msgCodeBbi(risqueLocal[0].produits)" class="mt-1">
          <div class="alert-body">
            <p v-html="msgCodeBbi(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
      <transition name="fade">
        <b-col v-if="showCompareTable" cols="12">
          <div v-for="(rl, index) in this.risqueLocal" :key="index + 'content'" class="mb-5">
            <b-dropdown class="mb-2 float-right" right split text="Trier par" variant="outline-primary">
              <b-dropdown-item @click="filtreUpDown(index)">Prix décroissant</b-dropdown-item>
              <b-dropdown-item @click="filtreDownUp(index)">Prix croissant</b-dropdown-item>
              <b-dropdown-item @click="filtreByTaux(index, 'UpDown')">Adéquation</b-dropdown-item>
              <!-- <b-dropdown-item @click="filtreByTaux(index,'DownUp')">Taux croissant</b-dropdown-item> -->
            </b-dropdown>

            <b-table-simple v-for="(pr, val) in rl.produitsFiltred" :key="val + 'produits'" bordered caption-top class="mb-4" hover responsive>
              <b-thead class="offere_thClass">
                <b-tr>
                  <b-th class="text-center" style="width: 20%">produit</b-th>
                  <b-th class="text-center" style="width: 25%">documents</b-th>
                  <b-th class="text-center" style="width: 15%">options</b-th>
                  <b-th class="text-center" style="width: 25%">adéquation au besoin</b-th>
                  <b-th class="text-center" style="width: 15%">prix</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="offere_tdClass">
                <b-td class="text-center">
                  <p class="box-custom-text">{{ pr.produit.produit_nom }}</p>
                  <template v-if="pr.produit.produit_ws_nom == 'SL_Sante_Retraites'">
                    <b-img :src="require('@/assets/images/logo/assureur/SwissLife.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else-if="pr.produit.produit_ws_nom == 'MALIN'">
                    <b-img :src="require('@/assets/images/logo/assureur/spvie.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else-if="pr.produit.produit_ws_nom == 'SMART'">
                    <b-img :src="require('@/assets/images/logo/assureur/spvie.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  <template v-else>
                    <b-img :src="require('@/assets/images/logo/assureur/eca-assurances.png')" alt="logo" class="mb-1" height="auto" width="100px" />
                  </template>
                  
                  <p class="box-custom-text">{{ pr.produit.assureur.nom_com_assureur }}</p>
                </b-td>
                <b-td>
                  <div v-for="(pda, compt) in pr.produit.document_assureur" :key="compt + 'pda'">
                    <span v-if="pda.document && pda.document.document_type.id !== 108" class="text-nowrap cursor-pointer" @click="showFile(pda.document.id, pda.document.document_type.type)">
                      <feather-icon color="#bf2626" icon="FileIcon" size="14" style="margin-bottom: 4px" />
                      <span class="text-nowrap">{{ pda.document.document_type.type }}</span>
                    </span>
                  </div>
                </b-td>
                <b-td>
                  <b-row v-for="(gr, cpt) in pr.garanties" :key="cpt + 'garantie'">
                    <b-col v-if="cpt !== 0 && hasGarantieNiveau(getGarantieProduit(gr.id, pr.produit.produit_garanties), pr.tarif)" md="12">
                      
                      <span>{{ getGarantieProduitCommercial(gr.id, pr.produit.produit_garanties) }} </span>
                    </b-col>
                    <b-col v-if="cpt !== 0" md="12">
                      <b-form-select v-if="hasGarantieNiveau(getGarantieProduit(gr.id, pr.produit.produit_garanties), pr.tarif)" :value="gr.garantieNiveauID[0]" class="mb-2" size="sm" @change="onChangeNiveau($event, index, val, cpt)">
                        <b-form-select-option v-for="(gn, ind) in gr.garantieniveaux" :key="ind + 'garantiesNiveaux'" :value="gn.id"> {{ gn.garantie_niveau_libel }} </b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <b-row v-if="pr.produit.produitcommissions && pr.produit.produitcommissions.length > 0" v-show="pr.produit.produit_ws_nom !== 'SL_Sante_Retraites' && pr.produit.produit_ws_nom !== 'MALIN' && pr.produit.produit_ws_nom !== 'SMART'">
                    <b-col md="12">
                      <span v-b-tooltip.hover.top="'Commission de 1ère année. Au delà, la commission est linéaire à 10%.'" v-b-tooltip.hover.v-primary class="cursor-pointer">Taux de commission :</span>
                    </b-col>
                    <b-col md="12">
                      <b-form-select v-model="pr.tarif.taux_commission" size="sm" @change="onChangeTaux($event, index, val, pr.tarif.formule, pr.tarif.taux_commission)">
                        <b-form-select-option v-for="(prc, prcIndex) in pr.produit.produitcommissions" :key="prcIndex + 'produitcommision'" :value="prc.commission_value">
                          {{ prc.commission_text }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <b-row v-for="(eg, cptValue) in rl.exigences" :key="cptValue + 'exigence'">
                    <b-col class="m-0 p-0" lg="6">
                      <span v-b-tooltip.hover.top="explicationExigenceLibel(eg.exigence_produits_details, pr.produit.id)" v-b-tooltip.hover.v-primary class="cursor-pointer"> {{ eg.label }} </span>
                    </b-col>
                    <b-col class="m-0 p-0" lg="5"> {{ titleClass(getPtGtNvExigCorrespondant(index, val, eg.id, pr.tarif.formule)) }} {{ eg.id == 278 || eg.id == 277 ? '*' : '' }} </b-col>
                    <!-- <b-col cols="2">{{eg.exigenceNiveauID.length>0 ? titleClass(getExigence(eg.exigenceNiveauID,eg.exigenceniveaux)):'' }}</b-col>
                    <b-col cols="2">{{titleClass(getPtGtNvExig(index,val,eg.id,pr.tarif.formule))}}</b-col> -->
                    <!-- <b-col class="m-0 p-0" lg="1">
                      
                      
                      
                      <feather-icon :color="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule)) ? '#26bf39' : '#bf2626'" :icon="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule)) ? 'PlusIcon' : 'MinusIcon'" size="16" />
                    </b-col> -->

                    <b-col class="m-0 p-0" lg="1" v-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))>0">
                      <feather-icon
                          icon="PlusIcon"
                          size="16"
                          color="#26bf39"
                      />
                                          </b-col>
                    <b-col  v-else-if="comparerExig(getExigence(eg.exigenceNiveauID, eg.exigenceniveaux), getPtGtNvExig(index, val, eg.id, pr.tarif.formule))<0" class="m-0 p-0" lg="1" >
                      <feather-icon
                          icon="MinusIcon"
                          size="16"
                          color="#bf2626"
                      />
                                          </b-col>
                    <b-col  v-else class="m-0 p-0" lg="1">
                      <feather-icon
                          icon="CheckIcon"
                          size="16"
                          color="#26bf39"
                      />
                    </b-col>
                  </b-row>
                </b-td>
                <b-td>
                  <div class="text-center">
                    <div class="px-0">
                      <h4>{{ pr.tarif.formule_commercial }} </h4>
                      <b-badge :variant="getGtNvByNiveauVariant(getGtNvByNiveau(pr.garanties[0], pr.tarif.formule))" class="mb-1">
                        {{ getGtNvByNiveau(pr.garanties[0], pr.tarif.formule) }}
                      </b-badge>
                      <p class="card-text">{{ pr.tarif.libelle == 'Enfant' ? 'Adulte Enfant' : pr.tarif.libelle }}</p>
                      <div class="annual-plan">
                        <div class="plan-price mt-2">
                          <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ Number(pr.tarif.tarif).toFixed(2) }}</span>
                          <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/mois</sub>
                        </div>
                      </div>
                      <b-button v-if="!pr.tarif.presente" v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" size="sm" style="border: 1px solid #7367f0" variant="white" @click.prevent="selectionnerProduit(index, val)"> Sélectionner </b-button>
                      <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2" size="sm" variant="primary" @click.prevent="deselectionnerProduit(index, val)"> Déselectionner </b-button>
                      <!-- <button v-if="!pr.tarif.presente" @click.prevent="selectionnerProduit(index,val)" type="button" class="btn mt-2  btn-sm" style="position: relative;">
                        :variant="pr.tarif.preconise ? 'primary' : 'outline-primary'"
                        Sélectionner
                      </button>
                      <button v-else @click.prevent="deselectionnerProduit(index,val)" type="button" class="btn mt-2 btn-primary btn-sm" style="position: relative;">
                        Déselectionner
                      </button> -->
                    </div>
                  </div>
                </b-td>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
       
      </transition>
    </b-row>
    <b-row v-else>
      <b-col lg="12">
        <b-alert variant="warning" show class="mt-1">
          <div class="alert-body">
            <p v-html="getAssureursRisque(risqueLocal[0].produits)"></p>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-2" cols="12">
        <p>* Adh&eacute;rents aux dispositifs de pratique tarifaire maitris&eacute;e.</p>

        <app-collapse id="collapse-besoin" type="margin">
          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>SwissLife - Détail des options<u></h4>">
            <ul>
              <li>
                <strong>Le module Retraité +</strong> permet de bénéficier de forfaits supplémentaires en médecines douces, pharmacie et dentaire (non remboursés par la Sécurité sociale ou tout autre régime obligatoire) ou aide aux frais d’obsèques. Il propose également un forfait complémentaire pour la chambre particulière en cas d’hospitalisation et le versement d’un capital en cas de perte totale et irréversible d’autonomie.
              </li>
              
              <li>
                <strong>L’option Essentiel Pharma</strong> permet d’optimiser le montant de votre cotisation en limitant la prise en charge aux médicaments remboursés à 65 % par la Sécurité sociale.
              </li>
            </ul>
          </app-collapse-item>

          <app-collapse-item ref="tab-collapse-item-0"  :is-visible="true" title="<h4 class='title-custom-wizard'><u>ECA - Détail des options<u></h4>">
              <ul>
                <li>
                  <strong>L’option Renfort + </strong> permet de bénéficier de garanties en médecines douces et médecines non remboursées par l’Assurance Maladie obligatoire. Elle comprend des forfaits supplémentaires pour la prise en charge de la chambre particulière en secteur conventionné, pour les prothèses dentaires, l’implantologie et la parodontologie non remboursées par l’Assurance Maladie Obligatoire. Elle propose également un capital forfaitaire en cas de décès accidentel de l’Adhérent ou de l’un des assurés au contrat ainsi qu’un capital perte d’autonomie. 
                </li>
              
                <li>
                  <strong>L’option Pharma +</strong> vise à prendre en charge les médicaments remboursés à 65%, 30% et 15%  par l’Assurance Maladie Obligatoire.                
                </li>
              </ul>
            </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-button class="float-left" size="lg" variant="primary" @click="backStep"> Précédent </b-button>
        <b-button v-if="canNext" class="float-right" size="lg" variant="primary" @click="submitProduit"> Suivant </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-display-document" ref="modal-display-document" :size="document.extensionDocument === 'PDF' ? 'xl' : 'sm'" :title="document.name" ok-only ok-title="Fermer" ok-variant="outline-secondary" @ok="clearDocumentDisplay">
      <form ref="form" :style="{ height: document.extensionDocument === 'PDF' ? '80vh' : 'auto', overflow: 'hidden' }">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :alt="document.name" :src="document.base64" fluid />
        <iframe v-else-if="document.extensionDocument === 'PDF'" :src="document.base64" height="100%" width="100%" :style="hideToolbar()" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import { selectRisqueAndProduct, updateProduitTarif } from './../../../../shared/utils/TarifCalcul'
import { BBadge, BButton, BCard, BCardText, BCol, BDropdown, BDropdownDivider, BDropdownItem, BFormGroup, BFormInput, BFormInvalidFeedback, BFormRadio, BFormRadioGroup, BFormSelect, BFormSelectOption, BFormTextarea, BImg, BListGroup, BListGroupItem, BModal, BOverlay, BRow, BTab, BTable, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr, VBTooltip, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BBadge,
    BRow,
    BImg,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    BAlert
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      risqueLocal: [],
      produitGarantieNiveau: [],
      tarif: Math.floor(Math.random() * 100) + 60,
      loading: false,
      indexLocal: 0,
      test: null,
      indexRisque: 0,
      hasProduct: false,
      showCompareTable: true,
      showLoading: false,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      }
    }
  },
  computed: {
    ...mapGetters(['getItemsRisque']),
    ...mapGetters(['getSelectedRisque']),
    ...mapGetters(['Tiers']),
    ...mapGetters(['Conjoint']),
    canNext: function () {
      let nbrPresente = _.filter(this.risqueLocal[0].produitsFiltred, function (p) {
        return p.tarif.presente
      })
      if (nbrPresente.length > 1) {
        return true
      }

      return false

      // return _.find(this.risqueLocal[0].produitsFiltred, function(p) { return p.tarif.presente; });
    }
  },
  watch: {
    getItemsRisque: {
      handler: function (val, oldVal) {
        this.risqueLocal = this.getItemsRisque.ItemsRisque
        this.arrayProduit()
        this.hasProduct = false
        if (this.risqueLocal.length > 0) {
          this.risqueLocal.map(risque => {
            if (risque.produitsFiltred.length > 0 && !this.hasProduct) {
              this.hasProduct = true
            }
            if (risque.produitsFiltred.length > 0) {
              risque.produitsFiltred.map(produit => {
                produit.tarif.taux_commission = '0'
              })

              this.updateOrderTarification(risque.produitsFiltred)
            }
          })
        }
      },
      immediate: true
    },
    getSelectedRisque: function (val, old) {
      this.indexRisque = this.getSelectedRisque
    }
  },
  methods: {
    explicationExigenceLibel(exProduitsDetails, produitSelected) {
      let expDetail = exProduitsDetails.find((exp) => exp.produit_id == produitSelected)
      return expDetail != undefined ? expDetail.info : ''
    },
    produitHasNotTarif(produits, produitId) {
      var response = false
      console.log(produits.find(pr => pr.id == produitId))
      if (produits.find(pr => pr.id == produitId) === undefined) {
        response = true
      }
      produits.filter(pr => {
        if (pr.tarif.length <= 0 && produitId == pr.id) {
          response = true
        }
      })      
      return response
    },
    getAssureursRisque(produits) {
      var innerHTML = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0) {
            assureurs.push(pr.assureur.nom_com_assureur + ' (' + pr.produit_nom + ')');
        }
        return assureurs;
      }, []);
      if(assureurs.length > 0){
        innerHTML = `Aucun tarif disponible pour ${assureurs.length == 1 ? 'la compagnie' : 'les companies'} : <strong>${assureurs.join(", ")}</strong>`
      }
      return innerHTML
    },
    msgCodeBbi(produits){
      var innerHTML = null
      let msgError = null
      var assureurs = produits.reduce((assureurs, pr) => {
        if (pr.tarif <= 0 && pr.errors) {
            msgError = pr.errors
            assureurs.push(pr.assureur.nom_com_assureur + ' (' + pr.produit_nom + ')');
        }
        return assureurs;
      }, []);
      if(assureurs.length > 0){
        const hasECA = assureurs.some(element => element.includes('ECA'));
        let erreurCodeBBi ="Vous n'avez pas l'accès à ce courtier"
        if(hasECA && msgError.includes(erreurCodeBBi) )
        innerHTML = `Une erreur avec le code ECA a été détecté, merci de vous rapprocher de notre support`
      }
      return innerHTML
    },
    getGtNvByNiveauVariant(niveau) {
      switch (niveau) {
        case 'Economique':
          return 'light-success'
        case 'Complète':
          return 'light-warning'
        case 'Haut de gamme':
          return 'light-danger'
        default:
          return 'light-success'
      }
    },
    getGtNvByNiveau(garantie, niveau) {
      let tmpGNPOption = null
      garantie.produit_garantie.forEach(element => {
        element.garantiesniveaux.forEach(element1 => {
          if (element1.pivot.libelle == niveau) {
            tmpGNPOption = element1.garantie_niveau_libel
            return tmpGNPOption
          }
        });
      });
      // let tmpGNPOption = _.find(garantie.produit_garantie.garantiesniveaux, (value, key) => value.pivot.libelle == niveau)
      return tmpGNPOption
    },
    titleClass(word) {
      return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''
    },
    updateIndexDevis(value) {
      this.indexRisque = value
      this.$store.commit('setSelectedRisque', {
        selectedRisque: value
      })
    },
    dupliquer(risque, produit) {
      let produitD = this.risqueLocal[risque].produits[produit]
      this.risqueLocal[risque].produits.push(produitD)
    },
    selectionnerProduit(risque, produit) {
      let produitsCpt = this.risqueLocal[risque].produitsFiltred.filter(pr => pr.tarif.presente)
      if (produitsCpt.length < 3) {
        this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = true
      }
      if (produitsCpt.length == 3) {
        this.messageToast('', 'Vous ne pouvez pas sélectionner plus de 3 offres', 'danger', 'EditIcon')
      }
    },
    deselectionnerProduit(risque, produit) {
      this.risqueLocal[risque].produitsFiltred[produit].tarif.presente = false
      this.risqueLocal[risque].produitsFiltred[produit].tarif.preconise = false
    },
    getPtGtNvExig(risque, produit, exigence, formule) {
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? resultFiltre[0].exigence_niveau_libel : ''
        }
      } else {
        return ''
      }
    },

    getPtGtNvExigCorrespondant(risque, produit, exigence, formule) {
      let result = this.risqueLocal[risque].produitsFiltred[produit].produit.produit_garanties[0].produitgarantiesniveaux.filter(pgn => pgn.libelle === formule)
      if (result.length > 0) {
        if (result[0].exigencesniveaux.length > 0) {
          let resultFiltre = result[0].exigencesniveaux.filter(egn => egn.exigence_id === exigence)
          return resultFiltre.length > 0 ? (resultFiltre[0].pivot ? resultFiltre[0].pivot.label_correspondant : '') : ''
        }
      } else {
        return ''
      }
    },
    getGarantieProduit(garantie, produitGarantie) {
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle : ''
    },
    getGarantieProduitCommercial(garantie, produitGarantie) {
      let found = produitGarantie.find(pg => pg.garantie_id === garantie)
      return found ? found.libelle_comm : ''
    },
    comparerExig(ex1, ex2) {
      let i=this.getExByScoringNumber(ex1);
      let j=this.getExByScoringNumber(ex2);
      if(i<j) return 1;
      else if(i>j) return -1;
      else return 0;
    },
    getExByScoringNumber(ex){
      switch(ex){
            case 'NON':
                return 0;
            case 'MIN':
                return 1;
            case 'MOYEN':
                return 2
            case 'FORT':
                return 3;
            case 'MAX':
                return 4;
            case 'MAXPLUS':
                return 5;
            default:
                return 1;
        }
    },
    updateOrderTarification(tarification) {
      console.log(tarification)
      let data = []
      tarification.forEach((el, index) => {
        data.push({ "tarificationId": el.tarif.tarification_id, index })
      })
      this.$http.post('portefeuilles/updateOrderTarification', data).then(response => {
        if (response.data.success) { console.log('updated') }
      }).catch(e => {console.error(e)})
    },
    filtreDownUp(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => a.tarif.tarif - b.tarif.tarif)
        this.showCompareTable = true
      }, 10)
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    filtreUpDown(risque) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred.sort((a, b) => b.tarif.tarif - a.tarif.tarif)
        this.showCompareTable = true
      }, 10)
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    filtreByTaux(risque, type) {
      this.showCompareTable = false
      setTimeout(() => {
        this.risqueLocal[risque].produitsFiltred = type == 'UpDown' ? this.sortByTauxDec(this.risqueLocal[risque].produitsFiltred) : this.sortByTauxCr(this.risqueLocal[risque].produitsFiltred)
        this.showCompareTable = true
      }, 10)
      this.updateOrderTarification(this.risqueLocal[risque].produitsFiltred)
    },
    async submitProduit() {
      this.showLoading = true
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      let produisPresente = []
      if (this.risqueLocal[this.indexRisque].produitsFiltred.length > 0) {
        this.risqueLocal[this.indexRisque].produitsFiltred.map(rlp => {
          produisPresente.push({
            presente: rlp.tarif.presente,
            taux_commission: rlp.tarif.taux_commission ? rlp.tarif.taux_commission : '0',
            prime: rlp.tarif.tarif,
            tarification_id: rlp.tarif.tarification_id
          })
        })
      }
      if (produisPresente.length > 0) {
        let data = {
          produitsPresentes: produisPresente,
          devis_id: this.risqueLocal[this.indexRisque].devis.id
        }
        await this.$http.post(`portefeuilles/updateTarifPresente`, data).then(response => {
          if (response.data) {
            console.log('updated')
          }
        })
      }
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 3,
          active: 'resume'
        }
      })
      this.showLoading = false
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 1,
          active: 'profil'
        }
      })
    },
    getTarif(selected, pg, tarif) {
      let found = pg.find(pgv => pgv.id === selected)
      let tmpTarif = found ? tarif.find(tf => tf.formule === found.libelle) : 'NaN'
      return tmpTarif.tarif
    },
    getScore(value) {
      return value ? (value * 100).toFixed(1) : '0'
    },
    arrayProduit() {
      let produitFiltre = []
      if (this.risqueLocal.length > 0) {
        this.risqueLocal.map(risque => {
          produitFiltre = []
          risque.produitsFiltred = []
          risque.produits.map(produit => {
            if (produit.tarif) {
              produit.tarif.map(tf => {
                const { tarif, ...newProduit } = produit
                // let garantiesLocal = tf.garanties

                let garantiesLocal = tf.garanties.filter(d => {
                if(Array.isArray(d.produit_garantie)){
                return d.produit_garantie.some(p => p.produit_id === produit.id);
                }else{
                return d.produit_garantie.produit_id === produit.id;
                }
                }).map(d => {
                if(Array.isArray(d.produit_garantie)){
                d.produit_garantie = d.produit_garantie.filter(p => p.produit_id === produit.id);
                }
                return d;
                });

                const { garanties, ...tarifLocal } = tf
                produitFiltre.push({
                  produit: newProduit,
                  garanties: garantiesLocal,
                  tarif: tarifLocal
                })
              })
            }
          })
          if (produitFiltre.length > 0) {
            let data = {
              produits: produitFiltre,
              exigences: risque.exigences,
              garanties: risque.garanties,
              risque: risque.risque.label,
              objets: risque.objets
            }
            let tmpProduits = selectRisqueAndProduct(data)
            risque.produitsFiltred = this.sortByTauxDec(tmpProduits)

          }
        })
        this.risqueLocal[0].produitsFiltred.forEach(e => {  
          e.produit.document_assureur.sort((a, b) => {
            let fa = a.document.document_type.type.toLowerCase(),
              fb = b.document.document_type.type.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }

            return 0;
          });
        })        
      }
    },
    showFile(id, type) {
      this.displayDocument(id, type, type)
    },
    sortByTauxDec(data) {
      return data.sort(function (a, b) {
        return b.tarif.tauxGn - a.tarif.tauxGn || b.tarif.tauxCouv - a.tarif.tauxCouv || a.tarif.tarif.tarif - b.tarif.tarif.tarif
      })
    },
    sortByTauxCr(data) {
      function DownUpcompare(a, b) {
        if (a.tarif.taux == b.tarif.taux) {
          if (a.tarif.tauxtype < b.tarif.tauxtype) {
            return -1
          }
          if (a.tarif.tauxtype > b.tarif.tauxtype) {
            return 1
          }
        } else {
          if (a.tarif.taux < b.tarif.taux) {
            return -1
          }
          if (a.tarif.taux > b.tarif.taux) {
            return 1
          }
        }
        return 0
      }

      data.sort(DownUpcompare)
      return data
    },
    hasGarantieNiveau(gn, options) {
      let tmpOption = _.find(options.optionsCompatibles, (v, key) => key == gn)
      return tmpOption ? true : false
    },
    getExigence(egn, egnArray) {
      let tmpOption = _.find(egnArray, (value, key) => value.id == egn)
      return tmpOption ? tmpOption.exigence_niveau_libel : ''
    },
    formatText(str) {
      var map = {
        '-': ' ',
        '-': '_',
        a: 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
        e: 'é|è|ê|ë|É|È|Ê|Ë',
        i: 'í|ì|î|ï|Í|Ì|Î|Ï',
        o: 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
        u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        c: 'ç|Ç',
        n: 'ñ|Ñ'
      }
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern)
      }

      return str.split(' ').join('').toUpperCase()
    },

    async onChangeNiveau(event, index, val, gr) {
      // console.log(event, index, val, gr)
      let produitTmp = this.risqueLocal[index].produitsFiltred[val]
      let risqueTmp = this.risqueLocal[index].risque
      let garantiesTmp = this.risqueLocal[index].produitsFiltred[val].garanties
      garantiesTmp[gr].garantieNiveauID[0] = event

      if (this.formatText(risqueTmp.label) == 'SANTESENIORS' && produitTmp.produit.assureur.nom_assureur == 'SL') {
        let result = _.find(garantiesTmp[gr].garantieniveaux, gn => gn.id == event && gn.garantie_niveau_libel == 'Oui')

        if (result) {
          // garantiesTmp.map((gt, key) => {
          //   if (key != 0 && key != gr) {
          //     let resultgr = _.find(gt.garantieniveaux, function (gn) {
          //       return gn.garantie_niveau_libel == 'Non'
          //     })
          //     gt.garantieNiveauID[0] = resultgr.id
          //   }
          // })
        }
      }

      let { objets } = this.risqueLocal[index]
      let result = await updateProduitTarif(garantiesTmp, produitTmp, this.risqueLocal[index].risque.label, objets)

      this.risqueLocal[index].produitsFiltred[val].tarif.tarif = result

      let dataSent = {
        id: garantiesTmp[gr].tarificationProduitGtNv,
        garantieNvId: garantiesTmp[gr].garantieNiveauID[0]
      }
      await this.$http.post('portefeuilles/updateTarificationNiveauGtNv', dataSent).then(response => {
        if (response.data) {
          // console.log('updated')
        }
      })
    },

    // ---------------------------------------------
    onChangeTaux(event, index, val, formule, taux_commission) {
      this.showLoading = true
      let data = {
        regime: this.Tiers.regime_obligatoire,
        dateNaissance: this.Tiers.date_naissance,
        dateEffet: this.risqueLocal[index].objets.objet_sante_seniors.date_effet,
        produit: 'SL_Sante_Retraites',
        formule,
        taux_commission
      }

      if (this.Tiers.regime_obligatoire !== 'REGIME_ALSACE_MOSELLE' || this.Conjoint.regime_obligatoire !== 'REGIME_ALSACE_MOSELLE') {
        data.codePostal = this.Tiers.moyen_contact.code_postal
      }

      if (this.risqueLocal[index].objets.objet_sante_seniors.conjoint > 0) {
        data.assurerVotreConjoint = 'OUI'
        data.dateNaissanceConjoint = this.Conjoint.date_naissance
        data.regimeConjoint = this.Conjoint.regime_obligatoire
      } else {
        data.assurerVotreConjoint = 'NON'
      }
      this.$http
        .post('portefeuilles/calculTarifWithTauxByNiveau', data)
        .then(response => {
          if (response.data) {
            // this.risqueLocal[index].produitsFiltred[val].tarif.tarif=response.data.tarifs.mensuelle.toFixed(2)
            this.risqueLocal[index].produitsFiltred[val].tarif.tarif_base = response.data.tarifs.mensuelle.toFixed(2)
            // Options
            this.risqueLocal[index].produitsFiltred[val].tarif.optionsCompatibles = response.data.optionsCompatibles

            let objets = this.risqueLocal[index].objets
            let produitTmp = this.risqueLocal[index].produitsFiltred[val]
            let garantiesTmp = this.risqueLocal[index].produitsFiltred[val].garanties

            let result = updateProduitTarif(garantiesTmp, produitTmp, this.risqueLocal[index].risque.label, objets)

            this.risqueLocal[index].produitsFiltred[val].tarif.tarif = result
          }
        })
        .catch(err => {
          console.log(err)
          this.showLoading = false
        })
        .finally(() => {
          this.showLoading = false
        })
    },

    async onChangeTauxGlobale(event, index,val){

      this.showLoading=true
      let data = {
        isConjoint : this.risqueLocal[index].objets.objet_sante_seniors.conjoint,
        produitAssureur : this.risqueLocal[index].produitsFiltred[val].produit.assureur.nom_assureur,
        produitTarif:this.risqueLocal[index].produitsFiltred[val].tarif,
        objet:this.risqueLocal[index].objets,
        risque:this.risqueLocal[index].risque,
        assure:this.Tiers,
        devis : this.risqueLocal[index].devis,
        produit : this.risqueLocal[index].produitsFiltred[val].produit,
        conjoint : this.Conjoint,
        tauxCommision : event, 
      }

      await this.$http.post(`portefeuilles/getTarifByFormule`,data).then((response)=>{
        if(response.data.statut==201){
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif = response.data.data.tarifs.mensuelle
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif_base = response.data.data.tarifs.mensuelle
          let produitTmp  =this.risqueLocal[index].produitsFiltred[val]
          let garantiesTmp=this.risqueLocal[index].produitsFiltred[val].garanties
          let result=updateProduitTarif(garantiesTmp,produitTmp,this.risqueLocal[index].risque.label,this.risqueLocal[index].objets)
          this.risqueLocal[index].produitsFiltred[val].tarif.tarif=result
          this.showLoading=false
        }
        }).catch((err)=>{
        this.showLoading=false
      })

      },

    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    displayDocument(id, name, NomDuDocument) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document'].show()
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    }
    // ShowFile(document) {
    //   var str = document.path + document.nom
    //   var width = 1000
    //   var height = 600
    //   var left = parseInt(screen.availWidth / 2 - width / 2)
    //   var top = parseInt(screen.availHeight / 2 - height / 2)
    //   var windowFeatures = 'width=' + width + ',height=' + height + ',status,resizable,left=' + left + ',top=' + top + 'screenX=' + left + ',screenY=' + top
    //   window.open(path, 'subWind', windowFeatures)
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

.offere_thClass th {
  background-color: #ebe9f1 !important;
  color: #4d25bc;
}
</style>

<style lang="scss" scoped>
#risque_prevoyance.btn-group {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

#risque_prevoyance > .sante_risque {
  min-width: calc(25% - 2rem);
  margin: 1rem;
  padding: 25px 15px;
  border-right: 1px solid #4d25bc !important;
  border-radius: 0.358rem;
}

#risque_prevoyance > .sante_risque.active {
  background-color: #4d25bc;
  color: #fff;
}

#risque_prevoyance .btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: #4d25bc;
  color: #fff;
  transition: 0.4s;
}

#addprojectform .nav-tabs .nav-link {
  background-color: rgba(77, 37, 188, 0.08);
  color: #4d25bc;
}

#addprojectform .nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked {
  background-color: #4d25bc !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li a .checked i {
  color: #fff !important;
}

.wizard-btn {
  background-color: #4d25bc !important;
  border-color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn {
  border-color: #4d25bc !important;
  color: #4d25bc !important;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn:hover {
  color: #fff !important;
  background-color: #4d25bc !important;
  transition: 0.3s;
  box-shadow: 0 8px 25px -8px #4d25bc;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked ~ .stepTitle {
  color: #4d25bc !important;
}

@media only screen and (max-width: 768px) {
  #risque_prevoyance > .sante_risque {
    max-width: calc(50% - 2rem);
  }
}

#addprojectform h4 span {
  font-size: 15px;
  color: #000;
}

.padding_pricing {
  padding: 0rem 1.5rem 1.5rem;
}

.propositions .card-body {
  padding: 0 !important;
}

.propositions .card-header {
  background-color: #4d25bc;
  color: #fff;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.propositions .card button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 15px 0px;
}

.box-custom-text {
  color: #7353ca;
}
</style>

<template>
  <div style="witdh: 100%; margin-top: 30px;">
    <b-overlay :show="showLoading" no-wrap />

    <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh: 100%;">
      <b-popover container="lg-popover" :target="option.value" placement="buttom" triggers="hover">
        <template #title>
          {{ option.infoBull.title }}
        </template>

        <div class="text-justify" v-html="option.infoBull.description"></div>
      </b-popover>
    </div>
    <div v-if="objetEmprunteur.has_pret_secondaire">
      <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh: 100%;">
        <b-popover container="lg-popover" :target="option.value+'1'" placement="buttom" triggers="hover">
          <template #title>
            {{ option.infoBull.title }}
          </template>

          <div class="text-justify" v-html="option.infoBull.description"></div>
        </b-popover>
      </div>
    </div>
    <div v-if="objetEmprunteur.emprunteur.has_co_emprunteur">
      <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh: 100%;">
        <b-popover container="lg-popover" :target="option.value + '2'" placement="buttom" triggers="hover">
          <template #title>
            {{ option.infoBull.title }}
          </template>

          <div class="text-justify" v-html="option.infoBull.description"></div>
        </b-popover>
      </div>
      <div v-if="objetEmprunteur.has_pret_secondaire">
        <div v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" style="witdh: 100%;">
          <b-popover container="lg-popover" :target="option.value + '3'" placement="buttom" triggers="hover">
            <template #title>
              {{ option.infoBull.title }}
            </template>

            <div class="text-justify" v-html="option.infoBull.description"></div>
          </b-popover>
        </div>
      </div>
    </div>

    <b-popover container="body" target="my_popover_1" placement="buttom" triggers="hover">
      <template #title>
        Kilomètres professionnel par an
      </template>
      Réalisé avec un véhicule terrestre à moteur et hors domicile/travail
    </b-popover>

    <b-popover container="lg-popover" target="my_popover" placement="buttom" triggers="hover">
      <template #title>
        Vente à distance - Obligations relatives au démarchage téléphonique en assurances
      </template>

      <p>
        Dans le cadre de votre mission, vous vous engagez &agrave; respecter l&rsquo;ensemble des r&egrave;gles relatives au d&eacute;marchage t&eacute;l&eacute;phonique, y comprise la loi n&deg;2021-402 du 8 avril 2021, le
        d&eacute;cret n&deg;2022-34 du 17 janvier 2022 et le d&eacute;cret n&deg;2022-1313 du 13 octobre 2022.
      </p>
      <p><u>Vos obligations </u>:</p>
      <ul>
        <li>Mettre en place une vente en deux temps lorsque vous d&eacute;marchez par t&eacute;l&eacute;phone un prospect qui n&rsquo;est pas client ou qui n&rsquo;a pas sollicit&eacute; votre appel de mani&egrave;re active.</li>
        <li>Informer le prospect d&egrave;s le d&eacute;but de l&rsquo;appel que celui-ci est enregistr&eacute; et qu&rsquo;il peut obtenir une copie de cet enregistrement s&rsquo;il en fait la demande.</li>
        <li>Mettre fin &agrave; l&rsquo;appel si le prospect refuse l&rsquo;enregistrement.</li>
        <li>Vous vous engagez &agrave; enregistrer l&rsquo;ensemble des conversations t&eacute;l&eacute;phoniques qui auront amen&eacute; &agrave; la conclusion du contrat d&rsquo;assurance et &agrave; les conserver.&nbsp;</li>
        <li>La signature &eacute;lectronique ne pourra intervenir lors du deuxi&egrave;me appel et vous ne pourrez pas l&rsquo;accompagner durant ce processus de signature.</li>
        <li>S&rsquo;assurer de permettre la tra&ccedil;abilit&eacute; et l&rsquo;int&eacute;grit&eacute; de l&rsquo;ensemble des appels t&eacute;l&eacute;phoniques.</li>
        <li>
          Respecter strictement le d&eacute;cret du 13 octobre 2022 encadrant les jours, horaires et fr&eacute;quence d&rsquo;appels quand celui-ci s&rsquo;applique. Les appels dans ce cas devront &ecirc;tre pass&eacute;s entre
          10h et 13h et entre 14h &agrave; 20h du lundi au vendredi. Ils sont en revanche interdits les samedis, dimanches et jours f&eacute;ri&eacute;s. Cet encadrement s&#39;applique aussi bien aux personnes non inscrites sur la
          liste Bloctel qu&#39;&agrave; celles inscrites mais sollicit&eacute;es dans le cadre d&#39;un contrat en cours.
        </li>
        <li>
          Limitation stricte de l&#39;acc&egrave;s aux enregistrements aux seuls agents de l&#39;Autorit&eacute; de contr&ocirc;le prudentiel et de r&eacute;solution et aux agents mentionn&eacute;s aux articles L. 511-3 et L.
          511-21 du code de la consommation.
        </li>
      </ul>
      <p><u>Destruction des enregistrements</u> :</p>
      <ul>
        <li>
          Sans d&eacute;lai, lorsque le souscripteur ou l&#39;adh&eacute;rent &eacute;ventuel s&#39;est explicitement oppos&eacute; &agrave; la poursuite de la communication t&eacute;l&eacute;phonique ou &agrave; la proposition
          commerciale
        </li>
        <li>
          En l&#39;absence de r&eacute;ponse favorable &agrave; une proposition commerciale, dans un d&eacute;lai d&#39;un mois &agrave; compter de la date de cette proposition (le souscripteur ou l&#39;adh&eacute;rent
          &eacute;ventuel manifestant ainsi une absence d&#39;int&eacute;r&ecirc;t ou son souhait de ne pas donner suite &agrave; cette proposition)
        </li>
      </ul>
    </b-popover>

    <validation-observer ref="AssureRules">
      <b-form>
        <app-collapse id="collapse-besoin" type="margin">
          <b-row>
            <b-col md="12">
              <b-alert v-show="errorsTarif.length > 0" variant="danger" show class="mt-1">
                <div class="alert-body">
                  <ul class="mb-0">
                    <li v-for="(error, index) in errorsTarif" :key="`erreur-${index}`">
                      {{ error.message }}
                    </li>
                  </ul>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="justify-content-center flex-column d-flex mr-0">
              <b-form-group id="lg-popover">
                <label class="float-left pr-4 mb-1">
                  Cette souscription s'effectue-t-elle dans le cadre d'une vente à distance ?

                  <span id="my_popover" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer">
                                        <feather-icon icon="InfoIcon" size="20" />
                                    </span>
                  <!-- <span
                    v-b-popover.top.click.html="`<p>Dans le cadre de votre mission, vous vous engagez &agrave; respecter l&rsquo;ensemble des r&egrave;gles relatives au d&eacute;marchage t&eacute;l&eacute;phonique, y comprise la loi n&deg;2021-402 du 8 avril 2021, le d&eacute;cret n&deg;2022-34 du 17 janvier 2022 et le d&eacute;cret n&deg;2022-1313 du 13 octobre 2022.</p>
                    <p><u>Vos obligations </u>:</p>
                    <ul>
                        <li>Mettre en place une vente en deux temps lorsque vous d&eacute;marchez par t&eacute;l&eacute;phone un prospect qui n&rsquo;est pas client ou qui n&rsquo;a pas sollicit&eacute; votre appel de mani&egrave;re active.</li>
                        <li>Informer le prospect d&egrave;s le d&eacute;but de l&rsquo;appel que celui-ci est enregistr&eacute; et qu&rsquo;il peut obtenir une copie de cet enregistrement s&rsquo;il en fait la demande.</li>
                        <li>Mettre fin &agrave; l&rsquo;appel si le prospect refuse l&rsquo;enregistrement.</li>
                        <li>Vous vous engagez &agrave; enregistrer l&rsquo;ensemble des conversations t&eacute;l&eacute;phoniques qui auront amen&eacute; &agrave; la conclusion du contrat d&rsquo;assurance et &agrave; les conserver.&nbsp;</li>
                        <li>La signature &eacute;lectronique ne pourra intervenir lors du deuxi&egrave;me appel et vous ne pourrez pas l&rsquo;accompagner durant ce processus de signature.</li>
                        <li>S&rsquo;assurer de permettre la tra&ccedil;abilit&eacute; et l&rsquo;int&eacute;grit&eacute; de l&rsquo;ensemble des appels t&eacute;l&eacute;phoniques.</li>
                        <li>Respecter strictement le d&eacute;cret du 13 octobre 2022 encadrant les jours, horaires et fr&eacute;quence d&rsquo;appels quand celui-ci s&rsquo;applique. Les appels dans ce cas devront &ecirc;tre pass&eacute;s entre 10h et 13h et entre 14h &agrave; 20h du lundi au vendredi. Ils sont en revanche interdits les samedis, dimanches et jours f&eacute;ri&eacute;s. Cet encadrement s&#39;applique aussi bien aux personnes non inscrites sur la liste Bloctel qu&#39;&agrave; celles inscrites mais sollicit&eacute;es dans le cadre d&#39;un contrat en cours.</li>
                        <li>Limitation stricte de l&#39;acc&egrave;s aux enregistrements aux seuls agents de l&#39;Autorit&eacute; de contr&ocirc;le prudentiel et de r&eacute;solution et aux agents mentionn&eacute;s aux articles L. 511-3 et L. 511-21 du code de la consommation.</li>
                    </ul>
                    <p><u>Destruction des enregistrements</u> :</p>
                    <ul>
                        <li>Sans d&eacute;lai, lorsque le souscripteur ou l&#39;adh&eacute;rent &eacute;ventuel s&#39;est explicitement oppos&eacute; &agrave; la poursuite de la communication t&eacute;l&eacute;phonique ou &agrave; la proposition commerciale</li>
                        <li>En l&#39;absence de r&eacute;ponse favorable &agrave; une proposition commerciale, dans un d&eacute;lai d&#39;un mois &agrave; compter de la date de cette proposition (le souscripteur ou l&#39;adh&eacute;rent &eacute;ventuel manifestant ainsi une absence d&#39;int&eacute;r&ecirc;t ou son souhait de ne pas donner suite &agrave; cette proposition) &raquo;</li>
                    </ul>
                    `"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :title="'Vente à distance - Obligations relatives au démarchage téléphonique en assurances'"
                    class="ml-1 cursor-pointer"
                    variant="outline-primary"
                  ><feather-icon
                    icon="InfoIcon"
                    size="20"
                  />
                  </span> -->
                </label>
                <b-form-radio-group v-model="devis.is_a_distance" :options="optionsVenteDistance" name="radio-vente-a-distance" />
              </b-form-group>

              <b-form-group v-if="devis.is_a_distance">
                <label class="float-left pr-4 mb-1">De quel type de vente s'agit-il ?</label>
                <validation-provider #default="{ errors }" name="type de vente" rules="required">
                  <b-form-radio-group v-model="devis.type_vente" name="radio-type-de-vente" :state="errors.length > 0 ? false : null">
                    <b-form-radio v-for="option in optionsTypeVente" :key="option.value" :value="option.value">
                      {{ option.text }}
                      <span v-b-popover.hover.top="option.infoBulle" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.text" class="ml-1 cursor-pointer" variant="outline-primary">
                                                <feather-icon icon="InfoIcon" size="20" />
                                            </span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    Le choix est obligatoire
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <label class="float-left pr-4 mb-1">Le proposant est-il éligible au cadre du RIA ?</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="le proposant est-il éligible au cadre du RIA"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="objetEmprunteur.ria"
                    :options="BooleanValue"
                    label="Je souhaite réaliser une RIA pour le client"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    Le choix est obligatoire
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group> -->
            </b-col>
            <!-- <b-col md="12">
              <b-alert
                v-show="objetEmprunteur.ria"
                variant="danger"
                show
                class="mb-2"
              >
                <div class="alert-body">
                  <p>Attention&nbsp;: La R&eacute;siliation Infra Annuelle (RIA) ne concerne que les contrats (automobile, habitation et sant&eacute;) souscrits depuis plus d&rsquo;un an. La date d'effet du nouveau contrat doit intervenir imm&eacute;diatement apr&egrave;s la r&eacute;siliation du premier contrat. La r&eacute;glementation pr&eacute;voit que le nouveau contrat prend effet 30 jours &agrave; compter de la r&eacute;ception de la r&eacute;siliation par l&rsquo;ancien assureur.</p>
                  <p>Un mandat de r&eacute;siliation sera propos&eacute; &agrave; la signature en fin de parcours et devra &ecirc;tre adress&eacute; par vos soins &agrave; l&rsquo;ancien assureur.</p>
                  <p>Pour prendre en compte le d&eacute;lai de r&eacute;alisation de ces d&eacute;marches, BubbleIn pr&eacute;voit donc une prise d&rsquo;effet au plus t&ocirc;t le {{ riaDate }} soit 34 jours (+24h en cas de vente en 2 temps) &agrave; compter d&rsquo;aujourd&rsquo;hui. Nous vous invitons n&eacute;anmoins &agrave; prendre une marge de man&oelig;uvre suppl&eacute;mentaire en fonction du temps estim&eacute; de votre d&eacute;marche commerciale. &nbsp;Merci de v&eacute;rifier et modifier le cas &eacute;ch&eacute;ant l&rsquo;option et la date renseign&eacute;es ci-dessus.</p>
                  <p>Si le contrat a moins d'un an, l&rsquo;assur&eacute; doit proc&eacute;der lui-m&ecirc;me &agrave; la r&eacute;siliation de son contrat &agrave; l'&eacute;ch&eacute;ance anniversaire, en respectant les d&eacute;lais de pr&eacute;avis pr&eacute;vus au contrat.</p>
                </div>
              </b-alert>
            </b-col> -->
          </b-row>
          <!-- Assuré principal (emprunteur) -->
          <app-collapse-item ref="tab-collapse-item-0" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Assuré principal</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label class="mb-1">Type d'assuré *</label>
                      <validation-provider #default="{ errors }" name="type d'assuré" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.type_assure" name="radio-type-assure" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <template v-for="option in TypeAssure">
                            <b-form-radio v-if="option.value != 'CO_EMPRUNTEUR'" :key="option.value" :value="option.value" class="mt-0">
                              <small>{{ option.text }}</small>
                            </b-form-radio>
                          </template>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Civilité *" label-for="assure_civilite">
                      <validation-provider #default="{ errors }" name="civilité" rules="required">
                        <b-form-select id="assure_civilite" v-model="assure.civilite" :options="civilite" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Nom *" label-for="assure_nom">
                      <div v-if="assure.statut_gda==='VERT' || assure.statut_gda==='ROUGE' || assure.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                        {{ assure.nom }}
                      </div>
                      <validation-provider v-else #default="{ errors }" name="nom" rules="required">
                        <b-form-input id="assure_nom" v-model="assure.nom" :state="errors.length > 0 ? false : null" class="text-capitalize" placeholder="Nom" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Nom de naissance *" label-for="assure_nom_naissance">
                      <validation-provider #default="{ errors }" name="nom de naissance" rules="required">
                        <b-form-input id="assure_nom_naissance" v-model="assure.nom_naissance" :state="errors.length > 0 ? false : null" class="text-capitalize" placeholder="Nom de naissance" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Prénom *" label-for="assure_prenom">
                      <div v-if="assure.statut_gda==='VERT' || assure.statut_gda==='ROUGE' || assure.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                        {{ assure.prenom }}
                      </div>
                      <validation-provider v-else #default="{ errors }" name="prénom" rules="required">
                        <b-form-input id="assure_prenom" v-model="assure.prenom" :state="errors.length > 0 ? false : null" class="text-capitalize" placeholder="Prénom" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Date de naissance *" label-for="date-naissance">
                      <div v-if="assure.statut_gda==='VERT' || assure.statut_gda==='ROUGE' || assure.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                        {{ assure.date_naissance }}
                      </div>
                      <validation-provider v-else #default="{ errors }" name="date de naissance" rules="required">
                        <flat-pickr
                            id="date-naissance"
                            v-model="assure.date_naissance"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate"
                            class="form-control"
                            placeholder="Date de naissance"
                            autocomplete="off"
                            @input="initPrecision(assure.statut, 'emprunteur', assure.date_naissance)"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback :state="errorDateNaissanceProspect ? false : null">
                          {{ errorDateNaissanceProspect }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Code postal de naissance *" label-for="code-postal-naissance">
                      <validation-provider #default="{ errors }" name="code postal de naissance" rules="required">
                        <b-form-input
                            id="code-postal-naissance"
                            v-model="assure.code_postal_naissance"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            type="text"
                            :raw="false"
                            class="text-uppercase"
                            placeholder="Code postal de naissance"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Commune de naissance *" label-for="commune-naissance">
                      <validation-provider #default="{ errors }" name="commune de naissance" rules="required">
                        <b-form-input id="commune-naissance" v-model="assure.commune_naissance" :state="errors.length > 0 ? false : null" class="text-capitalize" placeholder="Commune de naissance" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <validation-provider #default="{ errors }" name="pays de naissance" rules="required">
                      <b-form-group label="Pays de naissance *" label-for="pays-naissance" :class="errors.length > 0 ? 'is-invalid' : ''">
                        <v-select
                            v-model="assure.pays_naissance"
                            :close-on-select="true"
                            :reduce="pays => pays.pays"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pays"
                            class="flex-grow-1"
                            :state="errors.length > 0 ? false : null"
                            input-id="pays"
                            label="pays"
                            placeholder="Pays de naissance"
                        >
                          <template #option="{ pays }">
                            <span class="ml-50"> {{ pays }}</span>
                          </template>

                          <template #selected-option="{ pays }">
                            <span class="ml-50"> {{ pays }}</span>
                          </template>
                          <div slot="no-options">
                            Aucune pays disponible.
                          </div>
                        </v-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="align-items-center">
                  <!-- <b-col md="3">
                      <b-form-group label="Situation familiale *" label-for="situation_familiale">
                        <validation-provider v-slot="{ errors }" name="situation familiale" rules="required" vid="situation_familiale">
                          <b-form-select v-model="assure.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                  <b-col md="3">
                    <b-form-group label="Catégorie socioprofessionnelle" label-for="categorie_socioprofessionnelle">
                      <validation-provider v-slot="{ errors }" name="Catégorie socioprofessionnelle" rules="required" vid="categorie_socioprofessionnelle">
                        <b-form-select id="categorie_socioprofessionnelle" v-model="assure.statut" :options="ActiviteProfessionnelle" @change="initPrecision(assure.statut, 'emprunteur', assure.date_naissance)" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" v-if="assure.statut == 'SANS_EMPLOI' || assure.statut == 'PROFESSION_LIBERALE' || assure.statut == 'FONCTIONNAIRE_ASSIMILE'">
                    <b-form-group label="Merci de préciser*" label-for="precision-profession">
                      <validation-provider v-slot="{ errors }" name="Merci de préciser Assure" rules="required" vid="precision-profession">
                        <b-form-select v-model="objetEmprunteur.emprunteur.precision_profession" :options="TypePrecisionOptions[assure.statut]" id="precision-profession" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Profession" label-for="profession">
                      <b-form-input id="profession" v-model="objetEmprunteur.emprunteur.profession" class="text-capitalize" placeholder="Exemple : Boulanger" type="text" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Code postal *" label-for="code-postal">
                      <validation-provider #default="{ errors }" name="code postal" rules="required|length:5">
                        <cleave
                            id="code-postal"
                            v-model="assure.moyen_contact.code_postal"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :options="options.codePostal"
                            :raw="false"
                            class="form-control"
                            placeholder="Code postal"
                            type="tel"
                        />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Ville *" label-for="ville">
                      <validation-provider #default="{ errors }" name="ville" rules="required">
                        <b-form-select v-model="assure.moyen_contact.ville_id" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                          <b-form-select-option :value="null">
                            Choisissez
                          </b-form-select-option>
                          <b-form-select-option v-for="ville in villeOptions" :key="ville.id" :value="ville.id">
                            {{ ville.ville }}
                          </b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="fumeur" class="mb-1">
                        Fumeur ?
                        <span
                            v-b-popover.hover.top="'Ne fumant pas ou ayant arrêté de fumer (Cigarette ou Vapotage électronique) depuis plus de 24 mois.'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            title="Non fumeur"
                            class="ml-1 cursor-pointer"
                            variant="outline-primary"
                        >
                                                    <feather-icon icon="InfoIcon" size="20" />
                                                </span>
                      </label>
                      <validation-provider #default="{ errors }" name="fumeur" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.fumeur" name="radio-fumeur" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="contrat-temps-plein" class="mb-1">
                        Activité professionnelle à temps plein au vu de la durée habituelle prévue au contrat de travail ?
                      </label>
                      <validation-provider #default="{ errors }" name="contrat temps plein" rules="required">
                        <b-form-radio-group
                            v-model="objetEmprunteur.emprunteur.is_contrat_temps_plein"
                            name="radio-contrat-temps-plein"
                            :state="errors.length > 0 ? false : null"
                            @change="objetEmprunteur.emprunteur.en_conge = null"
                        >
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="!objetEmprunteur.emprunteur.is_contrat_temps_plein" md="3">
                    <b-form-group class="mb-0">
                      <label for="en-conge" class="mb-1">
                        En congé parental, maternité, paternité, sabbatique ?
                        <span
                            v-b-popover.hover.top="'Congé parental, maternité, paternité, sabbatique ou autre congé.'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            title="En congé"
                            class="ml-1 cursor-pointer"
                            variant="outline-primary"
                        >
                                                    <feather-icon icon="InfoIcon" size="20" />
                                                </span>
                      </label>
                      <validation-provider #default="{ errors }" name="en congé" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.en_conge" name="radio-en-congé" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <label for="deplacement-pro" class="mb-1">
                        Déplacement à titre professionnel ?
                      </label>
                      <span id="my_popover_1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer">
                                                <feather-icon icon="InfoIcon" size="20" />
                                            </span>
                      <validation-provider v-slot="{ errors }" name="deplacement a titre professionnel" rules="required">
                        <b-form-select id="deplacement-pro" v-model="objetEmprunteur.emprunteur.deplacement_pro" :options="DeplacementeProfessionnelOptions" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="activite-manuel-regulier" class="mb-1">
                        Activité avec manutention/travaux manuel régulier ?
                      </label>
                      <validation-provider #default="{ errors }" name="activite manuel regulier" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.activite_manuel_regulier" name="radio-activite-manuel-regulier" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="travail-hauteur" class="mb-1">
                        Travail en hauteur ?
                        <span
                            v-b-popover.hover.top="'Activité professionnelle à plus de 15 mètres de hauteur'"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            title="Travail en hauteur"
                            class="ml-1 cursor-pointer"
                            variant="outline-primary"
                        >
                                      <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                      </label>
                      <validation-provider #default="{ errors }" name="travail en hauteur" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.travail_hauteur" name="radio-travail-hauteur" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="9" class="mt-1">
                    <b-form-group class="mb-0">
                      <label for="en-cours" class="mb-1">
                        En incluant ce projet, l'assuré a-t-il des encours de prêts immobiliers assurés (hors prêt professionnels) supérieurs à 200 000 € ?
                      </label>
                      <validation-provider #default="{ errors }" name="en cours" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.emprunteur.en_cours" name="radio-en-cours" :state="errors.length > 0 ? false : null">
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="9" class="my-2">
                    <b-row>
                      <b-col md="9">
                        <b-row class="align-items-center">
                          <b-col md="12">
                            <b-form-group class="mb-0">
                              <label for="ppe" class="float-left pr-2 mb-1">
                                L'assuré, ou une ou des personnes de son entourage étroitement associées, exercent-ils ou ont-ils cessé d'exercer depuis moins d'un an des fonctions politiques,
                                juridictionnelles ou administratives ? (pour plus de détails, voir l'onglet FAQ)
                                <span
                                    v-b-popover.top.hover.html="`<p>Est PPE une personne qui est en charge ou a été en charge depuis moins d'un an de fonctions publiques (membres d'un gouvernement, politicien, haut militaire, dirigeant d'une entreprise publique, ambassadeur, haut magistrat …) ainsi que les membres de sa famille et ses partenaires commerciaux. <br><br> Pour plus d'informations : <br> <a href='https://bubbleinsupport.zendesk.com/hc/fr/articles/9209750085149-Qu-est-ce-qu-une-PPE-' target='_blank'>FAQ</a></p>`"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    title="Personnes Politiquement Exposées"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="ppe" rules="required">
                                <b-form-radio-group v-model="assure.isPPE" name="radio-ppe" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" class="pt-1">
                    <b-form-group class="mb-0">
                      <label class="float-left pr-4 mb-2">Ajouter un Co-assuré ?</label>
                      <validation-provider v-slot="{ errors }" name="is co-emprunteur" rules="required" vid="is_co_emprunteur">
                        <b-form-radio-group id="is_co_emprunteur_option" v-model="objetEmprunteur.emprunteur.has_co_emprunteur" :options="BooleanValue" @change="toggleIsSouscipteur" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Co-emprunteur -->
          <app-collapse-item v-if="objetEmprunteur.emprunteur.has_co_emprunteur" ref="tab-collapse-item-1" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Co-assuré</u></h4>">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="3">
                    <b-form-group>
                      <label class="mb-1">Type d'assuré *</label>
                      <validation-provider #default="{ errors }" name="type Co-assuré" rules="required">
                        <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.type_assure" name="radio-co-assure" :state="errors.length > 0 ? false : null" class="demo-inline-spacing">
                          <template v-for="option in TypeAssure">
                            <b-form-radio v-if="option.value != 'EMPRUNTEUR'" :key="option.value" :value="option.value" class="mt-0">
                              <small>{{ option.text }}</small>
                            </b-form-radio>
                          </template>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Quel est le lien du Co-assuré avec le souscripteur ?" label-for="lien du co-emprunteur">
                      <validation-provider #default="{ errors }" name="lien du co-emprunteur" rules="required">
                        <b-form-select v-model="objetEmprunteur.lien" :options="LienOptions" :state="errors.length > 0 ? false : null" @change="objetEmprunteur.lien == 'CONJOINT' ? initPrecision(conjoint.statut, 'co_emprunteur', conjoint.date_naissance, false) : ''" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="objetEmprunteur.lien" md="12" class="py-2">
                    <b-row v-if="objetEmprunteur.lien != 'CONJOINT'">
                      <div class="col-12 mb-1">
                        <label>Qui est le Co-assuré ?</label>
                      </div>
                    </b-row>
                    <div v-if="objetEmprunteur.lien == 'ENFANT'">
                      <b-row v-for="(enf,index) in enfantsArray" :key="`enfant-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top: 30px;">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="enf.state" :value="true" name="enfant" class="d-inline" @change="toggleConnexite(enf.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="enfant_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-select id="enfant_civilite" v-model="enf.civilite" :options="civilite" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom *" label-for="nom">
                                    <div v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ enf.nom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`nom enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-input id="nom" v-model="enf.nom" :state="errors.length > 0 ? false : null" autocomplete="nope" type="text" placeholder="Nom" class="text-capitalize" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom de naissance *" label-for="nom">
                                    <validation-provider #default="{ errors }" :name="`nom de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="enf.nom_naissance"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Nom de naissance"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Prénom *" label-for="nom">
                                    <div v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ enf.prenom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`prénom enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="enf.prenom"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Prénom"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!enf.id" md="1" class="text-center" style="margin-top: 30px;">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <b-col md="3">
                              <b-form-group label="Date de naissance *" label-for="date-naissance">
                                <div v-if="enf.statut_gda==='VERT' || enf.statut_gda==='ROUGE' || enf.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                  {{ enf.date_naissance }}
                                </div>
                                <validation-provider v-else #default="{ errors }" :name="`date de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                  <flat-pickr
                                      id="date-naissance"
                                      v-model="enf.date_naissance"
                                      class="form-control"
                                      :class="errors.length > 0 ? 'is-invalid' : ''"
                                      :config="configDate"
                                      placeholder="Date de naissance"
                                      data-max-date="today"
                                      @input="initPrecision(enf.statut, 'co_emprunteur',enf.date_naissance, enf.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Code postal de naissance *" label-for="code-postal-de-naissance">
                                <validation-provider #default="{ errors }" :name="`code postal de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                  <b-form-input
                                      id="code-postal-de-naissance"
                                      v-model="enf.code_postal_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Code postal de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Commune de naissance *" label-for="nom">
                                <validation-provider #default="{ errors }" :name="`commune de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                  <b-form-input
                                      id="nom"
                                      v-model="enf.commune_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Commune de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <validation-provider #default="{ errors }" :name="`pays de naissance enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''" label="Pays de naissance *" label-for="pays de naissance">
                                  <v-select
                                      v-model="enf.pays_naissance"
                                      :close-on-select="true"
                                      :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="pays"
                                      class="flex-grow-1"
                                      :state="errors.length > 0 ? false : null"
                                      input-id="pays"
                                      label="pays"
                                      placeholder="Pays de naissance"
                                  >
                                    <template #option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>

                                    <template #selected-option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>
                                    <div slot="no-options">
                                      Aucune pays disponible.
                                    </div>
                                  </v-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                            <b-form-group>
                            <label for="situation familiale">Situation familiale</label>
                                <validation-provider v-slot="{ errors }" :name="`situation familiale enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`" vid="enf_situation_familiale">
                                <b-form-select v-model="enf.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire</b-form-invalid-feedback>
                                </validation-provider>
                            </b-form-group>
                        </b-col> -->
                            <b-col md="3">
                              <b-form-group label="Catégorie socioprofessionnelle *" :label-for="`activite_professionnelle_enfant${index}`">
                                <validation-provider #default="{ errors }" :name="`activité professionnelle enfant{${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                  <b-form-select
                                      :id="`activite_professionnelle_enfant{${index}}`"
                                      v-model="enf.statut"
                                      :options="ActiviteProfessionnelle"
                                      :state="errors.length > 0 ? false : null"
                                      @change="initPrecision(enf.statut, 'co_emprunteur',enf.date_naissance, enf.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <template v-if="enf.state">
                              <b-col md="3" v-if="enf.statut == 'SANS_EMPLOI' || enf.statut == 'PROFESSION_LIBERALE' || enf.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser" :label-for="`precision-profession-enfant{${index}}`">
                                  <validation-provider
                                      v-slot="{ errors }"
                                      :name="`Merci de préciser enfant{${index}}`"
                                      :rules="`requiredIfChecked:${enf.state}`"
                                      :vid="`precision-profession-enfant{${index}}`"
                                  >
                                    <b-form-select
                                        v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                        :options="TypePrecisionOptions[enf.statut]"
                                        :id="`precision-profession-enfant{${index}}`"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-enfant{${index}}`">
                                  <b-form-input
                                      :id="`profession-co-emprunteur-enfant{${index}}`"
                                      v-model="objetEmprunteur.co_emprunteur.profession"
                                      class="text-capitalize"
                                      placeholder="Exemple : Boulanger"
                                      type="text"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-enfant{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal enfant {${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                    <cleave
                                        :id="`code-postal-enfant{${index}}`"
                                        v-model="enf.moyen_contact.code_postal"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :options="options.codePostal"
                                        :raw="false"
                                        class="form-control"
                                        placeholder="Code postal"
                                        type="tel"
                                        @input="enf.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-enfant{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville enfant {${index}}`" :rules="`requiredIfChecked:${enf.state}`">
                                    <b-form-select v-model="enf.moyen_contact.ville_id" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id" :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="enfantsArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary" @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'PARENT'">
                      <b-row v-for="(par,index) in parentsArray" :key="`parent-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top: 30px;">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="par.state" :value="true" name="parent" class="d-inline" @change="toggleConnexite(par.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="parent_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-select id="parent_civilite" v-model="par.civilite" :options="civilite" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom *" label-for="nom">
                                    <div v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ par.nom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`nom parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-input id="nom" v-model="par.nom" :state="errors.length > 0 ? false : null" autocomplete="nope" type="text" placeholder="Nom" class="text-capitalize" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom de naissance *" label-for="nom">
                                    <validation-provider #default="{ errors }" :name="`nom de naissance parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="par.nom_naissance"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Nom de naissance"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Prénom *" label-for="nom">
                                    <div v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ par.prenom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`prénom parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="par.prenom"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Prénom"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!par.id" md="1" class="text-center" style="margin-top: 30px;">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <b-col md="3">
                              <b-form-group label="Date de naissance *" label-for="date-naissance">
                                <div v-if="par.statut_gda==='VERT' || par.statut_gda==='ROUGE' || par.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                  {{ par.date_naissance }}
                                </div>

                                <validation-provider v-else #default="{ errors }" :name="`date de naissance parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                  <flat-pickr
                                      id="date-naissance"
                                      v-model="par.date_naissance"
                                      class="form-control"
                                      :class="errors.length > 0 ? 'is-invalid' : ''"
                                      :config="configDate"
                                      placeholder="Date de naissance"
                                      data-max-date="today"
                                      @input="initPrecision(par.statut, 'co_emprunteur', par.date_naissance, par.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Code postal de naissance *" label-for="code-postal-de-naissance">
                                <validation-provider #default="{ errors }" :name="`code postal de naissance parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                  <b-form-input
                                      id="code-postal-de-naissance"
                                      v-model="par.code_postal_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Code postal de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Commune de naissance *" label-for="nom">
                                <validation-provider #default="{ errors }" :name="`commune de naissance parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                  <b-form-input
                                      id="nom"
                                      v-model="par.commune_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Commune de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <validation-provider #default="{ errors }" :name="`pays de naissance parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''" label="Pays de naissance *" label-for="pays de naissance">
                                  <v-select
                                      v-model="par.pays_naissance"
                                      :close-on-select="true"
                                      :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="pays"
                                      class="flex-grow-1"
                                      :state="errors.length > 0 ? false : null"
                                      input-id="pays"
                                      label="pays"
                                      placeholder="Pays de naissance"
                                  >
                                    <template #option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>

                                    <template #selected-option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>
                                    <div slot="no-options">
                                      Aucune pays disponible.
                                    </div>
                                  </v-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                        <b-form-group>
                          <label for="situation familiale">Situation familiale</label>
                          <validation-provider #default="{ errors }" :name="`situation familiale parent{${index}}`"
                            :rules="`requiredIfChecked:${par.state}`" vid="par_situation_familiale">
                            <b-form-select v-model="par.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale"
                              :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                            <b-col md="3">
                              <b-form-group label="Catégorie socioprofessionnelle *" :label-for="`activite_professionnelle_parent${index}`">
                                <validation-provider #default="{ errors }" :name="`activité professionnelle parent{${index}}`" :rules="`requiredIfChecked:${par.state}`">
                                  <b-form-select
                                      :id="`activite_professionnelle_parent{${index}}`"
                                      v-model="par.statut"
                                      :options="ActiviteProfessionnelle"
                                      :state="errors.length > 0 ? false : null"
                                      @change="initPrecision(par.statut, 'co_emprunteur',par.date_naissance,par.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <template v-if="par.state">
                              <b-col md="3" v-if="par.statut == 'SANS_EMPLOI' || par.statut == 'PROFESSION_LIBERALE' || par.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser" :label-for="`precision-profession-parent{${index}}`">
                                  <validation-provider
                                      v-slot="{ errors }"
                                      :name="`Merci de préciser parent{${index}}`"
                                      :rules="`requiredIfChecked:${par.state}`"
                                      :vid="`precision-profession-parent{${index}}`"
                                  >
                                    <b-form-select
                                        v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                        :options="TypePrecisionOptions[par.statut]"
                                        :id="`precision-profession-parent{${index}}`"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-parent{${index}}`">
                                  <b-form-input
                                      :id="`profession-co-emprunteur-parent{${index}}`"
                                      v-model="objetEmprunteur.co_emprunteur.profession"
                                      class="text-capitalize"
                                      placeholder="Exemple : Boulanger"
                                      type="text"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-parent{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal parent {${index}}`" rules="required|length:5">
                                    <cleave
                                        :id="`code-postal-parent{${index}}`"
                                        v-model="par.moyen_contact.code_postal"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :options="options.codePostal"
                                        :raw="false"
                                        class="form-control"
                                        placeholder="Code postal"
                                        type="tel"
                                        @input="par.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-parent{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville parent {${index}}`" rules="required">
                                    <b-form-select v-model="par.moyen_contact.ville_id" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id" :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="parentsArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary" @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'CONJOINT'">
                      <b-row class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top: 30px;">
                              <b-form-group class="d-inline">
                                <b-form-radio :checked="true" :value="true" class="d-inline" name="conjoint" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="conjoint_civilite">
                                    <validation-provider #default="{ errors }" name="civilité conjoint" rules="required">
                                      <b-form-select id="conjoint_civilite" v-model="conjoint.civilite" :options="civilite" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom *" label-for="nom">
                                    <div v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ conjoint.nom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" name="nom conjoint" rules="required">
                                      <b-form-input id="nom" v-model="conjoint.nom" :state="errors.length > 0 ? false : null" autocomplete="nope" type="text" placeholder="Nom" class="text-capitalize" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom de naissance *" label-for="nom-de-naissance">
                                    <validation-provider #default="{ errors }" name="nom de naissance conjoint" rules="required">
                                      <b-form-input
                                          id="nom-de-naissance"
                                          v-model="conjoint.nom_naissance"
                                          autocomplete="nope"
                                          :state="errors.length > 0 ? false : null"
                                          type="text"
                                          placeholder="Nom de naissance"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Prénom *" label-for="prenom">
                                    <div v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ conjoint.prenom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" name="prénom conjoint" rules="required">
                                      <b-form-input
                                          id="prenom"
                                          v-model="conjoint.prenom"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Prénom"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                        Le champ est obligatoire
                                      </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!conjoint.id" md="1" class="text-center" style="margin-top: 30px;">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <b-col md="3">
                              <b-form-group label="Date de naissance *" label-for="date-naissance">
                                <div v-if="conjoint.statut_gda==='VERT' || conjoint.statut_gda==='ROUGE' || conjoint.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                  {{ conjoint.date_naissance }}
                                </div>
                                <validation-provider v-else #default="{ errors }" name="date naissance conjoint" rules="required">
                                  <flat-pickr
                                      id="date-naissance"
                                      v-model="conjoint.date_naissance"
                                      class="form-control"
                                      :class="errors.length > 0 ? 'is-invalid' : ''"
                                      :config="configDate"
                                      placeholder="Date de naissance"
                                      data-max-date="today"
                                      @input="initPrecision(conjoint.statut, 'co_emprunteur', conjoint.date_naissance)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Code postal de naissance *" label-for="code postal naissance">
                                <validation-provider #default="{ errors }" name="code postal naissance conjoint" rules="required">
                                  <b-form-input
                                      id="code postal naissance"
                                      v-model="conjoint.code_postal_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Code postal de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Commune de naissance *" label-for="nom">
                                <validation-provider #default="{ errors }" name="commune de naissance conjoint" rules="required">
                                  <b-form-input
                                      id="nom"
                                      v-model="conjoint.commune_naissance"
                                      autocomplete="nope"
                                      :state="errors.length > 0 ? false : null"
                                      type="text"
                                      placeholder="Commune de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <validation-provider #default="{ errors }" name="pays de naissance conjoint" rules="required">
                                <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''" label="Pays de naissance *" label-for="pays de naissance">
                                  <v-select
                                      v-model="conjoint.pays_naissance"
                                      :close-on-select="true"
                                      :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="pays"
                                      class="flex-grow-1"
                                      :state="errors.length > 0 ? false : null"
                                      input-id="pays"
                                      label="pays"
                                      placeholder="Pays de naissance"
                                  >
                                    <template #option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>

                                    <template #selected-option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>
                                    <div slot="no-options">
                                      Aucune pays disponible.
                                    </div>
                                  </v-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                          <b-form-group>
                            <label for="situation familiale">Situation familiale </label>
                            <validation-provider v-slot="{ errors }" name="situation familiale conjoint" rules="required" vid="conjoint_situation_familiale">
                              <b-form-select v-model="conjoint.situation_familiale"  :options="SituationFamilialeOptions" id="situation_familiale" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                            <b-col md="3">
                              <b-form-group label="Catégorie socioprofessionnelle *" label-for="categorie_socioprofessionnelle_conjoint">
                                <validation-provider #default="{ errors }" name="catégorie socioprofessionnelle conjoint" rules="required">
                                  <b-form-select
                                      id="categorie_socioprofessionnelle_conjoint"
                                      v-model="conjoint.statut"
                                      :options="ActiviteProfessionnelle"
                                      :state="errors.length > 0 ? false : null"
                                      @change="initPrecision(conjoint.statut, 'co_emprunteur', conjoint.date_naissance)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" v-if="conjoint.statut == 'SANS_EMPLOI' || conjoint.statut == 'PROFESSION_LIBERALE' || conjoint.statut == 'FONCTIONNAIRE_ASSIMILE'">
                              <b-form-group label="Merci de préciser" :label-for="`precision-profession-conjoint`">
                                <validation-provider v-slot="{ errors }" name="Merci de préciser conjoint" rules="required" vid="precision-profession-conjoint">
                                  <b-form-select
                                      v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                      :options="TypePrecisionOptions[conjoint.statut]"
                                      id="precision-profession-conjoint"
                                      :state="errors.length > 0 ? false : null"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Profession" :label-for="`profession_co_emprunteur_conjoint`">
                                <b-form-input
                                    :id="`profession_co_emprunteur_conjoint`"
                                    v-model="objetEmprunteur.co_emprunteur.profession"
                                    class="text-capitalize"
                                    placeholder="Exemple : Boulanger"
                                    type="text"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Code postal *" label-for="code-postal-conjoint">
                                <validation-provider #default="{ errors }" name="code postal conjoint" rules="required|length:5">
                                  <cleave
                                      id="code-postal-conjoint"
                                      v-model="conjoint.moyen_contact.code_postal"
                                      :class="errors.length > 0 ? 'is-invalid' : ''"
                                      :options="options.codePostal"
                                      :raw="false"
                                      class="form-control"
                                      placeholder="Code postal"
                                      type="tel"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Ville *" label-for="ville-conjoint">
                                <validation-provider #default="{ errors }" name="ville conjoint" rules="required">
                                  <b-form-select v-model="conjoint.moyen_contact.ville_id" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                                    <b-form-select-option :value="null">
                                      Choisissez
                                    </b-form-select-option>
                                    <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id" :value="ville.id">
                                      {{ ville.ville }}
                                    </b-form-select-option>
                                  </b-form-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                    <div v-if="objetEmprunteur.lien == 'AMI'">
                      <b-row v-for="(ami,index) in amisArray" :key="`ami-${index}`" class="mb-1">
                        <b-col md="12">
                          <b-row>
                            <b-col md="1" class="d-inline text-center" style="margin-top: 30px;">
                              <b-form-group class="d-inline">
                                <b-form-radio v-model="ami.state" :value="true" name="ami" class="d-inline" @change="toggleConnexite(ami.state, index, objetEmprunteur.lien)" />
                              </b-form-group>
                            </b-col>
                            <b-col md="10">
                              <b-row>
                                <b-col md="3">
                                  <b-form-group label="Civilité *" label-for="ami_civilite">
                                    <validation-provider #default="{ errors }" :name="`civilité ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-select id="ami_civilite" v-model="ami.civilite" :options="civilite" :state="errors.length > 0 ? false : null" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom *" label-for="nom">
                                    <div v-if="ami.statut_gda==='VERT' || ami.statut_gda==='ROUGE' || ami.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ ami.nom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`nom ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-input id="nom" v-model="ami.nom" :state="errors.length > 0 ? false : null" autocomplete="nope" type="text" placeholder="Nom" class="text-capitalize" />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Nom de naissance *" label-for="nom">
                                    <validation-provider #default="{ errors }" :name="`nom de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="ami.nom_naissance"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Nom de naissance"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="3">
                                  <b-form-group label="Prénom *" label-for="nom">
                                    <div v-if="ami.statut_gda==='VERT' || ami.statut_gda==='ROUGE' || ami.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                      {{ ami.prenom }}
                                    </div>
                                    <validation-provider v-else #default="{ errors }" :name="`prénom ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                      <b-form-input
                                          id="nom"
                                          v-model="ami.prenom"
                                          :state="errors.length > 0 ? false : null"
                                          autocomplete="nope"
                                          type="text"
                                          placeholder="Prénom"
                                          class="text-capitalize"
                                      />
                                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col v-if="!ami.id" md="1" class="text-center" style="margin-top: 30px;">
                              <div class="d-inline" @click="removeConnexite(index, objetEmprunteur.lien)">
                                <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <b-col md="3">
                              <b-form-group label="Date de naissance *" label-for="date-naissance">
                                <div v-if="ami.statut_gda==='VERT' || ami.statut_gda==='ROUGE' || ami.statut_gda==='ORANGE' " class="form-control" readonly disabled>
                                  {{ ami.date_naissance }}
                                </div>

                                <validation-provider v-else #default="{ errors }" :name="`date de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                  <flat-pickr
                                      id="date-naissance"
                                      v-model="ami.date_naissance"
                                      class="form-control"
                                      :class="errors.length > 0 ? 'is-invalid' : ''"
                                      :config="configDate"
                                      placeholder="Date de naissance"
                                      data-max-date="today"
                                      @input="initPrecision(ami.statut, 'co_emprunteur', ami.date_naissance, ami.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Code postal de naissance *" label-for="code-postal-de-naissance">
                                <validation-provider #default="{ errors }" :name="`code postal de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                  <b-form-input
                                      id="code-postal-de-naissance"
                                      v-model="ami.code_postal_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Code postal de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group label="Commune de naissance *" label-for="nom">
                                <validation-provider #default="{ errors }" :name="`commune de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                  <b-form-input
                                      id="nom"
                                      v-model="ami.commune_naissance"
                                      :state="errors.length > 0 ? false : null"
                                      autocomplete="nope"
                                      type="text"
                                      placeholder="Commune de naissance"
                                      class="text-capitalize"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <validation-provider #default="{ errors }" :name="`pays de naissance ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                <b-form-group :class="errors.length > 0 ? 'is-invalid' : ''" label="Pays de naissance *" label-for="pays de naissance">
                                  <v-select
                                      v-model="ami.pays_naissance"
                                      :close-on-select="true"
                                      :reduce="pays => pays.pays"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="pays"
                                      class="flex-grow-1"
                                      :state="errors.length > 0 ? false : null"
                                      input-id="pays"
                                      label="pays"
                                      placeholder="Pays de naissance"
                                  >
                                    <template #option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>

                                    <template #selected-option="{ pays }">
                                      <span class="ml-50"> {{ pays }}</span>
                                    </template>
                                    <div slot="no-options">
                                      Aucune pays disponible.
                                    </div>
                                  </v-select>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ est obligatoire </b-form-invalid-feedback>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="10" offset="1">
                          <b-row>
                            <!-- <b-col md="3">
                          <b-form-group>
                            <label for="situation familiale">Situation familiale</label>
                            <validation-provider #default="{ errors }" :name="`situation familiale ami{${index}}`"
                              :rules="`requiredIfChecked:${ami.state}`" vid="par_situation_familiale">
                              <b-form-select v-model="ami.situation_familiale" :options="SituationFamilialeOptions" id="situation_familiale"
                                :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                            <b-col md="3">
                              <b-form-group label="Catégorie socioprofessionnelle" :label-for="`activite_professionnelle_ami_{${index}}`">
                                <validation-provider #default="{ errors }" :name="`activité professionnelle ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`">
                                  <b-form-select
                                      :id="`activite_professionnelle_ami_{${index}}`"
                                      v-model="ami.statut"
                                      :options="ActiviteProfessionnelle"
                                      :state="errors.length > 0 ? false : null"
                                      @change="initPrecision(ami.statut, 'co_emprunteur', ami.date_naissance, ami.state)"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <template v-if="ami.state">
                              <b-col md="3" v-if="ami.statut == 'SANS_EMPLOI' || ami.statut == 'PROFESSION_LIBERALE' || ami.statut == 'FONCTIONNAIRE_ASSIMILE'">
                                <b-form-group label="Merci de préciser" :label-for="`precision-profession-ami{${index}}`">
                                  <validation-provider v-slot="{ errors }" :name="`Merci de préciser ami{${index}}`" :rules="`requiredIfChecked:${ami.state}`" :vid="`precision-profession-ami{${index}}`">
                                    <b-form-select
                                        v-model="objetEmprunteur.co_emprunteur.precision_profession"
                                        :options="TypePrecisionOptions[ami.statut]"
                                        :id="`precision-profession-ami{${index}}`"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null"> Le champ est obligatoire </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Profession" :label-for="`profession-co-emprunteur-ami{${index}}`">
                                  <b-form-input
                                      :id="`profession-co-emprunteur-ami{${index}}`"
                                      v-model="objetEmprunteur.co_emprunteur.profession"
                                      class="text-capitalize"
                                      placeholder="Exemple : Boulanger"
                                      type="text"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Code postal *" :label-for="`code-postal-ami{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`code postal ami {${index}}`" rules="required|length:5">
                                    <cleave
                                        :id="`code-postal-ami{${index}}`"
                                        v-model="ami.moyen_contact.code_postal"
                                        :class="errors.length > 0 ? 'is-invalid' : ''"
                                        :options="options.codePostal"
                                        :raw="false"
                                        class="form-control"
                                        placeholder="Code postal"
                                        type="tel"
                                        @input="ami.state ? getVilleByCodePostal($event, index, objetEmprunteur.lien) : ''"
                                    />
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Ville *" :label-for="`ville-ami{${index}}`">
                                  <validation-provider #default="{ errors }" :name="`ville ami {${index}}`" rules="required">
                                    <b-form-select v-model="ami.moyen_contact.ville_id" :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : ''">
                                      <b-form-select-option :value="null">
                                        Choisissez
                                      </b-form-select-option>
                                      <b-form-select-option v-for="ville in villeOptionsCoEmprunteur" :key="ville.id" :value="ville.id">
                                        {{ ville.ville }}
                                      </b-form-select-option>
                                    </b-form-select>
                                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                      Le champ est obligatoire
                                    </b-form-invalid-feedback>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </template>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-col md="11" sm="11" :offset="amisArray.length > 0 ? 1 : 0">
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="outline-primary" @click="addConnexite(objetEmprunteur.lien)">
                            <feather-icon icon="PlusIcon" />
                            <span class="align-middle">Ajouter</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row v-if="objetEmprunteur.lien != 'CONJOINT' && hasCoEmprunteurSelected">
                      <b-col class="col-10 my-1" offset="1">
                        <h5>Informations complémentaires du co-assuré sélectionné</h5>
                      </b-col>
                    </b-row>
                    <div v-if="hasCoEmprunteurSelected">
                      <b-row class="mb-1">
                        <b-col md="10" offset="1">
                          <b-row class="mb-1 align-items-center">
                            <b-col md="3">
                              <b-form-group class="mb-0">
                                <label for="fumeur" class="mb-1">
                                  Fumeur ?
                                  <span
                                      v-b-popover.hover.top="'Ne fumant pas ou ayant arrêté de fumer (Cigarette ou Vapotage électronique) depuis plus de 24 mois.'"
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      title="Non fumeur"
                                      class="ml-1 cursor-pointer"
                                      variant="outline-primary"
                                  >
                                                                        <feather-icon icon="InfoIcon" size="20" />
                                                                    </span>
                                </label>
                                <validation-provider #default="{ errors }" name="fumeur co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.fumeur" name="radio-fumeur-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group class="mb-0">
                                <label for="contrat-temps-plein-co-emprunteur" class="mb-1">
                                  Activité professionnelle à temps plein au vu de la durée habituelle prévue au contrat de travail ?
                                </label>
                                <validation-provider #default="{ errors }" name="contrat temps plein co-emprunteur" rules="required">
                                  <b-form-radio-group
                                      v-model="objetEmprunteur.co_emprunteur.is_contrat_temps_plein"
                                      name="radio-contrat-temps-plein-co-emprunteur"
                                      :state="errors.length > 0 ? false : null"
                                      @change="objetEmprunteur.co_emprunteur.en_conge = null"
                                  >
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="!objetEmprunteur.co_emprunteur.is_contrat_temps_plein" md="3">
                              <b-form-group class="mb-0">
                                <label for="en-conge" class="mb-1">
                                  En congé parental, maternité, paternité, sabbatique ?
                                  <span
                                      v-b-popover.hover.top="'Congé parental, maternité, paternité, sabbatique ou autre congé.'"
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      title="En congé"
                                      class="ml-1 cursor-pointer"
                                      variant="outline-primary"
                                  >
                                                                        <feather-icon icon="InfoIcon" size="20" />
                                                                    </span>
                                </label>
                                <validation-provider #default="{ errors }" name="en-conge-co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.en_conge" name="radio-en-conge-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3">
                              <b-form-group>
                                <label for="deplacement-pro-co-emprunteur" class="mb-1">
                                  Déplacement à titre professionnel ?
                                </label>
                                <span id="my_popover_2" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer">
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                                <b-popover container="body" target="my_popover_2" placement="buttom" triggers="hover">
                                  <template #title>
                                    Kilomètres professionnel par an
                                  </template>
                                  Réalisé avec un véhicule terrestre à moteur et hors domicile/travail
                                </b-popover>
                                <validation-provider v-slot="{ errors }" name="deplacement a titre professionnel co-emprunteur" rules="required">
                                  <b-form-select
                                      id="deplacement-pro-co-emprunteur"
                                      v-model="objetEmprunteur.co_emprunteur.deplacement_pro"
                                      :options="DeplacementeProfessionnelOptions"
                                      :state="errors.length > 0 ? false : null"
                                  />
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le champ est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="activite-manuel-regulier-co-emprunteur" class="mb-1">
                                  Activité avec manutention/travaux manuel régulier ?
                                </label>
                                <validation-provider #default="{ errors }" name="activite manuel regulier co-emprunteur" rules="required">
                                  <b-form-radio-group
                                      v-model="objetEmprunteur.co_emprunteur.activite_manuel_regulier"
                                      name="radio-activite-manuel-regulier-co-emprunteur"
                                      :state="errors.length > 0 ? false : null"
                                  >
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="3" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="travail-hauteur-co-emprunteur" class="mb-1">
                                  Travail en hauteur ?
                                  <span
                                      v-b-popover.hover.top="'Activité professionnelle à plus de 15 mètres de hauteur'"
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      title="Travail en hauteur"
                                      class="ml-1 cursor-pointer"
                                      variant="outline-primary"
                                  >
                                      <feather-icon icon="InfoIcon" size="20" />
                                  </span>
                                </label>
                                <validation-provider #default="{ errors }" name="travail en hauteur co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.travail_hauteur" name="radio-travail-hauteur-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="5" class="my-2">
                              <b-form-group class="mb-0">
                                <label for="en-cours-co-emprunteur" class="mb-1">
                                  En incluant ce projet, le co-assuré a-t-il des encours de prêts immobiliers assurés (hors prêt professionnels) supérieurs à 200 000 € ?
                                </label>
                                <validation-provider #default="{ errors }" name="en cours co-emprunteur" rules="required">
                                  <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.en_cours" name="radio-en-cours-co-emprunteur" :state="errors.length > 0 ? false : null">
                                    <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                      {{ option.text }}
                                    </b-form-radio>
                                  </b-form-radio-group>
                                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    Le choix est obligatoire
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="12" class="my-2">
                              <b-row>
                                <b-col md="9">
                                  <b-row class="align-items-center">
                                    <b-col md="12">
                                      <b-form-group class="mb-0">
                                        <label for="ppe-co-emprunteur" class="float-left pr-2 mb-1">
                                          Le Co-assuré, ou une ou des personnes de son entourage étroitement associées, exercent-ils ou ont-ils cessé d'exercer depuis moins d'un an une fonction
                                          politique, juridictionnelle ou administrative exerce ou a-t-il cessé d'exercer depuis moins d'un an des fonctions politiques, juridictionnelles ou
                                          administratives ? (pour plus de détails, voir l'onglet FAQ)
                                          <span
                                              v-b-popover.top.hover.html="`<p>Est PPE une personne qui est en charge ou a été en charge depuis moins d'un an de fonctions publiques (membres d'un gouvernement, politicien, haut militaire, dirigeant d'une entreprise publique, ambassadeur, haut magistrat …) ainsi que les membres de sa famille et ses partenaires commerciaux. <br><br> Pour plus d'informations : <br> <a href='https://bubbleinsupport.zendesk.com/hc/fr/articles/9209750085149-Qu-est-ce-qu-une-PPE-' target='_blank'>FAQ</a></p>`"
                                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                              title="Personnes Politiquement Exposées"
                                              class="ml-1 cursor-pointer"
                                              variant="outline-primary"
                                          >
                                                                                        <feather-icon icon="InfoIcon" size="20" />
                                                                                    </span>
                                        </label>
                                        <validation-provider #default="{ errors }" name="ppe co-emprunteur" rules="required">
                                          <b-form-radio-group v-model="objetEmprunteur.co_emprunteur.isPPE" name="radio-ppe-co-emprunteur" :state="errors.length > 0 ? false : null">
                                            <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                              {{ option.text }}
                                            </b-form-radio>
                                          </b-form-radio-group>
                                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                            Le choix est obligatoire
                                          </b-form-invalid-feedback>
                                        </validation-provider>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!-- Prêt principal -->
          <app-collapse-item ref="tab-collapse-item-2" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Prêt principal</u></h4>">
            <b-row class="align-items-center mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="type-formule" class="font-weight-normal">
                    Type de formule
                    <span
                        v-b-popover.top.hover.html="'<ul><li>' +
                         '<strong> Capital Initial (CI) :</strong> Mensualité fixe.</li>' +
                           '<li><strong> Capital Restant Dû (CRD) :</strong> Mensualité variable.</li>' +
                            '</ul>'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        title="Type de formule emprunteur"
                        class="ml-1 cursor-pointer"
                        variant="outline-primary"
                    >
                                            <feather-icon icon="InfoIcon" size="20" />
                                        </span>
                  </label>
                  <validation-provider #default="{ errors }" name="type formule" rules="required">
                    <b-form-select id="type-formule" v-model="objetEmprunteur.prets.type_formule" :options="TypeFormuleOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label class="pr-2 mb-1 font-weight-normal">Type de demande ?</label>
                  <validation-provider v-slot="{ errors }" name="type demande" rules="required" vid="type-demande">
                    <b-form-radio-group id="type-demande-option" v-model="objetEmprunteur.prets.type_demande" @change="initDataByTypeDemande">
                      <b-form-radio v-for="option in TypeDemandeOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                        <span v-b-popover.hover.top="option.infoBulle.description" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :title="option.infoBulle.title" class="ml-1 cursor-pointer" variant="outline-primary">
                                                    <feather-icon icon="InfoIcon" size="20" />
                                                </span>
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="date-effet-pret-principal" class="font-weight-normal">
                    Date d'effet
                    <span
                        v-b-popover.top.hover.html="'<ul><li><p>Pour une demande de déliaison, il s’agit de la date d’effet du prêt.</p></li><li><p>Pour une demande de substitution, il s’agit de la date d’effet du contrat (date de substitution souhaitée).</p></li></ul>'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        title="Date d'effet"
                        class="ml-1 cursor-pointer"
                        variant="outline-primary"
                    >
                                            <feather-icon icon="InfoIcon" size="20" />
                                        </span>
                  </label>
                  <validation-provider #default="{ errors }" ref="dateEffet" name="date d'effet du prêt principal" rules="required">
                    <flat-pickr
                        id="date-effet-pret-principal"
                        v-model="objetEmprunteur.prets.pret_principal.date_effet"
                        class="form-control"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                        :config="configDateEffet"
                        placeholder="Date d'effet"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'" md="3">
                <b-form-group label="Avancé du projet" label-for="avance-projet" class="mb-0">
                  <b-form-select id="avance-projet" v-model="objetEmprunteur.prets.pret_principal.avance_projet" :options="AvanceProjetOptions" />
                </b-form-group>
              </b-col>
              <b-col v-if="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" md="3">
                <b-form-group class="mb-0">
                  <label for="date-signature-pret-principal" class="font-weight-normal">
                    Date de signature du prêt
                  </label>
                  <validation-provider #default="{ errors }" ref="dateSignature" name="date de signature du prêt principal" rules="required">
                    <flat-pickr
                        id="date-signature-pret-principal"
                        v-model="objetEmprunteur.prets.pret_principal.date_signature"
                        class="form-control"
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                        :config="configDate"
                        placeholder="Date de signature"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert v-show="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" variant="danger" show class="mt-2 mb-0">
                  <div class="alert-body">
                    <p><strong>Attention :</strong> Prévoir une date d’effet à J+2 mois (acceptation du dossier). J+3 mois si le dossier présente des particularités</p>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="Type du prêt" label-for="type-pret">
                  <validation-provider #default="{ errors }" name="type-pret" rules="required">
                    <b-form-select id="type" v-model="objetEmprunteur.prets.pret_principal.type" :options="TypesPrincipal" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Nature" label-for="nature">
                  <validation-provider #default="{ errors }" name="nature" rules="required">
                    <b-form-select id="nature" v-model="objetEmprunteur.prets.pret_principal.nature" :options="NaturesPrincipal" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Objet" label-for="objet">
                  <validation-provider #default="{ errors }" name="objet" rules="required">
                    <b-form-select id="objet" v-model="objetEmprunteur.prets.pret_principal.objet" :options="ObjetPrincipalOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label class="mb-1 font-weight-normal">
                    S'agit-il d'un prêt palier ?
                    <span v-b-popover.hover.top="'Échéance variant dans le temps'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Prêt à palier" class="ml-1 cursor-pointer" variant="outline-primary">
                                            <feather-icon icon="InfoIcon" size="20" />
                                        </span>
                  </label>
                  <validation-provider v-slot="{ errors }" name="is pret-palier" rules="required" vid="has_pret_palier">
                    <b-form-radio-group id="is_pret_palier_option" v-model="objetEmprunteur.prets.pret_principal.has_pret_palier" :options="BooleanValue" @change="resetDataPretPalier($event, 'PRINCIPAL')" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="mb-0" label="Taux d'intérêt" label-for="taux_interet">
                  <validation-provider #default="{ errors }" name="taux d'intérêt" rules="required|between:0.00,9.99">
                    <b-form-input v-model="objetEmprunteur.prets.pret_principal.taux_interet" v-mask="['#', '.##']" :state="errors.length > 0 ? false : null" placeholder="Taux d'intérêt" type="text" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le champ est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group class="mb-0">
                  <label for="type-taux" class="mb-1 font-weight-normal">Type de taux</label>
                  <validation-provider #default="{ errors }" name="type-taux" rules="required">
                    <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.type_taux" name="radio-type-taux" :state="errors.length > 0 ? false : null">
                      <b-form-radio v-for="option in optionsTaux" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <template v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'">
                <b-col md="3">
                  <b-form-group label="Capital initial" label-for="capital-initial">
                    <validation-provider #default="{ errors }" name="capital initial" rules="required|between:1,10000000">
                      <b-form-input id="capital" v-model="objetEmprunteur.prets.pret_principal.capital" placeholder="Capital initial" type="number" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 10 000 000 euros
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Durée (en mois)" label-for="duree">
                    <validation-provider #default="{ errors }" name="durée" rules="required|integer|between:1,600">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_pret" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 600 mois
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <template v-else>
                <b-col md="3">
                  <b-form-group label="Capital restant dû à la date de substitution" label-for="capital-restant">
                    <validation-provider #default="{ errors }" name="capital restant" rules="required|between:1,10000000">
                      <b-form-input id="capital-restant" v-model="objetEmprunteur.prets.pret_principal.capital" placeholder="Capital restant" type="number" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 10 000 000 euros
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="mb-0" label="Durée restante à compter de la date de substitution (en mois)" label-for="duree-restante">
                    <validation-provider #default="{ errors }" name="durée restante" rules="required|integer|between:1,600">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_pret" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée restante" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        La valeur doit être comprise entre 1 et 600 mois
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <template v-if="objetEmprunteur.prets.pret_principal.has_pret_palier && true">
              <hr class="mb-2" />
              <b-row v-for="(pal,index) in objetEmprunteur.prets.pret_principal.paliersArray" :key="`pret-principal-${index}`" class="mb-1">
                <b-col md="12">
                  <b-row>
                    <b-col md="6">
                      <b-row>
                        <b-col md="6">
                          <b-form-group :label="`Mensualité du palier ${index + 1}`" :label-for="`mensualite-palier-${index + 1}`">
                            <validation-provider #default="{ errors }" :name="`mensualite palier ${index + 1}`" rules="required">
                              <b-form-input :id="`mensualite-palier-${index + 1}`" v-model="pal.mensualite_palier" placeholder="Mensualité en €" type="number" :state="errors.length > 0 ? false : null" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group class="mb-0" :label="`Durée du palier ${index + 1}`" :label-for="`duree-palier-${index + 1}`">
                            <validation-provider #default="{ errors }" name="durée du palier" rules="required|integer|between:1,9999">
                              <b-form-input v-model="pal.duree_palier" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le champ est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col v-if="!pal.id" md="1" class="text-center" style="margin-top: 30px;">
                      <div class="d-inline" @click="removePalier(index, 'PRINCIPAL')">
                        <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="12" sm="12">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="outline-primary" @click="addPalier('PRINCIPAL')" v-if="!ShowPrincipalAddPalier">
                    <feather-icon icon="PlusIcon" />
                    <span class="align-middle">AJOUTER UN PALIER</span>
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <b-row class="align-items-center">
              <b-col md="4">
                <b-form-group class="mb-0 mt-3">
                  <label class="float-left pr-1 mb-2">Le prêt comporte-t-il un différé ?</label>
                  <validation-provider v-slot="{ errors }" name="pret has differe" rules="required" vid="pret-has-differe">
                    <b-form-radio-group id="pret-has-differe-option" v-model="objetEmprunteur.prets.pret_principal.has_differe" @change="resetDataDiffere($event, 'PRINCIPAL')">
                      <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <template v-if="objetEmprunteur.prets.pret_principal.has_differe">
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label for="type-differe" class="font-weight-normal">Différé partiel ou total ?</label>
                    <validation-provider #default="{ errors }" name="type differe" rules="required">
                      <b-form-select id="type-differe" v-model="objetEmprunteur.prets.pret_principal.type_differe" :options="ImpactDiffereOptions" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Durée du différé (en mois)" label-for="duree-differe">
                    <validation-provider #default="{ errors }" name="durée différé" rules="required|integer|between:1,9999">
                      <b-form-input v-model="objetEmprunteur.prets.pret_principal.duree_differe" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <b-row class="mt-1 mb-2">
              <b-col md="3">
                <b-form-group class="mb-0">
                  <label for="add-pret" class="float-left pr-4">Ajouter un prêt ?</label>
                  <validation-provider #default="{ errors }" name="has prêt secondaire" rules="required">
                    <b-form-radio-group v-model="objetEmprunteur.has_pret_secondaire" name="radio-has-pret-secondaire" :state="errors.length > 0 ? false : null" @change="resetDataPretSec">
                      <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </b-form-radio>
                    </b-form-radio-group>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
          <app-collapse-item ref="tab-collapse-item-3" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Prêt secondaire</u></h4>" v-if="objetEmprunteur.has_pret_secondaire">
            <template>
              <b-row class="my-2 align-items-center">
                <b-col md="5">
                  <b-form-group class="mb-0 mt-2">
                    <label for="has-diff-date-effet" class="float-left pr-4">La date d'effet est-elle différente de celle du prêt principal ?</label>
                    <validation-provider #default="{ errors }" name="has diff date d'effet" rules="required">
                      <b-form-radio-group
                          v-model="objetEmprunteur.prets.pret_secondaire.has_diff_date"
                          name="radio-taux"
                          :state="errors.length > 0 ? false : null"
                          @change="$event ? objetEmprunteur.prets.pret_secondaire.date_effet = null : ''"
                      >
                        <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.pret_secondaire.has_diff_date" md="3">
                  <b-form-group class="mb-0">
                    <label for="date-effet-pret-secondaire" class="font-weight-normal">
                      Date d'effet
                    </label>
                    <validation-provider #default="{ errors }" ref="dateEffet" name="date d'effet du prêt" rules="required">
                      <flat-pickr
                          id="date-effet-pret-secondaire"
                          v-model="objetEmprunteur.prets.pret_secondaire.date_effet"
                          class="form-control"
                          :class="errors.length > 0 ? 'is-invalid' : ''"
                          :config="configDateEffet"
                          placeholder="Date d'effet"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'" md="3">
                  <b-form-group label="Avancé du projet" label-for="avance-projet-secondaire" class="mb-0">
                    <b-form-select id="avance-projet-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.avance_projet" :options="AvanceProjetOptions" />
                  </b-form-group>
                </b-col>
                <b-col v-if="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" md="3">
                  <b-form-group class="mb-0">
                    <label for="date-signature-pret-secondaire" class="font-weight-normal">
                      Date de signature du prêt
                    </label>
                    <validation-provider #default="{ errors }" ref="dateSignature" name="date de signature du prêt secondaire" rules="required">
                      <flat-pickr
                          id="date-signature-pret-secondaire"
                          v-model="objetEmprunteur.prets.pret_secondaire.date_signature"
                          class="form-control"
                          :class="errors.length > 0 ? 'is-invalid' : ''"
                          :config="configDate"
                          placeholder="Date de signature"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-alert v-show="objetEmprunteur.prets.type_demande == 'SUBSTITUTION'" variant="danger" show class="mt-2 mb-0">
                    <div class="alert-body">
                      <p><strong>Attention :</strong> Prévoir une date d’effet à J+2 mois (acceptation du dossier). J+3 mois si le dossier présente des particularités.</p>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Type du prêt" label-for="type-pret-secondaire">
                    <validation-provider #default="{ errors }" name="type-pret-secondaire" rules="required">
                      <b-form-select id="type-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.type" :options="TypesPrincipal" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Nature" label-for="nature-secondaire">
                    <validation-provider #default="{ errors }" name="nature secondaire" rules="required">
                      <b-form-select id="nature-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.nature" :options="NaturesPrincipal" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Objet" label-for="objet-secondaire">
                    <validation-provider #default="{ errors }" name="objet secondaire" rules="required">
                      <b-form-select id="objet-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.objet" :options="ObjetPrincipalOptions" :state="errors.length > 0 ? false : null" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label class="mb-1 font-weight-normal">
                      S'agit-il d'un prêt palier ?
                      <span v-b-popover.hover.top="'Échéance variant dans le temps'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" title="Prêt à palier" class="ml-1 cursor-pointer" variant="outline-primary">
                                                <feather-icon icon="InfoIcon" size="20" />
                                            </span>
                    </label>
                    <validation-provider v-slot="{ errors }" name="has pret palier secondaire" rules="required" vid="has_pret_palier_secondaire">
                      <b-form-radio-group
                          id="has_pret_palier_secondaire_option"
                          v-model="objetEmprunteur.prets.pret_secondaire.has_pret_palier"
                          :options="BooleanValue"
                          @change="resetDataPretPalier($event, 'SECONDAIRE')"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0" label="Taux d'intérêt" label-for="taux_interet_secondaire">
                    <validation-provider #default="{ errors }" name="taux d'intérêt secondaire" rules="required|between:0.00,9.99">
                      <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.taux_interet" v-mask="['#', '.##']" :state="errors.length > 0 ? false : null" placeholder="Taux d'intérêt" type="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le champ est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group class="mb-0">
                    <label for="type-taux" class="mb-1 font-weight-normal">Type de taux</label>
                    <validation-provider #default="{ errors }" name="type-taux-secondaire" rules="required">
                      <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.type_taux" name="radio-type-taux-secondaire" :state="errors.length > 0 ? false : null">
                        <b-form-radio v-for="option in optionsTaux" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <template v-if="objetEmprunteur.prets.type_demande == 'DELIAISON_ASSURANCE'">
                  <b-col md="3">
                    <b-form-group label="Capital initial" label-for="capital-initial-secondaire">
                      <validation-provider #default="{ errors }" name="capital initial secondaire" rules="required|between:1,10000000">
                        <b-form-input id="capital-initial-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.capital" placeholder="Capital initial" type="number" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 10 000 000 euros
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0" label="Durée (en mois)" label-for="duree-secondaire">
                      <validation-provider #default="{ errors }" name="durée secondaire" rules="required|integer|between:1,600">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_pret" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 600 mois
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
                <template v-else>
                  <b-col md="3">
                    <b-form-group label="Capital restant dû à la date de substitution" label-for="capital-restant-secondaire">
                      <validation-provider #default="{ errors }" name="capital restant secondaire" rules="required|between:1,10000000">
                        <b-form-input id="capital-restant-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.capital" placeholder="Capital restant" type="number" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 10 000 000 euros
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group class="mb-0" label="Durée restante à compter de la date de substitution (en mois)" label-for="duree-restante-secondaire">
                      <validation-provider #default="{ errors }" name="durée restante secondaire" rules="required|integer|between:1,600">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_pret" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée restante" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          La valeur doit être comprise entre 1 et 600 mois
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
              <template v-if="objetEmprunteur.prets.pret_secondaire.has_pret_palier && true">
                <hr class="mb-2" />
                <b-row v-for="(pal_sec,index) in objetEmprunteur.prets.pret_secondaire.paliersArray" :key="`pret-secondaire-${index}`" class="mb-1">
                  <b-col md="12">
                    <b-row>
                      <b-col md="6">
                        <b-row>
                          <b-col md="6">
                            <b-form-group :label="`Mensualité du palier ${index + 1}`" :label-for="`mensualite-palier-secondaire-${index + 1}`">
                              <validation-provider #default="{ errors }" :name="`mensualite palier secondaire ${index + 1}`" rules="required">
                                <b-form-input
                                    :id="`mensualite-palier-secondaire-${index + 1}`"
                                    v-model="pal_sec.mensualite_palier"
                                    placeholder="Mensualité en €"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group class="mb-0" :label="`Durée du palier ${index + 1}`" :label-for="`duree-palier-secondaire-${index + 1}`">
                              <validation-provider #default="{ errors }" name="durée du palier" rules="required|integer|between:1,9999">
                                <b-form-input v-model="pal_sec.duree_palier" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le champ est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-if="!pal_sec.id" md="1" class="text-center" style="margin-top: 30px;">
                        <div class="d-inline" @click="removePalier(index, 'SECONDAIRE')">
                          <feather-icon icon="TrashIcon" size="22" class="mr-50 cursor-pointer text-danger" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="outline-primary" @click="addPalier('SECONDAIRE')" v-if="!ShowSecondaireAddPalier">
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle">AJOUTER UN PALIER</span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <b-row class="mt-1 align-items-center">
                <b-col md="4">
                  <b-form-group class="mb-0 mt-2">
                    <label class="float-left pr-1 mb-2">Le prêt comporte-t-il un différé ?</label>
                    <validation-provider v-slot="{ errors }" name="pret has differe secondaire" rules="required" vid="pret-has-differe-secondaire">
                      <b-form-radio-group id="pret-has-differe-secondaire-option" v-model="objetEmprunteur.prets.pret_secondaire.has_differe" @change="resetDataDiffere($event, 'SECONDAIRE')">
                        <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                          {{ option.text }}
                        </b-form-radio>
                      </b-form-radio-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        Le choix est obligatoire
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <template v-if="objetEmprunteur.prets.pret_secondaire.has_differe">
                  <b-col md="3">
                    <b-form-group class="mb-0">
                      <label for="type-differe-secondaire" class="font-weight-normal">Différé partiel ou total ?</label>
                      <validation-provider #default="{ errors }" name="type differe secondaire" rules="required">
                        <b-form-select id="type-differe-secondaire" v-model="objetEmprunteur.prets.pret_secondaire.type_differe" :options="ImpactDiffereOptions" :state="errors.length > 0 ? false : null" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le champ est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group class="mb-0" label="Durée du différé (en mois)" label-for="duree-differe-secondaire">
                      <validation-provider #default="{ errors }" name="durée différé secondaire" rules="required|integer|between:1,9999">
                        <b-form-input v-model="objetEmprunteur.prets.pret_secondaire.duree_differe" v-mask="'####'" :state="errors.length > 0 ? false : null" placeholder="Durée en mois" type="text" />
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </template>
              </b-row>
            </template>
          </app-collapse-item>
          <!-- Garanties -->
          <app-collapse-item ref="tab-collapse-item-4" :is-visible="true" title="<h4 class='title-custom-wizard'><u>Garanties</u></h4>">
            <b-row class="mb-2">
              <b-col md="12">
                <b class="d-block">Préciser par assuré et par prêt</b>
                <small>Attention à bien vérifier la cohérence avec le besoin exprimé par le client</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-alert v-show="errorsGaranties.length > 0" variant="danger" show class="mt-1">
                  <div class="alert-body">
                    <ul class="mb-0">
                      <li v-for="(error, index) in errorsGaranties" :key="`erreur-garantie-${index}`">
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt principal / Assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label class="mb-1" :class="objetEmprunteur.has_pret_secondaire ? 'mt-3' : ''">Quel est le besoin exprimé par l’assuré ?</label>
                      <validation-provider #default="{ errors }" name="besoin client" rules="required">
                        <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client"
                            name="radio-besoin-client"
                            :state="errors.length > 0 ? false : null"
                            class="demo-inline-spacing"
                            :disabled="disBc"
                            @change="resetValueBesoinClient($event, 'pret_principal', 'emprunteur')"
                        >
                          <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" class="mt-0">
                            <small>{{ option.text }}</small>
                            <span v-if="option.infoBull" :id="option.value" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer" variant="outline-primary">
                                                            <feather-icon icon="InfoIcon" size="20" />
                                                        </span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-1">
                    <b-row class="align-items-center">
                      <b-col md="4">
                        <b-form-group>
                          <label for="ptia" class="font-weight-normal">
                            Quotité Décès/PTIA (%)
                            <span
                                v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                :title="'Quotité'"
                                class="ml-1 cursor-pointer"
                                variant="outline-primary"
                            >
                                                            <feather-icon icon="InfoIcon" size="20" />
                                                        </span>
                          </label>
                          <validation-provider #default="{ errors }" name="ptia" rules="required|between:1,100">
                            <b-form-input id="ptia" v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.ptia" placeholder="Quotité Décès/PTIA (%)" type="number" :state="errors.length > 0 ? false : null" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              La valeur doit être comprisee entre 1 et 100
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group>
                          <label for="supplement-deces" class="float-left pr-4 font-weight-normal">Supplément décès accidentel</label>
                          <validation-provider #default="{ errors }" name="supplément décès" rules="required">
                            <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.suppl_deces" name="radio-supplement-deces" :state="errors.length > 0 ? false : null">
                              <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                {{ option.text }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              Le choix est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <template v-if="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client != 'MINIMUM'">
                        <b-col md="4">
                          <b-form-group>
                            <label for="incapacite" class="font-weight-normal">
                              Quotité Incapacité/Invalidité (%)
                              <span
                                  v-if="objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'OPTIMUM'"
                                  v-b-popover.top.hover.html="
                                  objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                "
                                  :title="'Quotité Incapacité/Invalidité (%)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="incapacite" rules="required|between:1,100">
                              <b-form-input
                                  id="incapacite"
                                  v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.incapacite"
                                  placeholder="Quotité Incapacité/Invalidité (%)"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <!-- <pre>{{  objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise  }}</pre> -->
                          <b-form-group>
                            <label for="franchise" class="font-weight-normal">Franchise</label>
                            <validation-provider #default="{ errors }" name="franchise" rules="required">
                              <b-form-select
                                  id="franchise"
                                  v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise"
                                  :options="filterFranchise(true, true)"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group v-if="showPerlEmplois.showPrincipal">
                            <label for="perte-emploi" class="font-weight-normal">Perte d'emploi</label>
                            <validation-provider #default="{ errors }" name="perte-emploi" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.pei" name="perte-emploi" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>

                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8" class="pt-2 pb-1">
                          <b-form-group>
                            <label for="mno-dospsy" class="float-left pr-3 font-weight-normal">
                              Option Dos/Psy ?
                              <span
                                  v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="mno" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.mno_dospsy" name="radio-mno-dospsy" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="exo" class="float-left pr-3 font-weight-normal">
                              Exonération des cotisations ?
                              <span
                                  v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="exo" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.emprunteur.exo" name="radio-exo-epp" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="objetEmprunteur.has_pret_secondaire" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt secondaire/ Assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group class="mb-1">
                      <label for="garanties-differentes" class="float-left pr-4">Les garanties souhaitées sont-elles identiques au prêt principal ?</label>
                      <validation-provider #default="{ errors }" name="garanties différentes" rules="required">
                        <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties"
                            name="radio-garanties-differentes"
                            :state="errors.length > 0 ? false : null"
                            @change="resetDataSecondaire($event, 'EMPRUNTEUR')"
                        >
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <template v-if="!objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties">
                    <b-col md="12">
                      <b-form-group>
                        <label class="mb-1">Quel est le besoin exprimé par l’assuré ?</label>
                        <validation-provider #default="{ errors }" name="besoin client secondaire" rules="required">
                          <b-form-radio-group
                              v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client"
                              name="radio-besoin-client-secondaire"
                              :state="errors.length > 0 ? false : null"
                              class="demo-inline-spacing"
                              :disabled="disBc"
                              @change="resetValueBesoinClient($event, 'pret_secondaire', 'emprunteur')"
                          >
                            <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" class="mt-0">
                              <small>{{ option.text }}</small>
                              <span v-if="option.infoBull" :id="option.value+'1'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer" variant="outline-primary">
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            Le choix est obligatoire
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="mt-1">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-form-group>
                            <label for="ptia-secondaire" class="font-weight-normal">
                              Quotité Décès/PTIA (%)
                              <span
                                  v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  :title="'Quotité'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="ptia-secondaire" rules="required|between:1,100">
                              <b-form-input
                                  id="ptia-secondaire"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.ptia"
                                  placeholder="Quotité Décès/PTIA (%)"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="supplement-deces-secondaire" class="float-left pr-4 font-weight-normal">Supplément décès accidentel</label>
                            <validation-provider #default="{ errors }" name="supplément décès secondaire" rules="required">
                              <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.suppl_deces"
                                  name="radio-supplement-deces-secondaire"
                                  :state="errors.length > 0 ? false : null"
                              >
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <template v-if="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client != 'MINIMUM'">
                          <b-col md="4">
                            <b-form-group>
                              <label for="incapacite-secondaire" class="font-weight-normal">
                                Quotité Incapacité/Invalidité (%)
                                <span
                                    v-if="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'OPTIMUM'"
                                    v-b-popover.top.hover.html="
                                    objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                  "
                                    :title="'Quotité Incapacité/Invalidité (%)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="incapacite-secondaire" rules="required|between:1,100">
                                <b-form-input
                                    id="incapacite-secondaire"
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.incapacite"
                                    placeholder="Quotité Incapacité/Invalidité (%)"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  La valeur doit être comprisee entre 1 et 100
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group>
                              <label for="franchise-secondaire" class="font-weight-normal">Franchise</label>
                              <validation-provider #default="{ errors }" name="franchise-secondaire" rules="required">
                                <b-form-select
                                    id="franchise-secondaire"
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.franchise"
                                    :options="filterFranchise(true, false)"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group v-if="showPerlEmplois.showPrincipal">
                              <label for="perte-emploi-secondaire" class="font-weight-normal">Perte d'emploi</label>
                              <validation-provider #default="{ errors }" name="perte-emploi-secondaire" rules="required">
                                <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.pei" name="perte-emploi-secondaire" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8" class="pt-2 pb-1">
                            <b-form-group>
                              <label for="mno-dospsy-secondaire" class="float-left pr-3 font-weight-normal">
                                Option Dos/Psy ?
                                <span
                                    v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="mno-secondaire" rules="required">
                                <b-form-radio-group
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.mno_dospsy"
                                    name="radio-mno-dospsy-secondaire"
                                    :state="errors.length > 0 ? false : null"
                                >
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8">
                            <b-form-group>
                              <label for="exo-secondaire" class="float-left pr-3 font-weight-normal">
                                Exonération des cotisations ?
                                <span
                                    v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="exo-secondaire" rules="required">
                                <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.exo" name="radio-exo-eps" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </template>
                      </b-row>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
            <hr v-if="objetEmprunteur.emprunteur.has_co_emprunteur" />
            <b-row class="my-2">
              <b-col v-if="objetEmprunteur.emprunteur.has_co_emprunteur" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt principal / Co-assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label class="mb-1" :class="objetEmprunteur.emprunteur.has_co_emprunteur && objetEmprunteur.has_pret_secondaire ? 'mt-3' : ''">Quel est le besoin exprimé par le co-assuré ?</label>
                      <validation-provider #default="{ errors }" name="besoin client co-emprunteur" rules="required">
                        <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client"
                            name="radio-besoin-client-co-emprunteur"
                            :state="errors.length > 0 ? false : null"
                            class="demo-inline-spacing"
                            :disabled="disBcCe"
                            @change="resetValueBesoinClient($event, 'pret_principal', 'co_emprunteur')"
                        >
                          <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" class="mt-0">
                            <small>{{ option.text }}</small>
                            <span v-if="option.infoBull" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :id="option.value+'2'" class="ml-1 cursor-pointer" variant="outline-primary">
                                                            <feather-icon icon="InfoIcon" size="20" />
                                                        </span>
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="mt-2">
                    <b-row class="align-items-center">
                      <b-col md="4">
                        <b-form-group>
                          <label for="ptia-co-emprunteur" class="font-weight-normal">
                            Quotité Décès/PTIA (%)
                            <span
                                v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                :title="'Quotité'"
                                class="ml-1 cursor-pointer"
                                variant="outline-primary"
                            >
                                                            <feather-icon icon="InfoIcon" size="20" />
                                                        </span>
                          </label>
                          <validation-provider #default="{ errors }" name="ptia-co-emprunteur" rules="required|between:1,100">
                            <b-form-input
                                id="ptia-co-emprunteur"
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.ptia"
                                placeholder="Quotité Décès/PTIA (%)"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              La valeur doit être comprisee entre 1 et 100
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group>
                          <label for="supplement-deces-co-assure" class="float-left pr-4 font-weight-normal">Supplément décès accidentel</label>
                          <validation-provider #default="{ errors }" name="supplément décès co-assure" rules="required">
                            <b-form-radio-group
                                v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.suppl_deces"
                                name="radio-supplement-deces-co-assure"
                                :state="errors.length > 0 ? false : null"
                            >
                              <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                {{ option.text }}
                              </b-form-radio>
                            </b-form-radio-group>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              Le choix est obligatoire
                            </b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <template v-if="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client != 'MINIMUM'">
                        <b-col md="4">
                          <b-form-group>
                            <label for="incapacite-co-emprunteur" class="font-weight-normal">
                              Quotité Incapacité/Invalidité (%)
                              <span
                                  v-if="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'OPTIMUM'"
                                  v-b-popover.top.hover.html="
                                  objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                "
                                  :title="'Quotité Incapacité/Invalidité (%)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="incapacite-co-emprunteur" rules="required|between:1,100">
                              <b-form-input
                                  id="incapacite-co-emprunteur"
                                  v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.incapacite"
                                  placeholder="Quotité Incapacité/Invalidité (%)"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group>
                            <label for="franchise-co-emprunteur" class="font-weight-normal">Franchise</label>
                            <validation-provider #default="{ errors }" name="franchise-co-emprunteur" rules="required">
                              <b-form-select
                                  id="franchise-co-emprunteur"
                                  v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.franchise"
                                  :options="filterFranchise(false, true)"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group v-if="showPerlEmplois.showSecondaire">
                            <label for="perte-emploi-co-emprunteur" class="font-weight-normal">Perte d'emploi</label>
                            <validation-provider #default="{ errors }" name="perte-emploi-co-emprunteur" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.pei" name="perte-emploi-co-emprunteur" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>

                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8" class="pt-2 pb-1">
                          <b-form-group>
                            <label for="mno-dospsy-co-emprunteur" class="float-left pr-3 font-weight-normal">
                              Option Dos/Psy ?
                              <span
                                  v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="mno-co-emprunteur" rules="required">
                              <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.mno_dospsy"
                                  name="radio-mno-dospsy-co-emprunteur"
                                  :state="errors.length > 0 ? false : null"
                              >
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="exo-co-emprunteur" class="float-left pr-3 font-weight-normal">
                              Exonération des cotisations ?
                              <span
                                  v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="exo-co-emprunteur" rules="required">
                              <b-form-radio-group v-model="objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.exo" name="radio-cepp" :state="errors.length > 0 ? false : null">
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col v-if="objetEmprunteur.emprunteur.has_co_emprunteur && objetEmprunteur.has_pret_secondaire" md="6">
                <i class="title-custom-wizard d-block mb-2"><b>Prêt secondaire / Co-assuré</b></i>
                <b-row>
                  <b-col md="12">
                    <b-form-group class="mb-1">
                      <label for="garanties-differentes-co-emprunteur" class="float-left pr-4">Les garanties souhaitées sont-elles identiques au prêt principal ?</label>
                      <validation-provider #default="{ errors }" name="garanties différentes co-emprunteur" rules="required">
                        <b-form-radio-group
                            v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties"
                            name="radio-garanties-differentes-co-emprunteur"
                            :state="errors.length > 0 ? false : null"
                            @change="resetDataSecondaire($event, 'CO-EMPRUNTEUR')"
                        >
                          <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                            {{ option.text }}
                          </b-form-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                          Le choix est obligatoire
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <template v-if="!objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties">
                    <b-col md="12">
                      <b-form-group>
                        <label class="mb-1">Quel est le besoin exprimé par le co-assuré ?</label>
                        <validation-provider #default="{ errors }" name="besoin client secondaire co-emprunteur" rules="required">
                          <b-form-radio-group
                              v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client"
                              name="radio-besoin-client-secondaire-co-emprunteur"
                              :state="errors.length > 0 ? false : null"
                              class="demo-inline-spacing"
                              :disabled="disBcCe"
                              @change="resetValueBesoinClient($event, 'pret_secondaire', 'co_emprunteur')"
                          >
                            <b-form-radio v-for="option in BesoinClientOptions" :key="option.value" :value="option.value" class="mt-0">
                              <small>{{ option.text }}</small>
                              <span v-if="option.infoBull" :id="option.value+ '3'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1 cursor-pointer" variant="outline-primary">
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </b-form-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            Le choix est obligatoire
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="mt-1">
                      <b-row class="align-items-center">
                        <b-col md="4">
                          <b-form-group>
                            <label for="ptia-secondaire-co-emprunteur" class="font-weight-normal">
                              Quotité Décès/PTIA (%)
                              <span
                                  v-b-popover.top.hover.html="'La quotité assurée sert à calculer le montant de la cotisation et à déterminer le taux d’indemnisation.'"
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  :title="'Quotité'"
                                  class="ml-1 cursor-pointer"
                                  variant="outline-primary"
                              >
                                                                <feather-icon icon="InfoIcon" size="20" />
                                                            </span>
                            </label>
                            <validation-provider #default="{ errors }" name="ptia-secondaire-co-emprunteur" rules="required|between:1,100">
                              <b-form-input
                                  id="ptia-secondaire-co-emprunteur"
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.ptia"
                                  placeholder="Quotité Décès/PTIA (%)"
                                  type="number"
                                  :state="errors.length > 0 ? false : null"
                              />
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                La valeur doit être comprisee entre 1 et 100
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="8">
                          <b-form-group>
                            <label for="supplement-deces-co-assure-secondaire" class="float-left pr-4 font-weight-normal">Supplément décès accidentel</label>
                            <validation-provider #default="{ errors }" name="supplément décès co-assure secondaire" rules="required">
                              <b-form-radio-group
                                  v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.suppl_deces"
                                  name="radio-supplement-deces-co-assure-secondaire"
                                  :state="errors.length > 0 ? false : null"
                              >
                                <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                  {{ option.text }}
                                </b-form-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                Le choix est obligatoire
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <template v-if="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client != 'MINIMUM'">
                          <b-col md="4">
                            <b-form-group>
                              <label for="incapacite-secondaire-co-emprunteur" class="font-weight-normal">
                                Quotité Incapacité/Invalidité (%)
                                <span
                                    v-if="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'COMPLETE' || objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'OPTIMUM'"
                                    v-b-popover.top.hover.html="
                                    objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client == 'COMPLETE' ? 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT) et d\'Incapacité Temporaire de Travail (ITT).' : 'Quotité pour les garanties d\'Invalidité Permanente Totale (IPT),d\'Incapacité Temporaire de Travail (ITT),d\'Invalidité Permanente Partielle (IPP) et de Maladie non objectivable sans conditions'"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'
                                  "
                                    :title="'Quotité Incapacité/Invalidité (%)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="incapacite-secondaire-co-emprunteur" rules="required|between:1,100">
                                <b-form-input
                                    id="incapacite-secondaire-co-emprunteur"
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.incapacite"
                                    placeholder="Quotité Incapacité/Invalidité (%)"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  La valeur doit être comprisee entre 1 et 100
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group>
                              <label for="franchise-secondaire-co-emprunteur" class="font-weight-normal">Franchise</label>
                              <validation-provider #default="{ errors }" name="franchise-secondaire-co-emprunteur" rules="required">
                                <b-form-select
                                    id="franchise-secondaire-co-emprunteur"
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.franchise"
                                    :options="filterFranchise(false, false)"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group v-if="showPerlEmplois.showSecondaire">
                              <label for="perte-emploi-co-emprunteur-secondaire" class="font-weight-normal">Perte d'emploi</label>
                              <validation-provider #default="{ errors }" name="perte-emploi-co-emprunteur-secondaire" rules="required">
                                <b-form-radio-group
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.pei"
                                    name="perte-emploi-co-emprunteur-secondaire"
                                    :state="errors.length > 0 ? false : null"
                                >
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>

                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8" class="pt-2 pb-1">
                            <b-form-group>
                              <label for="mno-dospsy-co-emprunteur-secondaire" class="float-left pr-3 font-weight-normal">
                                Option Dos/Psy ?
                                <span
                                    v-b-popover.top.hover.html="`<p>La couverture des Maladies non objectivables sans condition (<strong>MNO</strong>) permet le rachat des exclusions sur les maladies dorso-vertèbrales et psychiques.</p>`"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="mno-co-emprunteur-secondaire" rules="required">
                                <b-form-radio-group
                                    v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.mno_dospsy"
                                    name="radio-mno-dospsy-co-emprunteur-secondaire"
                                    :state="errors.length > 0 ? false : null"
                                >
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="8">
                            <b-form-group>
                              <label for="exo-co-emprunteur-secondaire" class="float-left pr-3 font-weight-normal">
                                Exonération des cotisations ?
                                <span
                                    v-b-popover.top.hover.html="`<p>Cette option de garantie permet, sous conditions, le remboursement du paiement des cotisations en cas d’arrêt de travail (ITT ou IPT).</p>`"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="ml-1 cursor-pointer"
                                    variant="outline-primary"
                                >
                                                                    <feather-icon icon="InfoIcon" size="20" />
                                                                </span>
                              </label>
                              <validation-provider #default="{ errors }" name="exo-co-emprunteur-secondaire" rules="required">
                                <b-form-radio-group v-model="objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.exo" name="radio-ceps" :state="errors.length > 0 ? false : null">
                                  <b-form-radio v-for="option in BooleanValue" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                  </b-form-radio>
                                </b-form-radio-group>
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                  Le choix est obligatoire
                                </b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </template>
                      </b-row>
                    </b-col>
                  </template>
                </b-row>
              </b-col>
            </b-row>
          </app-collapse-item>
          <!--  -->
          <app-collapse-item ref="tab-collapse-item-5" :is-visible="false" title="<h4 class='title-custom-wizard'><u>Informations complémentaires du courtier</u></h4>">
            <b-row>
              <b-col md="12">
                <b-alert v-show="true" variant="primary" show>
                  <div class="alert-body">
                    <p>
                      Merci de préciser le taux de commission souhaité pour Harmonie mutuelle.
                    </p>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="taux-commission" class="font-weight-normal">Taux de commission (en %) *</label>
                  <validation-provider #default="{ errors }" name="taux de commission" rules="required">
                    <b-form-select id="taux-commission" v-model="objetEmprunteur.taux_commission" :options="TauxeOptions" :state="errors.length > 0 ? false : null" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      Le choix est obligatoire
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </validation-observer>
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button variant="primary" class="float-left" size="lg" @click="backStep">
          Précédent
        </b-button>

        <b-button variant="primary" class="float-right" size="lg" @click="checkValidateField(0)">
          Suivant
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TheFormSpinbuttonWithInput from '@core/components/input-number/TheFormSpinbuttonWithInput.vue'
import {
  BFormSpinbutton,
  BPopover,
  BOverlay,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormRadio,
  BTabs,
  BTab,
  BFormSelect,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BButton,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BFormValidFeedback,
  BAlert,
  VBTooltip,
  VBPopover,
  BFormSelectOption,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import {
  required, requiredIf, minLength, maxLength, between, email,
} from 'vuelidate/lib/validators'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import { rules } from '@core/libs/validations'

// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BPopover,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormRadioGroup,
    BFormRadio,
    BTabs,
    BTab,
    flatPickr,
    BFormSelect,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BButton,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormValidFeedback,
    BOverlay,
    Cleave,
    BAlert,
    VueSlider,
    BFormSpinbutton,
    BFormCheckboxGroup,
    AppCollapse,
    AppCollapseItem,
    TheFormSpinbuttonWithInput,
    BFormSelectOption,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    ...mapGetters(['getEtude']),
    ...mapGetters(['Tiers']),
    ...mapGetters(['Conjoint']),
    hasCoEmprunteurSelected() {
      let coEmprunteur = null
      const parentSelected = this.parentsArray.find(p => p.state == true)
      const enfantSelected = this.enfantsArray.find(p => p.state == true)
      const amiSelected = this.amisArray.find(p => p.state == true)
      switch (this.objetEmprunteur.lien) {
        case 'CONJOINT':
          return coEmprunteur = this.conjoint
        case 'PARENT':
          return parentSelected != undefined ? coEmprunteur = parentSelected : coEmprunteur
        case 'ENFANT':
          return enfantSelected != undefined ? coEmprunteur = enfantSelected : coEmprunteur
        case 'AMI':
          return amiSelected != undefined ? coEmprunteur = amiSelected : coEmprunteur
        default:
          return coEmprunteur
      }

    },
    ShowPrincipalAddPalier() {
      return this.objetEmprunteur.prets.pret_principal.paliersArray.length >= 4;
    },
    ShowSecondaireAddPalier(){
      return this.objetEmprunteur.prets.pret_secondaire.paliersArray.length >= 4;
    },
    enfantsHasValidDateNaissance: function () {
      let enfantNovalidDate = _.filter(this.enfantsArray, enf => enf.state && (Math.floor(moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(enf.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79 ));
      return this.enfantsArray.length == 0 || enfantNovalidDate.length == 0 ? true : false
    },
    parentHasValidDateNaissance: function () {
      let parentNovalidDate = _.filter(this.parentsArray, parent => parent.state && (Math.floor(moment().diff(parent.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(parent.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79));
      return this.parentsArray.length == 0 || parentNovalidDate.length == 0 ? true : false
    },
    amiHasValidDateNaissance: function () {
      let amisNovalidDate = _.filter(this.amisArray, ami => ami.state && (Math.floor(moment().diff(ami.date_naissance.split("/").reverse().join("-"), 'years', true)) < 18 || Math.floor(moment().diff(ami.date_naissance.split("/").reverse().join("-"), 'years', true)) > 79));
      return this.amisArray.length == 0 || amisNovalidDate.length == 0 ? true : false
    }
  },
  watch: {
    getEtude: {
      handler(old, nld) {
        this.initialAssure(old.Tiers)
        this.initialConjoint(old.Conjoint)
        this.objetEmp = old.itemsRisque.ItemsRisque.length > 0 ? old.itemsRisque.ItemsRisque[0].objets.objet_emprunteurs[0] : null
        this.garanties = old.itemsRisque.ItemsRisque.length > 0 ? old.itemsRisque.ItemsRisque[0].garanties : null
        this.exigences = old.itemsRisque.ItemsRisque.length > 0 ? old.itemsRisque.ItemsRisque[0].exigences : null
        this.devis = old.itemsRisque.ItemsRisque.length > 0 ? old.itemsRisque.ItemsRisque[0].devis : null
        this.enfantStore = old.Enfant.Enfant
        this.parentStore = old.Parent.Parent
        this.amiStore = old.Ami.Ami
        if (old.itemsRisque.ItemsRisque.length > 0 && old.itemsRisque.ItemsRisque[0].objets.objet_emprunteurs[1] != undefined) {
          this.objetEmprunteur = old.itemsRisque.ItemsRisque.length > 0 ? old.itemsRisque.ItemsRisque[0].objets.objet_emprunteurs[1] : null
        }
      },
      deep: true,
      immediate: true,
    },
    enfantStore: {
      handler(old, nld) {
        this.$http
          .get(`devis/getProspectDevisEnfants/${this.devis.id}`)
          .then(r => {
            this.mapConnexiteForStore(r.data, 'ENFANT')
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate: true,
    },
    parentStore: {
      handler(old, nld) {
        this.$http
          .get(`devis/getProspectDevisParents/${this.devis.id}`)
          .then(r => {
            this.mapConnexiteForStore(r.data, 'PARENT')
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate: true,
    },
    amiStore: {
      handler(old, nld) {
        this.$http
          .get(`devis/getProspectDevisAmis/${this.devis.id}`)
          .then(r => {
            this.mapConnexiteForStore(r.data, 'AMI')
          })
          .catch(e => {
            console.log(e)
          })
      },
      immediate: true,
    },
    'devis.is_a_distance': {
      handler(val) {
        if (!val) {
          this.devis.type_vente = null
        }
      },
    },
    'devis.type_vente': {
      handler(val) {
        if (this.objetEmprunteur.ria) {
          if (val == 'optout') {
            this.riaDate = moment().add(36, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(36)
          } else {
            this.riaDate = moment().add(35, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(35)
          }
        } else {
          this.changeDisabledDate(0)
        }
      },
    },
    'assure.moyen_contact.code_postal': {
      immediate: true,
      handler(val) {
        if (val && val.length === 5) {
          this.$http
            .get(`/helpers/${val}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villeOptions = [...res.data]
                const obj = this.villeOptions.find(o => o.id === this.assure.moyen_contact.ville_id)
                if (typeof obj === 'undefined' || obj === null) {
                  this.assure.moyen_contact.ville_id = this.initialState().assure.moyen_contact.ville_id
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.villeOptions = []
        }
      },
    },
    'conjoint.moyen_contact.code_postal': {
      immediate: true,
      handler(val) {
        this.getVilleByCodePostal(val, -1, 'CONJOINT')
      },
    },
    'objetEmprunteur.emprunteur.has_co_emprunteur': {
      immediate: true,
      handler(val) {
        if(!val) {
          this.objetEmprunteur.lien = null
        }
      }
    },
    'objetEmprunteur.lien': {
      immediate: true,
      handler(val) {
        if (val) {
          if (val == 'CONJOINT') {
            this.showPei(this.conjoint.statut, 'co_emprunteur', this.conjoint.date_naissance)
            this.objetEmprunteur.co_emprunteur.isPPE = this.conjoint.isPPE
            this.getVilleByCodePostal(this.conjoint.moyen_contact.code_postal, -1, val)
          } else if (val == 'ENFANT') {
            const enfantSelected = this.enfantsArray.find(enf => enf.state == true)
            const indexEnfantSelected = this.enfantsArray.map(enf => enf.state).indexOf(true)
            if (enfantSelected != undefined) {
              this.objetEmprunteur.co_emprunteur.isPPE = enfantSelected.isPPE
              this.getVilleByCodePostal(enfantSelected.moyen_contact.code_postal, indexEnfantSelected, val)
            }
          } else if (val == 'PARENT') {
            const parentSelected = this.parentsArray.find(par => par.state == true)
            const indexParentSelected = this.parentsArray.map(par => par.state).indexOf(true)
            if (parentSelected != undefined) {
              this.objetEmprunteur.co_emprunteur.isPPE = parentSelected.isPPE
              this.getVilleByCodePostal(parentSelected.moyen_contact.code_postal, indexParentSelected, val)
            }
          } else if (val == 'AMI') {
            const amiSelected = this.amisArray.find(par => par.state == true)
            const indexAmiSelected = this.amisArray.map(par => par.state).indexOf(true)
            if (amiSelected != undefined) {
              this.objetEmprunteur.co_emprunteur.isPPE = amiSelected.isPPE
              this.getVilleByCodePostal(amiSelected.moyen_contact.code_postal, indexAmiSelected, val)
            }
          }
        } else {
          this.objetEmprunteur.co_emprunteur = this.initialState().objetEmprunteur.co_emprunteur
        }
        // clear checked co-assuré
        this.resetStateCoAssure(val)
      },
    },
    'objetEmprunteur.emprunteur.profession': {
      handler(val) {
        this.checkProfession(val, 'EMPRUNTEUR')
      },
    },
    'objetEmprunteur.co_emprunteur.profession': {
      handler(val) {
        this.checkProfession(val, 'CO-EMPRUNTEUR')
      },
    },
    'objetEmprunteur.ria': {
      handler(val) {
        if (val) {
          if (this.devis.type_vente == 'optout') {
            this.riaDate = moment().add(35, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(35)
          } else {
            this.riaDate = moment().add(34, 'days').format('DD/MM/YYYY')
            this.changeDisabledDate(34)
          }
        } else {
          this.changeDisabledDate(0)
        }
      },
    },
  },
  data() {
    return this.initialState()
  },
  validations: {
  },
  created() {
    this.getPays()
  },
  mounted() {
    setTimeout(() => {
      this.$refs.AssureRules.reset()
    }, 1000)
  },
  beforeMount() {
    this.initOriginalData(this.assure)
    this.initOriginalDataConjoint(this.getEtude.Conjoint)
  },
  methods: {
    filterFranchise(isAssure, isPretPrincipale) {
      // Determine which data to use based on the isAssure flag
      const assureData = isAssure ? this.assure : this.hasCoEmprunteurSelected ? this.hasCoEmprunteurSelected : null
      const emprunteurData = isAssure ? this.objetEmprunteur.emprunteur : this.objetEmprunteur.co_emprunteur

      // Determine which pret data to use based on the isPretPrincipale flag
      const pretData = isPretPrincipale ? this.objetEmprunteur.prets.pret_principal : this.objetEmprunteur.prets.pret_secondaire
      if (!assureData || !pretData) {
        return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }
      // Calculate the "date fin de prêt" based on the type of demand
      const dateFinPret = this.calculateDateFinPret(this.objetEmprunteur.prets.type_demande, pretData, isPretPrincipale)

      // Calculate the difference between "date fin de prêt" and "date de naissance"
      const startDate = moment(dateFinPret, "DD/MM/YYYY").startOf("day")
      const dateDeNaissance = moment(assureData.date_naissance, "DD/MM/YYYY").startOf("day")
      const differenceInYears = moment.duration(startDate.diff(dateDeNaissance)).asYears()

      if (!dateFinPret) {
        return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }
      if (!emprunteurData.en_cours && differenceInYears < 60){
        return [{ value: null, text: 'Choisissez' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
      }

      return [{ value: null, text: 'Choisissez' }, { value: '30', text: '30' }, { value: '60', text: '60' }, { value: '90', text: '90' }, { value: '120', text: '120' }, { value: '180', text: '180' }]
    },
    calculateDateFinPret(typeDemande, pretData, isPretPrincipale) {
      let endDate;
      let startDate;
      const dureePret = pretData.duree_pret;
      if (typeDemande === "DELIAISON_ASSURANCE") {
        if (isPretPrincipale || (!isPretPrincipale && pretData.has_diff_date)) {
          startDate = moment(pretData.date_effet, "DD/MM/YYYY");
        }
        startDate = moment(this.objetEmprunteur.prets.pret_principal.date_effet, "DD/MM/YYYY");
      } else if (typeDemande === "SUBSTITUTION") {
        startDate = moment(pretData.date_signature, "DD/MM/YYYY");
      }
      endDate = startDate ? startDate.add(dureePret, 'months') : null;
      return endDate ? endDate.format("DD/MM/YYYY") : null;
    },
    initialState() {
      return {
        showPerlEmplois: {
          showPrincipal: false,
          showSecondaire: false,
        },
        riaDate: null,
        disBc: false,
        disBcCe: false,
        dateHamon: null,
        originalData: {
          assure: {
            civilite: null,
            situation_familiale: null,
            date_naissance: null,
            statut: null,
            nom:null,
            nom_naissance:null,
            prenom:null,
            code_postal_naissance:null,
            commune_naissance:null,
            pays_naissance:null,
            moyen_contact : {
              code_postal : null,
              ville_id : null
            }

          },
          conjoint: {
            civilite: null,
            situation_familiale: null,
            date_naissance: null,
            statut: null,
            nom : null,
            nom_naissance : null,
            prenom : null,
            code_postal_naissance : null,
            commune_naissance : null,
            pays_naissance : null,
            moyen_contact : {
              code_postal : null,
              ville_id : null
            }
          },
        },
        errorsGaranties: [],
        errorsTarif: [],
        enfantsArray: [],
        parentsArray: [],
        amisArray: [],
        enfantsLocal: [],
        parentsLocal: [],
        enfantStore: [],
        parentStore: [],
        amiStore: [],
        villeOptions: [],
        villeOptionsCoEmprunteur: [],
        pays: [],
        options: {
          codePostal: {
            blocks: [5]
          },
          number: {
            numeral: true,
          },
        },
        errorDateNaissanceProspect: null,
        configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              const ele = document.activeElement
              let val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        configDateEffet: {
          dateFormat: 'd/m/Y',
          disable: [function (date) {
            const localyDayHours = new Date().setHours(0, 0, 0, 0)
            const localDay = new Date(localyDayHours)
            return date < localDay
          }, function (date) {
            const localyDayHours = new Date().setHours(0, 0, 0, 0)
            const localDayThreeMonths = new Date(new Date(localyDayHours).setMonth(new Date(localyDayHours).getMonth() + 3))
            return date > localDayThreeMonths
          }],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              const ele = document.activeElement
              let val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              const ele = document.activeElement
              let val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          },
        },
        enfant: {
          id: null,
          nom: null,
          civilite: null,
          situation_familiale: null,
          nom_naissance: null,
          prenom: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut: null,
          date_naissance: null,
          state: false,
          type_lien_familial: 'ENFANT',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null,
          },
        },
        parent: {
          id: null,
          nom: null,
          civilite: null,
          situation_familiale: null,
          nom_naissance: null,
          prenom: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut: null,
          date_naissance: null,
          state: false,
          type_lien_familial: 'PARENT',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null,
          },
        },
        ami: {
          id: null,
          nom: null,
          civilite: null,
          situation_familiale: null,
          nom_naissance: null,
          prenom: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut: null,
          date_naissance: null,
          state: false,
          type_lien_familial: 'AMI',
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null,
          },
        },
        conjoint: {
          id: null,
          nom: null,
          nom_naissance: null,
          prenom: null,
          date_naissance: null,
          code_postal_naissance: null,
          commune_naissance: null,
          pays_naissance: null,
          statut: null,
          civilite: null,
          num_secu: null,
          numeroOrganisme: null,
          type_lien_familial: 'CONJOINT',
          situation_familiale: null,
          statut_gda: null,
          isPPE: null,
          moyen_contact: {
            code_postal: null,
            ville_id: null,
          },
        },
        palier: {
          id: null,
          mensualite_palier: null,
          duree_palier: null,
        },
        garanties: [],
        exigences: [],
        objetEmp: [],
        objetEmprunteur: {
          taux_commission: null,
          ria: 0,
          lien: null,
          has_pret_secondaire: 0,
          emprunteur: {
            type_assure: null,
            en_conge: null,
            fumeur: null,
            is_contrat_temps_plein: null,
            deplacement_pro: null,
            activite_manuel_regulier: null,
            travail_hauteur: null,
            en_cours: null,
            profession: null,
            precision_profession: null,
            has_co_emprunteur: 0,
          },
          co_emprunteur: {
            type_assure: null,
            en_conge: null,
            fumeur: null,
            is_contrat_temps_plein: null,
            deplacement_pro: null,
            activite_manuel_regulier: null,
            travail_hauteur: null,
            en_cours: null,
            profession: null,
            precision_profession: null,
            isPPE: null, // traitement back
          },
          prets: {
            type_demande: null,
            type_formule: null,
            pret_principal: {
              date_effet: null,
              avance_projet: null,
              date_signature: null,
              type: null,
              nature: null,
              objet: null,
              has_pret_palier: null,
              taux_interet: null,
              type_taux: null,
              capital: 0,
              duree_pret: 0,
              has_differe: null,
              duree_differe: null,
              type_differe: null,
              paliersArray: [],
              garanties: {
                emprunteur: {
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null,
                },
                co_emprunteur: {
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null,
                },
              },
            },
            pret_secondaire: {
              has_diff_date: null,
              date_effet: null,
              avance_projet: null,
              date_signature: null,
              type: null,
              nature: null,
              objet: null,
              has_pret_palier: null,
              taux_interet: null,
              type_taux: null,
              capital: 0,
              duree_pret: 0,
              has_differe: null,
              duree_differe: null,
              type_differe: null,
              paliersArray: [],
              garanties: {
                emprunteur: {
                  has_same_garanties: 0,
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null,
                },
                co_emprunteur: {
                  has_same_garanties: 0,
                  besoin_client: null,
                  ptia: null,
                  incapacite: null,
                  franchise: null,
                  suppl_deces: null,
                  pei: null,
                  mno_dospsy: null,
                  exo: null,
                },
              },
            },
          },
        },
        assure: {
            moyen_contact: {
            code_postal: null,
            ville_id: null,
          },
        },
        civilite: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'MR',
            text: 'M.',
          },
          {
            value: 'MME',
            text: 'Mme',
          },
        ],
        LienOptions: [{
          value: null,
          text: 'Choisissez',

        },
          {
            value: 'CONJOINT',
            text: 'Conjoint',
          },
          {
            value: 'ENFANT',
            text: 'Enfant',
          },
          {
            value: 'PARENT',
            text: 'Parent',
          },
          {
            value: 'AMI',
            text: 'Ami',
          },
        ],
        SituationFamilialeOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            text: 'Concubin(e)',
            value: 'CONCUBIN',
          },
          {
            text: 'Célibataire',
            value: 'CELIBATAIRE',
          },
          {
            text: 'Divorcé(e)',
            value: 'DIVORCE',
          },
          {
            text: 'Marié(e)',
            value: 'MARIE',
          },
          {
            text: 'Séparé(e)',
            value: 'SEPARE',
          },
          {
            text: 'Veuf(ve)',
            value: 'VEUF',
          },
          {
            text: 'Pacsé(e)',
            value: 'PACSE',
          },
        ],
        optionsVenteDistance: [
          { text: 'Oui', value: 1 },
          { text: 'Non', value: 0 },
        ],
        optionsTypeVente: [
          { text: 'Vente à distance en 1 temps', value: 'optin', infoBulle: 'Prospect ayant préalablement consenti à l\'appel ou client.' },
          { text: 'Vente à distance en 2 temps', value: 'optout', infoBulle: 'Prospect n\'ayant pas consenti à l\'appel. Un délai de 24h s\'appliquera avant la mise en place de la signature électronique.' },
        ],
        optionsTaux: [
          { text: 'Fixe', value: 'FIXE' },
          { text: 'Variable', value: 'VARIABLE' },
        ],
        BooleanValue: [{
          value: 1,
          text: 'Oui',
        },
          {
            value: 0,
            text: 'Non',
          },
        ],
        ActiviteProfessionnelle: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'AGRICULTEUR',
            text: 'Agriculteur',
          },
          {
            value: 'ARTISAN',
            text: 'Artisan',
          },
          {
            value: 'CHEF_ENTREPRISE',
            text: "Chef d'entreprise",
          },
          {
            value: 'COMMERCANT',
            text: 'Commerçant',
          },
          {
            value: 'ETUDIANT',
            text: 'Etudiant',
          },
          {
            value: 'FONCTIONNAIRE_ASSIMILE',
            text: 'Fonctionnaire ou assimilé',
          },
          {
            value: 'PROFESSION_LIBERALE',
            text: 'Profession libérale',
          },
          {
            value: 'RETRAITE',
            text: 'Retraité',
          },
          {
            value: 'SALARIE_NON_CADRE',
            text: 'Salarié non cadre',
          },
          {
            value: 'SALARIE_CADRE',
            text: 'Salarié cadre',
          },
          {
            value: 'SANS_EMPLOI',
            text: 'Sans emploi',
          },
        ],
        TypeAssure: [
          {
            value: 'EMPRUNTEUR',
            text: 'Emprunteur',
          },
          {
            value: 'CO_EMPRUNTEUR',
            text: 'Co-emprunteur',
          },
          {
            value: 'CAUTION',
            text: 'Caution',
          },
        ],
        TypesPrincipal: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'AMORTISSABLE',
            text: 'Amortissable',
          },
          {
            value: 'IN_FINE',
            text: 'In Fine',
          },
          {
            value: 'RELAIS',
            text: 'Relais',
          },
        ],
        NaturesPrincipal: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'PRET_IMMOBILIER',
            text: 'Prêt Immobilier',
          },
          {
            value: 'PRET_PERSONNEL',
            text: 'Prêt Personnel',
          },
          {
            value: 'PRET_PROFESSIONNEL',
            text: 'Prêt Professionnel',
          },
        ],
        ObjetPrincipalOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'RESIDENCE_PRINCIPALE',
            text: 'Résidence Principale',
          },
          {
            value: 'RESIDENCE_SECONDAIRE',
            text: 'Résidence Secondaire',
          },
          {
            value: 'IVESTISSEMENT_LOCATIF',
            text: 'Investissement locatif',
          },
          {
            value: 'TRAVAUX',
            text: 'Travaux',
          },
          {
            value: 'CONSOMMATION',
            text: 'Consommation',
          },
          {
            value: 'PRET_HYPOTHECAIRE',
            text: 'Prêt hypothécaire',
          },
        ],
        TypePrecisionOptions: {
          SANS_EMPLOI: [{
            value: null,
            text: 'Choisissez'
          },{
            value: "CHOMEURS_INDEMNISES",
            text: "Chômeurs indemnisés"
          },
            {
              value: "CHOMEURS_NON_INDEMNISES",
              text: "Chômeurs non indemnisés"
            }],
          PROFESSION_LIBERALE: [{
            value: null,
            text: 'Choisissez'
          },{
            value: "PROFESSION_LIBERALE_MEDICAL",
            text: "Profession libérale médical/paramed"
          },
            {
              value: "PROFESSION_LIBERALE_HORS_MED",
              text: "Profession libérale hors med/paramed"
            }],
          FONCTIONNAIRE_ASSIMILE : [{
            value: null,
            text : 'Choisissez'
          },{
            value:  "CATEGORIE_A",
            text : "Catégorie A"
          },{
            value : "CATEGORIE_B_OU_C",
            text : "Catégorie B ou C"
          }],
        },
        ImpactDiffereOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'CAPITAL',
            text: 'Capital',
          },
          {
            value: 'CAPITAL_INTERET',
            text: 'Capital + intérêt',
          }
        ],
        AvanceProjetOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'COMROMIS_SIGNE',
            text: 'Compromis signé',
          },
          {
            value: 'NEGOCIATION_EN_COURS',
            text: 'Négociation en cours',
          },
          {
            value: 'DEMANDE_INFORMATION',
            text: "Demande d'information",
          },
        ],
        TypeFormuleOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'CI',
            text: 'Capital Initial (CI)',
          },
          {
            value: 'CRD',
            text: 'Capital Restant Dû (CRD)',
          },
        ],
        TypeDemandeOptions: [{
          value: 'DELIAISON_ASSURANCE',
          text: 'Déliaison',
          infoBulle: {
            title: 'Déliaison pour un prêt à venir',
            description: "L'assuré est en train de faire un prêt, et  ne souhaite pas souscrire le contrat d'assurance proposé par le prêteur.",
          },
        },
          {
            value: 'SUBSTITUTION',
            text: 'Substitution',
            infoBulle: {
              title: 'Substitution pour un prêt déjà signé',
              description: "L'assuré a déjà signé une offre de prêt et  souhaite changer d'assurance.",
            },
          },
        ],
        DeplacementeProfessionnelOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: 'MOINS_200K',
            text: "Moins de 20 000 km",
          },
          {
            value: 'PLUS_200K',
            text: '20 000 km ou plus',
          }],
        TauxeOptions: [{
          value: null,
          text: 'Choisissez',
        },
          {
            value: '10',
            text: '10',
          },
          {
            value: '20',
            text: '20',
          },
          {
            value: '30',
            text: '30',
          }],
        BesoinClientOptions: [
          {
            value: 'MINIMUM',
            text: 'Minimum (Décès/PTIA)',
            infoBull: {
              title: 'Minimum (Décès/PTIA)',
              description: "<ul><li><p>Minimum (Décès/PTIA) :</p><ul><li><p>La garantie décès est la garantie minimale de tout contrat d'assurance emprunteur. En cas de décès de l'assuré, l'assureur remboursera à l'organisme prêteur le capital restant dû à hauteur de la quotité assurée.</p></li><li><p>La perte totale et irréversible d’autonomie (<strong>PTIA</strong>) assure le remboursement lorsque l’assuré est dans l’incapacité définitive d’exercer toute activité rémunératrice et dans l’obligation d'être assisté par une tierce personne pour l’accomplissement de tous les actes de la vie quotidienne.</p></li></ul></li></ul>",
            },
          },
          {
            value: 'COMPLETE',
            text: 'Complète (Minimum + IPT/ITT)',
            infoBull: {
              title: 'Complète (Minimum + IPT/ITT)',
              description: "<ul><li><p>Complète (Minimum + IPT/ITT) : Outre les garanties minimum décès et PTIA :</p><ul><li><p>La garantie d'Invalidité Permanente Totale (<strong>IPT</strong>) assure le remboursement du capital restant dû lorsqu'un taux d'invalidité supérieur à 66 % a été reconnu.</p></li><li><p>La garantie d'Incapacité Temporaire de Travail (<strong>ITT</strong>) fonctionne en cas d'arrêt de travail prolongé, dépassant généralement une période selon la franchise (90 jours généralement).</p></li></ul></li></ul>",
            },
          }, {
            value: 'OPTIMUM',
            text: 'Optimum (Complète + IPP)',
            infoBull: {
              title: 'Optimum (Complète + IPP)',
              description: "<ul><li><p>Optimum (Complète + IPP) : Outre les garanties décès/PTIA et IPT/ITT :</p><ul><li><p>La garantie d'Invalidité Permanente Partielle (<strong>IPP</strong>) assure le remboursement d'une partie des échéances du prêt, dès lors qu'un taux d'invalidité supérieur à 33 % et inférieur à 66% a été reconnu.</p></li></ul></li></ul>",
            },
          }],
        showLoading: false,
        devis: null,
      }
    },
    clearFranchise() {
      this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.franchise = null
      this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.franchise = null
    },
    showPei(val,TypeAssure, date_naissance) {
      if (TypeAssure == "co_emprunteur") {
        if ( (val == "SALARIE_NON_CADRE" || val == "SALARIE_CADRE" ) && (date_naissance && Math.floor(moment().diff(date_naissance.split("/").reverse().join("-"), 'years', true)) < 60)) {
          this.showPerlEmplois.showSecondaire = true
        }
        else {
          this.showPerlEmplois.showSecondaire = false
          this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.pei = null
          this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.pei = null
        }

      }
      else if (TypeAssure == "emprunteur") {
        if ((val == "SALARIE_NON_CADRE" || val == "SALARIE_CADRE" ) &&  (date_naissance && Math.floor(moment().diff(date_naissance.split("/").reverse().join("-"), 'years', true)) < 60)) {
          this.showPerlEmplois.showPrincipal = true
        }
        else {
          this.showPerlEmplois.showPrincipal = false
          this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.pei = null
          this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.pei = null
        }
      }
      
    },
    initPrecision(val, TypeAssure, date_naissance,checkChange = true) {
      if(val != "SANS_EMPLOI" && val != "PROFESSION_LIBERALE" && val != "FONCTIONNAIRE_ASSIMILE")
        this.objetEmprunteur[TypeAssure].precision_profession = val
      else this.objetEmprunteur[TypeAssure].precision_profession = null

      if(checkChange)
        this.showPei(val, TypeAssure, date_naissance)
    },
    getVilleByCodePostal(codePostal, idConnexite = null, typeConnexite = null) {
      if (codePostal && codePostal.length === 5) {
        this.$http
          .get(`/helpers/${codePostal}/villes`)
          .then(res => {
            if (res.data?.length > 0) {
              this.villeOptionsCoEmprunteur = [...res.data]
              if (idConnexite && typeConnexite) {
                const obj = this.villeOptionsCoEmprunteur.find(o => {
                  if (typeConnexite == 'CONJOINT') return o.id === this.conjoint.moyen_contact.ville_id
                  if (typeConnexite == 'ENFANT') return o.id === this.enfantsArray[idConnexite].moyen_contact.ville_id
                  if (typeConnexite == 'PARENT') return o.id === this.parentsArray[idConnexite].moyen_contact.ville_id
                  if (typeConnexite == 'AMI') return o.id === this.amisArray[idConnexite].moyen_contact.ville_id
                })
                if (typeof obj === 'undefined' || obj === null) {
                  if (typeConnexite == 'CONJOINT') {
                    this.conjoint.moyen_contact.ville_id = null
                  } else if (typeConnexite == 'ENFANT') {
                    this.enfantsArray[idConnexite].moyen_contact.ville_id = null
                  } else if (typeConnexite == 'PARENT') {
                    this.parentsArray[idConnexite].moyen_contact.ville_id = null
                  } else if (typeConnexite == 'AMI') {
                    this.amisArray[idConnexite].moyen_contact.ville_id = null
                  }
                }
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.villeOptionsCoEmprunteur = []
      }
    },
    resetDataPretPalier(val, type_pret) {
      if (!val) {
        if (type_pret == 'PRINCIPAL') {
          this.objetEmprunteur.prets.pret_principal.paliersArray = []
        } else if (type_pret == 'SECONDAIRE') {
          this.objetEmprunteur.prets.pret_secondaire.paliersArray = []
        }
      }
    },
    resetDataDiffere(val, type_pret) {
      if (!val) {
        if (type_pret == 'PRINCIPAL') {
          this.objetEmprunteur.prets.pret_principal.duree_differe = null
          this.objetEmprunteur.prets.pret_principal.type_differe = null
        } else if (type_pret == 'SECONDAIRE') {
          this.objetEmprunteur.prets.pret_secondaire.duree_differe = null
          this.objetEmprunteur.prets.pret_secondaire.type_differe = null
        }
      }
    },
    resetDataPretSec(val) {
      if (!val) {
        this.objetEmprunteur.prets.pret_secondaire = this.initialState().objetEmprunteur.prets.pret_secondaire
      }
    },
    resetDataSecondaire(val, typeEmp) {
      let resetData = null
      switch (typeEmp) {
        case 'EMPRUNTEUR':
          resetData = this.initialState().objetEmprunteur.prets.pret_secondaire.garanties.emprunteur
          resetData.has_same_garanties = val
          this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur = resetData
          break
        case 'CO-EMPRUNTEUR':
          resetData = this.initialState().objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur
          resetData.has_same_garanties = val
          this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur = resetData
          break
        default:
          break
      }
    },
    initDataGarantiesSecondaire() {
      var GPP = this.objetEmprunteur.prets.pret_principal.garanties
      var GPS = this.objetEmprunteur.prets.pret_secondaire.garanties
      var hasCoEmp = this.objetEmprunteur.emprunteur.has_co_emprunteur
      var hasPs = this.objetEmprunteur.has_pret_secondaire

      if(hasPs) {
        if(GPS.emprunteur.has_same_garanties) {
          GPS.emprunteur = Object.assign({}, GPP.emprunteur)
          GPS.emprunteur.has_same_garanties = 1
        }
        if(hasCoEmp && GPS.co_emprunteur.has_same_garanties) {
          GPS.co_emprunteur = Object.assign({}, GPP.co_emprunteur)
          GPS.co_emprunteur.has_same_garanties = 1
        }
      }
    },
    resetValueBesoinClient(value, typePret, TypeAssure) {
      if (value == 'MINIMUM' && typePret && TypeAssure) {
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].incapacite = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].franchise = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].pei = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].mno_dospsy = null
            this.objetEmprunteur.prets[typePret].garanties[TypeAssure].exo = null
      }
    },
    checkProfession(profession, typeEmp) {
      const prof = profession ? profession.toLowerCase() : ''
      if (prof == 'retraité' || prof == 'retraite') {
        switch (typeEmp) {
          case 'EMPRUNTEUR':
            this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client = 'MINIMUM'
            this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client = 'MINIMUM'
            this.disBc = true
            break
          case 'CO-EMPRUNTEUR':
            this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client = 'MINIMUM'
            this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client = 'MINIMUM'
            this.disBcCe = true
            break
        }
      } else {
        this.disBc = typeEmp == 'EMPRUNTEUR' ? false : this.disBc
        this.disBcCe = typeEmp == 'CO-EMPRUNTEUR' ? false : this.disBcCe
      }
    },
    initDataByTypeDemande(type_demande) {
      if (type_demande == 'DELIAISON_ASSURANCE') {
        this.objetEmprunteur.prets.pret_principal.date_signature = null
        this.objetEmprunteur.prets.pret_principal.capital = 0
        this.objetEmprunteur.prets.pret_principal.duree_pret = 0
        this.objetEmprunteur.prets.pret_secondaire.date_signature = null
        this.objetEmprunteur.prets.pret_secondaire.capital = 0
        this.objetEmprunteur.prets.pret_secondaire.duree_pret = 0
      } else if (type_demande == 'SUBSTITUTION') {
        this.clearFranchise()
        this.objetEmprunteur.prets.pret_principal.avance_projet = null
        this.objetEmprunteur.prets.pret_principal.capital = 0
        this.objetEmprunteur.prets.pret_principal.duree_pret = 0
        this.objetEmprunteur.prets.pret_secondaire.avance_projet = null
        this.objetEmprunteur.prets.pret_secondaire.capital = 0
        this.objetEmprunteur.prets.pret_secondaire.duree_pret = 0
      }
    },
    initOriginalData(tiers) {
      this.originalData.assure.civilite = tiers.civilite
      this.originalData.assure.situation_familiale = tiers.situation_familiale
      this.originalData.assure.date_naissance = tiers.date_naissance
      this.originalData.assure.nom = tiers.nom
      this.originalData.assure.nom_naissance = tiers.nom_naissance
      this.originalData.assure.prenom = tiers.prenom
      this.originalData.assure.code_postal_naissance = tiers.code_postal_naissance
      this.originalData.assure.commune_naissance = tiers.commune_naissance
      this.originalData.assure.pays_naissance = tiers.pays_naissance
      this.originalData.assure.statut = tiers.statut
      this.originalData.assure.moyen_contact.code_postal = tiers.moyen_contact.code_postal
      this.originalData.assure.moyen_contact.ville_id = tiers.moyen_contact.ville_id
    },
    initOriginalDataConjoint(conjoint) {
      this.originalData.conjoint.civilite = conjoint.civilite
      this.originalData.conjoint.situation_familiale = conjoint.situation_familiale
      this.originalData.conjoint.statut = conjoint.statut
      this.originalData.conjoint.date_naissance = conjoint.date_naissance
      this.originalData.conjoint.statut_gda = conjoint.statut_gda
      this.originalData.conjoint.nom = conjoint.nom
      this.originalData.conjoint.nom_naissance = conjoint.nom_naissance
      this.originalData.conjoint.prenom = conjoint.prenom
      this.originalData.conjoint.code_postal_naissance = conjoint.code_postal_naissance
      this.originalData.conjoint.commune_naissance = conjoint.commune_naissance
      this.originalData.conjoint.pays_naissance = conjoint.pays_naissance
      this.originalData.conjoint.moyen_contact.code_postal = conjoint.moyen_contact.code_postal
      this.originalData.conjoint.moyen_contact.ville_id = conjoint.moyen_contact.ville_id
    },
    initConfigDateEffet() {
      if (this.objetEmprunteur.hamon) {
        if (this.devis.type_vente && this.devis.type_vente == 'optout') {
          this.changeDisabledDate(34)
        } else {
          this.changeDisabledDate(33)
        }
      } else {
        this.changeDisabledDate(0)
      }
    },
    changeDisabledDate(day) {
      this.configDateEffet.disable = [
        function (date) {
          const today = new Date()
          today.setDate(today.getDate() + day)
          today.setHours(0, 0, 0, 0)
          return date < today
        },
        function (date) {
          const dateMaxSL = new Date()
          const dateMaxECA = new Date()
          dateMaxSL.setMonth(dateMaxSL.getMonth() + 9)
          dateMaxECA.setFullYear(dateMaxECA.getFullYear() + 1, 0, 1)
          dateMaxSL.setHours(0, 0, 0, 0)
          dateMaxECA.setHours(0, 0, 0, 0)
          const datePlusMonth = dateMaxSL <= dateMaxECA ? dateMaxSL : dateMaxECA
          return datePlusMonth < date
        },
      ]
    },
    clearError(error) {
      if (error) error = error.replace(/{[0-9]*}/i, '')

      return error
    },
    toggleIsSouscipteur(val) {
      if (!val) {
        this.objetEmprunteur.lien = null
        this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur = this.initialState().objetEmprunteur.prets.pret_principal.garanties.co_emprunteur
        this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur = this.initialState().objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur
        this.objetEmprunteur.co_emprunteur = this.initialState().objetEmprunteur.co_emprunteur 
      }
    },
    toggleConnexite(statut, indexConnexite, type) {
      if (statut) {
        if (type == 'ENFANT') {
          this.enfantsArray.forEach((enf, index) => {
            if (index !== indexConnexite) {
              enf.state = false
            } else {
              this.initPrecision(enf.statut, 'co_emprunteur', enf.date_naissance,enf.state)
              this.objetEmprunteur.co_emprunteur.isPPE = enf.isPPE
              this.getVilleByCodePostal(enf.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        } else if (type == 'PARENT') {
          this.parentsArray.forEach((par, index) => {
            if (index !== indexConnexite) {
              par.state = false
            } else {
              this.initPrecision(par.statut, 'co_emprunteur',par.date_naissance,par.state)
              this.objetEmprunteur.co_emprunteur.isPPE = par.isPPE
              this.getVilleByCodePostal(par.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        } else if (type == 'AMI') {
          this.amisArray.forEach((ami, index) => {
            if (index !== indexConnexite) {
              ami.state = false
            } else {
              this.initPrecision(ami.statut, 'co_emprunteur',ami.date_naissance,ami.state)
              this.objetEmprunteur.co_emprunteur.isPPE = ami.isPPE
              this.getVilleByCodePostal(ami.moyen_contact.code_postal, indexConnexite, type)
            }
          })
        }
      }
    },
    initialAssure(tiers) {
      this.assure = tiers
      if(!this.objetEmprunteur.emprunteur.precision_profession) {
        this.initPrecision(this.assure.statut, 'emprunteur', this.assure.date_naissance)
      } else {
          this.showPei(this.assure.statut, 'emprunteur', this.assure.date_naissance)
      }
    },
    initialConjoint(tiers) {
      this.conjoint = tiers
    },
    getPays() {
      this.$http
        .get('tiers/getPays')
        .then(r => {
          this.pays = r.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    MonthFormatter(value, maxValue) {
      return (value == maxValue) ? `${value} mois et plus` : `${value} mois`
    },
    backStep() {
      this.$store.commit('setStepTarif', {
        step: {
          indexStep: 0,
          active: 'choix',
        },
      })
    },
    async checkValidateField(index) {
      this.errorsTarif = []
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
       if ((this.objetEmprunteur.prets.pret_principal.has_pret_palier && this.objetEmprunteur.prets.pret_principal.paliersArray.length == 0) || (this.objetEmprunteur.prets.pret_secondaire.has_pret_palier && this.objetEmprunteur.prets.pret_secondaire.paliersArray.length == 0)){
         this.$toast({
           component: ToastificationContent,
           props: {
             title: 'Vous devez insérer au moins un palier',
             icon: 'XIcon',
             variant: 'danger',
           },
         })
         this.showLoading = false
         return 0
       }

      if (!this.amiHasValidDateNaissance && this.objetEmprunteur.lien == 'AMI') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'L\'âge de amis doit être comprise entre 18 et 79 ans',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.showLoading = false
        return 0
      }

      if (!this.parentHasValidDateNaissance && this.objetEmprunteur.lien == 'PARENT') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'L\'âge de parents doit être comprise entre 18 et 79 ans',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.showLoading = false
        return 0
      }

      if (!this.enfantsHasValidDateNaissance && this.objetEmprunteur.lien == 'ENFANT' ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'L\'âge d\'enfants doit être comprise entre 18 et 79 ans',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.showLoading = false
        return 0
      }
      

      if (this.conjoint.date_naissance && this.objetEmprunteur.lien == 'CONJOINT' && (Math.floor(moment().diff(moment(this.conjoint.date_naissance, 'DD-MM-YYYY'), 'years', true)) < 18 || Math.floor(moment().diff(moment(this.conjoint.date_naissance, 'DD-MM-YYYY'), 'years', true)) > 79)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'L\'âge de conjoint doit être comprise entre 18 et 79 ans',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return 0
      }
      
      if (this.assure.date_naissance &&( Math.floor(moment().diff(moment(this.assure.date_naissance, 'DD-MM-YYYY'), 'years', true)) < 18 || Math.floor(moment().diff(moment(this.assure.date_naissance, 'DD-MM-YYYY'), 'years', true)) > 79)) {
        this.errorDateNaissanceProspect = 'L\'âge de l’assuré principal doit être comprise entre 18 et 79 ans'
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'L\'âge de l’assuré principal doit être comprise entre 18 et 79 ans',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return 0
      }

      if(this.objetEmprunteur.emprunteur.has_co_emprunteur && this.objetEmprunteur.lien) {
        if(!this.hasCoEmprunteurSelected) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Co-assuré est obligatoire',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return 0
        }
      }

      const isValid = await this.$refs.AssureRules.validate()
      if (!isValid || !this.checkValidateGaranties()) {
        this.checkCollapseHasErrors()
        this.showLoading = false
        return 0
      }
      // assure
      this.assure.original_civilite = this.originalData.assure.civilite
      this.assure.original_statut = this.originalData.assure.statut
      this.assure.original_situation_familiale = this.originalData.assure.situation_familiale
      this.assure.original_date_naissance = this.originalData.assure.date_naissance   
      this.assure.original_nom = this.originalData.assure.nom
      this.assure.original_prenom = this.originalData.assure.prenom
      this.assure.original_nom_naissance = this.originalData.assure.nom_naissance
      this.assure.original_code_postal_naissance = this.originalData.assure.code_postal_naissance
      this.assure.original_commune_naissance = this.originalData.assure.commune_naissance
      this.assure.original_pays_naissance = this.originalData.assure.pays_naissance
      this.assure.original_code_postal = this.originalData.assure.moyen_contact.code_postal
      this.assure.original_ville_id = this.originalData.assure.moyen_contact.ville_id
      // conjoint
      this.conjoint.original_civilite = this.originalData.conjoint.civilite
      this.conjoint.original_statut = this.originalData.conjoint.statut
      this.conjoint.original_situation_familiale = this.originalData.conjoint.situation_familiale
      this.conjoint.original_date_naissance = this.originalData.conjoint.date_naissance
      this.conjoint.original_nom = this.originalData.conjoint.nom
      this.conjoint.original_prenom = this.originalData.conjoint.prenom
      this.conjoint.original_nom_naissance = this.originalData.conjoint.nom_naissance
      this.conjoint.original_code_postal_naissance = this.originalData.conjoint.code_postal_naissance
      this.conjoint.original_commune_naissance = this.originalData.conjoint.commune_naissance
      this.conjoint.original_pays_naissance = this.originalData.conjoint.pays_naissance
      this.conjoint.original_code_postal = this.originalData.conjoint.moyen_contact.code_postal
      this.conjoint.original_ville_id = this.originalData.conjoint.moyen_contact.ville_id
      if (
      // original assure
        (this.assure.statut != this.assure.original_statut && this.assure.original_statut != '' && this.assure.original_statut != null)
      || (this.assure.date_naissance != this.assure.original_date_naissance && this.assure.original_date_naissance != '' && this.assure.original_date_naissance != null)
      || (this.assure.civilite != this.assure.original_civilite && this.assure.original_civilite != '' && this.assure.original_civilite != null)
      || (this.assure.nom != this.assure.original_nom && this.assure.original_nom !='' && this.assure.original_nom != null)
      || (this.assure.prenom != this.assure.original_prenom && this.assure.original_prenom != '' && this.assure.original_prenom != null)
      || (this.assure.nom_naissance != this.assure.original_nom_naissance && this.assure.original_nom_naissance != '' && this.assure.original_nom_naissance !=null)
      || (this.assure.code_postal_naissance != this.assure.original_code_postal_naissance && this.assure.original_code_postal_naissance != '' && this.assure.original_code_postal_naissance != null)
      || (this.assure.commune_naissance != this.assure.original_commune_naissance && this.assure.original_commune_naissance != '' && this.assure.original_commune_naissance != null)
      || (this.assure.pays_naissance != this.assure.original_pays_naissance && this.assure.original_pays_naissance != '' && this.assure.original_pays_naissance !=null)
      || (this.assure.moyen_contact.code_postal != this.assure.original_code_postal && this.assure.original_code_postal != '' && this.assure.original_code_postal !=null)
      || (this.assure.moyen_contact.ville_id != this.assure.original_ville_id && this.assure.original_ville_id != '' && this.assure.original_ville_id !=null)
      || this.checkOrigineCoAssure()
      ) {
        this.$swal({
          html: `<p class="text-justify" style="font-size: 15px">Attention, vous souhaitez modifier une donnée potentiellement tarifante pour un devis non encore souscrit ou un contrat souscrit par l'intermédiaire de BubbleIn.
                  <br><br>
                  Les devis en cours d'étude seront modifiés avec ces nouvelles informations. Les devis déjà envoyés ou les contrats en cours de signature seront classés sans suite et il conviendra de les refaire. Si vous avez souscrit un contrat, il conviendra de vérifier la nécessité de procéder à un avenant.
                  <br><br>
                  Souhaitez-vous confirmer cette modification ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            html: 'text-left',
          },
          buttonsStyling: false,
        })
          .then(confirmed => {
            if (confirmed.value) {
              this.cleardata()
              this.faireTarif(index)
              this.originalData.assure.civilite = this.assure.civilite
              this.originalData.assure.statut = this.assure.statut
              this.originalData.assure.situation_familiale = this.assure.situation_familiale
              this.originalData.assure.date_naissance = this.assure.date_naissance
              // conjoint
              this.originalData.conjoint.civilite = this.conjoint.civilite
              this.originalData.conjoint.statut = this.conjoint.statut
              this.originalData.conjoint.situation_familiale = this.conjoint.situation_familiale
              this.originalData.conjoint.date_naissance = this.conjoint.date_naissance
              this.originalData.conjoint.nom = this.conjoint.nom
              this.originalData.conjoint.nom_naissance = this.conjoint.nom_naissance
              this.originalData.conjoint.prenom = this.conjoint.prenom
              this.originalData.conjoint.code_postal_naissance = this.conjoint.code_postal_naissance
              this.originalData.conjoint.commune_naissance = this.conjoint.commune_naissance
              this.originalData.conjoint.pays_naissance = this.conjoint.pays_naissance
              this.originalData.conjoint.moyen_contact.code_postal = this.conjoint.moyen_contact.code_postal
              this.originalData.conjoint.moyen_contact.ville_id = this.conjoint.moyen_contact.ville_id
            }
          })
      } else this.faireTarif(index)
    },
        cleardata() {
      this.errorDateNaissanceProspect = null
    },
    checkCollapseHasErrors() {
      for (let index = 0; index < 6; index++) {
        let tabCollapseItem = this.$refs[`tab-collapse-item-${index}`];
        if (tabCollapseItem) {
          const hasErrors = tabCollapseItem.$el.querySelector('div.d-block.invalid-feedback, input.is-invalid');
          if (hasErrors !== undefined) {
            tabCollapseItem.visible = !!hasErrors;
          }
        }
      }
    },
    async faireTarif(index) {
      this.initDataGarantiesSecondaire()
      this.getEtude.itemsRisque.ItemsRisque[index].objets.objet_emprunteurs = [this.objetEmp, this.objetEmprunteur]
      this.getEtude.Tiers = this.assure
      const chooseRisque = this.getEtude.itemsRisque.ItemsRisque[0]
      const risqueItem = this.getEtude
      risqueItem.Enfant.Enfant = this.enfantsArray
      risqueItem.Parent.Parent = this.parentsArray
      risqueItem.Ami.Ami = this.amisArray
      risqueItem.itemsRisque.ItemsRisque = []
      risqueItem.itemsRisque.ItemsRisque.push(chooseRisque)

      this.showLoading = true
      await this.persistObject(risqueItem)
      return new Promise((resolve, reject) => {
        resolve(true)
      })
    },
    async persistObject(risqueItem) {
      try {
        await this.$http.post('portefeuilles/addDevisPortefeuille', risqueItem).then(response => {
          const enfants = response.data[1]
          const conjoint = response.data[2]
          const parents = response.data[3]
          const ami = response.data[4]

          this.$store.commit('setObjetEnfant', { Enfant: enfants })
          this.$store.commit('setObjetConjoint', { Conjoint: conjoint })
          this.$store.commit('setObjetParent', { Parent: parents })
          this.$store.commit('setObjetAmi', { Ami: ami })

          return this.getTarifData(risqueItem, 0, enfants, parents, conjoint)
        })
        return true
      } catch (e) {
        this.showLoading = false
        console.error(e)
        this.errors = e.response.data.errors
      }
    },
    async getTarifData(risqueItem, risque, enfants, parents, conjoint) {
      try {
        const garantiesValues = this.getEtude.itemsRisque.ItemsRisque[risque].garanties
        garantiesValues.forEach((item, index) => {
          if (garantiesValues[index].garantieNiveauID.length <= 0) {
            this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID = []
            // this.getEtude.itemsRisque.ItemsRisque[risque].garanties[index].garantieNiveauID.push(
            //   garantiesValues[index].garantieniveaux.find(item => item.garantie_niveau_libel == 'Non').id
            // )
          }
        })
        risqueItem.itemsRisque.ItemsRisque[risque].garanties = this.getEtude.itemsRisque.ItemsRisque[risque].garanties

        const data = {
          conjoint,
          enfant: enfants,
          parent: parents,
          assure: this.assure,
          risque: {
            objet: risqueItem.itemsRisque.ItemsRisque[risque].objets,
            produits: risqueItem.itemsRisque.ItemsRisque[risque].produits,
            garanties: risqueItem.itemsRisque.ItemsRisque[risque].garanties,
            produits_garanties: risqueItem.itemsRisque.ItemsRisque[risque].produit_garanties,
            risque: risqueItem.itemsRisque.ItemsRisque[risque].risque,
            devis: risqueItem.itemsRisque.ItemsRisque[risque].devis.id,
          },
        }
        await this.$http.post('portefeuilles/getTarif', data).then(response => {
          this.errorsTarif = []
          let hasErrorsProduit = false
          let errors = []
          if (Array.isArray(response.data)) {
            response.data.every(pr => {
              if (pr.hasOwnProperty('errors')) {
                errors = errors.concat(pr.errors)
                return hasErrorsProduit = true
              } else {
                errors = []
                return hasErrorsProduit = false
              }
            })
          }

          if (!hasErrorsProduit) {
            this.getEtude.itemsRisque.ItemsRisque[risque].produits.tarif = []
            this.getEtude.itemsRisque.ItemsRisque[risque].produits = response.data
            this.$store.commit('setItemsRisqueGlobal', {
              ItemsRisque: risqueItem.itemsRisque.ItemsRisque,
            })
          } else {
            this.errorsTarif = this.filterErrorsTarif(errors)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }
        })

        if (this.errorsTarif.length <= 0) {
          this.$store.commit('setStepTarif', {
            step: {
              indexStep: 2,
              active: 'comparatif',
            },
          })
        }

        this.showLoading = false
      } catch (e) {
        this.showLoading = false
        this.errors = e.response.data.errors
      }
    },
    filterErrorsTarif(arrayErrors) {
      arrayErrors = arrayErrors.filter((value, index, self) => index === self.findIndex(e => (
        e.attribut === value.attribut && e.message === value.message
      )))
      return arrayErrors
    },
    addConnexite(typeConnexite) {
      if (typeConnexite == 'ENFANT') this.enfantsArray.push(this.initialState().enfant)
      else if (typeConnexite == 'PARENT') this.parentsArray.push(this.initialState().parent)
      else if (typeConnexite == 'AMI') this.amisArray.push(this.initialState().ami)
    },
    removeConnexite(index, typeConnexite) {
      if (typeConnexite == 'ENFANT') this.enfantsArray.splice(index, 1)
      else if (typeConnexite == 'PARENT') this.parentsArray.splice(index, 1)
      else if (typeConnexite == 'AMI') this.amisArray.splice(index, 1)
    },
    addPalier(type_palier) {
      switch (type_palier) {
        case 'PRINCIPAL':
          if (this.objetEmprunteur.prets.pret_principal.paliersArray.length < 4) this.objetEmprunteur.prets.pret_principal.paliersArray.push(this.initialState().palier)
          break
        case 'SECONDAIRE':
          if (this.objetEmprunteur.prets.pret_secondaire.paliersArray.length < 4) this.objetEmprunteur.prets.pret_secondaire.paliersArray.push(this.initialState().palier)
          break
        default:
          break
      }
    },
    removePalier(index, type_palier) {
      switch (type_palier) {
        case 'PRINCIPAL':
          this.objetEmprunteur.prets.pret_principal.paliersArray.splice(index, 1)
          break
        case 'SECONDAIRE':
          this.objetEmprunteur.prets.pret_secondaire.paliersArray.splice(index, 1)
          break
        default:
          break
      }
    },
    mapConnexiteForStore(prospects, typeConnexite) {
      if (typeConnexite == 'ENFANT') {
        this.enfantsArray = []
        Object.values(this.enfantStore).forEach((enf, index) => {
          let cloneEnf = JSON.parse(JSON.stringify(enf))
          const enfant = {
            id: enf.lienable.id,
            civilite: enf.lienable.civilite,
            nom: enf.lienable.nom,
            prenom: enf.lienable.prenom,
            num_secu: enf.lienable.num_secu,
            numeroOrganisme: enf.lienable.numeroOrganisme,
            date_naissance: enf.lienable.date_naissance ? moment(enf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            state: false,
            original_date_naissance:  cloneEnf.lienable.date_naissance ? moment(cloneEnf.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
original_civilite : cloneEnf.lienable.civilite,
            original_nom : cloneEnf.lienable.nom,
            original_nom_naissance: cloneEnf.lienable.nom_naissance,
            original_prenom: cloneEnf.lienable.prenom,
            original_code_postal_naissance : cloneEnf.lienable.code_postal_naissance,
            original_commune_naissance :cloneEnf.lienable.commune_naissance,
            original_pays_naissance : cloneEnf.lienable.pays_naissance,
            original_code_postal : cloneEnf.lienable.moyen_contact ? cloneEnf.lienable.moyen_contact.code_postal : null,
            original_ville_id : cloneEnf.lienable.moyen_contact ? cloneEnf.lienable.moyen_contact.ville_id : null,
            original_statut: cloneEnf.lienable.statut,
            code_postal : enf.lienable.moyen_contact ? enf.lienable.moyen_contact.code_postal : null,
            ville_id : enf.lienable.moyen_contact ? enf.lienable.moyen_contact.ville_id : null,
            pays_naissance: enf.lienable.pays_naissance,
            commune_naissance: enf.lienable.commune_naissance,
            code_postal_naissance: enf.lienable.code_postal_naissance,
            nom_naissance: enf.lienable.nom_naissance,
            situation_familiale: enf.lienable.situation_familiale,
            statut: enf.lienable.statut,
            statut_gda: enf.lienable.statut_gda,
            isPPE: enf.lienable.isPPE,
            moyen_contact: {
              code_postal: enf.lienable.moyen_contact ? enf.lienable.moyen_contact.code_postal : null,
              ville_id: enf.lienable.moyen_contact ? enf.lienable.moyen_contact.ville_id : null,
            },
          }
          if (prospects && prospects.length > 0) {
            const checkExist = _.find(prospects, e => e.personne_physique_id == enf.lienable.id)
            if (checkExist) {
              enfant.state = true
              if(!this.objetEmprunteur.co_emprunteur.precision_profession) {
                this.initPrecision(enf.lienable.statut, 'co_emprunteur', enf.lienable.date_naissance,enfant.state)
              } else {
                this.showPei(enf.lienable.statut, 'co_emprunteur', enf.lienable.date_naissance)
              }
            } 
          }
          this.enfantsArray.push(enfant)
        })
      } else if (typeConnexite == 'PARENT') {
        this.parentsArray = []
        Object.values(this.parentStore).forEach((par, index) => {
          let clonePar = JSON.parse(JSON.stringify(par))
          const parent = {
            id: par.lienable.id,
            civilite: par.lienable.civilite,
            nom: par.lienable.nom,
            prenom: par.lienable.prenom,
            num_secu: par.lienable.num_secu,
            numeroOrganisme: par.lienable.numeroOrganisme,
            date_naissance: par.lienable.date_naissance ? moment(par.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            state: false,
            original_date_naissance: clonePar.lienable.date_naissance ? moment(clonePar.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            original_civilite : clonePar.lienable.civilite,
            original_nom : clonePar.lienable.nom,
            original_nom_naissance: clonePar.lienable.nom_naissance,
            original_prenom: clonePar.lienable.prenom,
            original_code_postal_naissance: clonePar.lienable.code_postal_naissance,
            original_commune_naissance:clonePar.lienable.commune_naissance,
            original_pays_naissance : clonePar.lienable.pays_naissance,
            original_code_postal : clonePar.lienable.moyen_contact ? clonePar.lienable.moyen_contact.code_postal : null,
            original_ville_id : clonePar.lienable.moyen_contact ? clonePar.lienable.moyen_contact.ville_id : null,
            original_statut: clonePar.lienable.statut,
            code_postal : par.lienable.moyen_contact ? par.lienable.moyen_contact.code_postal : null,
            ville_id : par.lienable.moyen_contact ? par.lienable.moyen_contact.ville_id : null,
            pays_naissance: par.lienable.pays_naissance,
            commune_naissance: par.lienable.commune_naissance,
            code_postal_naissance: par.lienable.code_postal_naissance,
            nom_naissance: par.lienable.nom_naissance,
            situation_familiale: par.lienable.situation_familiale,
            statut: par.lienable.statut,
            statut_gda: par.lienable.statut_gda,
            isPPE: par.lienable.isPPE,
            moyen_contact: {
              code_postal: par.lienable.moyen_contact.code_postal,
              ville_id: par.lienable.moyen_contact.ville_id,
            },
          }
          if (prospects && prospects.length > 0) {
            const checkExist = _.find(prospects, e => e.personne_physique_id == par.lienable.id)
            if (checkExist) {
              parent.state = true
              if(!this.objetEmprunteur.co_emprunteur.precision_profession) {
                this.initPrecision(par.lienable.statut, 'co_emprunteur',par.lienable.date_naissance,parent.state)
              } else {
                this.showPei(par.lienable.statut, 'co_emprunteur',par.lienable.date_naissance)
              }
            } 
          }
          this.parentsArray.push(parent)
        })
      } else if (typeConnexite == 'AMI') {
        this.amisArray = []
        Object.values(this.amiStore).forEach((ami, index) => {
          let cloneAmi = JSON.parse(JSON.stringify(ami))
          const amiObjet = {
            id: ami.lienable.id,
            civilite: ami.lienable.civilite,
            nom: ami.lienable.nom,
            prenom: ami.lienable.prenom,
            num_secu: ami.lienable.num_secu,
            numeroOrganisme: ami.lienable.numeroOrganisme,
            date_naissance: ami.lienable.date_naissance ? moment(ami.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            original_date_naissance: cloneAmi.lienable.date_naissance ? moment(cloneAmi.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            original_civilite : cloneAmi.lienable.civilite,
            original_nom : cloneAmi.lienable.nom,
            original_nom_naissance: cloneAmi.lienable.nom_naissance,
            original_prenom: cloneAmi.lienable.prenom,
            original_code_postal_naissance: cloneAmi.lienable.code_postal_naissance,
            original_commune_naissance:cloneAmi.lienable.commune_naissance,
            original_pays_naissance : cloneAmi.lienable.pays_naissance,
            original_code_postal : cloneAmi.lienable.moyen_contact ? cloneAmi.lienable.moyen_contact.code_postal : null,
            original_ville_id : cloneAmi.lienable.moyen_contact ? cloneAmi.lienable.moyen_contact.ville_id : null,
            original_statut: cloneAmi.lienable.statut,
            code_postal : ami.lienable.moyen_contact ? ami.lienable.moyen_contact.code_postal : null,
            ville_id : ami.lienable.moyen_contact ? ami.lienable.moyen_contact.ville_id : null,
            state: false,
            original_date_naissance: ami.lienable.date_naissance ? moment(ami.lienable.date_naissance, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            pays_naissance: ami.lienable.pays_naissance,
            commune_naissance: ami.lienable.commune_naissance,
            code_postal_naissance: ami.lienable.code_postal_naissance,
            nom_naissance: ami.lienable.nom_naissance,
            situation_familiale: ami.lienable.situation_familiale,
            statut: ami.lienable.statut,
            statut_gda: ami.lienable.statut_gda,
            isPPE: ami.lienable.isPPE,
            moyen_contact: {
              code_postal: ami.lienable.moyen_contact.code_postal,
              ville_id: ami.lienable.moyen_contact.ville_id,
            },
          }
          if (prospects && prospects.length > 0) {
            const checkExist = _.find(prospects, e => e.personne_physique_id == ami.lienable.id)
            if (checkExist) {
              amiObjet.state = true
              if(!this.objetEmprunteur.co_emprunteur.precision_profession) {
                this.initPrecision(ami.lienable.statut, 'co_emprunteur',ami.lienable.date_naissance,amiObjet.state)
              } else {
                this.showPei(ami.lienable.statut, 'co_emprunteur',ami.lienable.date_naissance)
              }
            } 
          }
          this.amisArray.push(amiObjet)
        })
      }
    },
    checkValidateGaranties() {
      this.errorsGaranties = []
      const emprunteurBcPr = this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.besoin_client
      // Principal
      const emprunteurPtiaPr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.ptia ?? 0)
      const emprunteurIncapacitePr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.emprunteur.incapacite ?? 0)
      const coEmprunteurBcPr = this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.besoin_client
      const coEmprunteurPtiaPr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.ptia ?? 0)
      const coEmprunteurIncapacitePr = parseFloat(this.objetEmprunteur.prets.pret_principal.garanties.co_emprunteur.incapacite ?? 0)

      // Secondaire
      let emprunteurBcSe = this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.besoin_client
      let emprunteurPtiaSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.ptia ?? 0)
      let emprunteurIncapaciteSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.incapacite ?? 0)
      let coEmprunteurBcSe = this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.besoin_client
      let coEmprunteurPtiaSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.ptia ?? 0)
      let coEmprunteurIncapaciteSe = parseFloat(this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.incapacite ?? 0)

      if (this.objetEmprunteur.emprunteur.has_co_emprunteur && (coEmprunteurPtiaPr <= 0 || emprunteurPtiaPr <=0)) {
        this.errorsGaranties.push('Décès/PTIA de prêt principal (assuré, co-assuré) doivent être supérieur à zéro')
      }

      // if (emprunteurPtiaPr + coEmprunteurPtiaPr < 100) {
      //   this.errorsGaranties.push('le total PTIA de prêt principal doivent être égale ou supérieur à 100%')
      // }

      if (emprunteurBcPr && emprunteurBcPr != 'MINIMUM') {
        if (emprunteurIncapacitePr > emprunteurPtiaPr) {
          this.errorsGaranties.push('Incapacité/Invalidité de prét principal (assuré) doivent être inférieur ou égale PTIA')
        }
      }

      if (coEmprunteurBcPr && coEmprunteurBcPr != 'MINIMUM') {
        if (coEmprunteurIncapacitePr > coEmprunteurPtiaPr) {
          this.errorsGaranties.push('Incapacité/Invalidité de prét principal (co-assuré) doivent être inférieur ou égale PTIA')
        }
      }

      if (this.objetEmprunteur.has_pret_secondaire) {
        if (this.objetEmprunteur.prets.pret_secondaire.garanties.emprunteur.has_same_garanties) {
          emprunteurBcSe = emprunteurBcPr
          emprunteurPtiaSe = emprunteurPtiaPr
          emprunteurIncapaciteSe = emprunteurIncapacitePr
        }
        if (this.objetEmprunteur.prets.pret_secondaire.garanties.co_emprunteur.has_same_garanties) {
          coEmprunteurBcSe = coEmprunteurBcPr
          coEmprunteurPtiaSe = coEmprunteurPtiaPr
          coEmprunteurIncapaciteSe = coEmprunteurIncapacitePr
        }

        if(emprunteurPtiaSe <= 0 || (this.objetEmprunteur.emprunteur.has_co_emprunteur &&coEmprunteurPtiaSe <= 0)) {
          this.errorsGaranties.push('Décès/PTIA de prêt secondaire (assuré, co-assuré) doivent être supérieur à zéro')
        }

        // if (emprunteurPtiaSe + coEmprunteurPtiaSe < 100) {
        //   this.errorsGaranties.push('le total PTIA de prêt secondaire doivent être égale ou supérieur à 100%')
        // }

        if (emprunteurBcSe && emprunteurBcSe != 'MINIMUM') {
          if (emprunteurIncapaciteSe > emprunteurPtiaSe) {
            this.errorsGaranties.push('Incapacité/Invalidité de prét secondiare (assuré) doivent être inférieur ou égale PTIA')
          }
        }

        if (coEmprunteurBcSe && coEmprunteurBcSe != 'MINIMUM') {
          if (coEmprunteurIncapaciteSe > coEmprunteurPtiaSe) {
            this.errorsGaranties.push('Incapacité/Invalidité de prét secondiare (co-assuré) doivent être inférieur ou égale PTIA')
          }
        }
      }

      return !(this.errorsGaranties.length > 0)
    },
    resetStateCoAssure($coAssureType) {
      if($coAssureType != 'AMI') {
        this.amisArray.forEach(ami => ami.state = false);
      }
      if($coAssureType != 'PARENT') {
        this.parentsArray.forEach(par => par.state = false);
      }
      if($coAssureType != 'ENFANT') {
        this.enfantsArray.forEach(enf => enf.state = false);
      }
    },
    checkOrigineCoAssure(){ 
      let coAssureCheck = this.hasCoEmprunteurSelected
      if(coAssureCheck){
        if(this.objetEmprunteur.lien === 'CONJOINT'){
          if((this.conjoint.civilite != this.conjoint.original_civilite && this.conjoint.original_civilite != '' && this.conjoint.original_civilite != null)
            || (this.conjoint.date_naissance != this.conjoint.original_date_naissance && this.conjoint.original_date_naissance != '' && this.conjoint.original_date_naissance != null)
            || (this.conjoint.statut != this.conjoint.original_statut && this.conjoint.original_statut != '' && this.conjoint.original_statut != null)
            || (this.conjoint.nom != this.conjoint.original_nom && this.conjoint.original_nom !='' && this.conjoint.original_nom != null)
            || (this.conjoint.prenom != this.conjoint.original_prenom && this.conjoint.original_prenom != '' && this.conjoint.original_prenom != null)
            || (this.conjoint.nom_naissance != this.conjoint.original_nom_naissance && this.conjoint.original_nom_naissance != '' && this.conjoint.original_nom_naissance !=null)
            || (this.conjoint.code_postal_naissance != this.conjoint.original_code_postal_naissance && this.conjoint.original_code_postal_naissance != '' && this.conjoint.original_code_postal_naissance != null)
            || (this.conjoint.commune_naissance != this.conjoint.original_commune_naissance && this.conjoint.original_commune_naissance != '' && this.conjoint.original_commune_naissance != null)
            || (this.conjoint.pays_naissance != this.conjoint.original_pays_naissance && this.conjoint.original_pays_naissance != '' && this.conjoint.original_pays_naissance !=null)
            || (this.conjoint.moyen_contact.code_postal != this.conjoint.original_code_postal && this.conjoint.original_code_postal != '' && this.conjoint.original_code_postal !=null)
            || (this.conjoint.moyen_contact.ville_id != this.conjoint.original_ville_id && this.conjoint.original_ville_id != '' && this.conjoint.original_ville_id !=null)
          ){
            return true;
          }
        }
        else
        {
          if((this.hasCoEmprunteurSelected.original_civilite != this.hasCoEmprunteurSelected.civilite && this.hasCoEmprunteurSelected.original_civilite != '' && this.hasCoEmprunteurSelected.original_civilite != null)
        || (this.hasCoEmprunteurSelected.original_date_naissance != this.hasCoEmprunteurSelected.date_naissance && this.hasCoEmprunteurSelected.original_date_naissance != '' && this.hasCoEmprunteurSelected.original_date_naissance != null)
        || (this.hasCoEmprunteurSelected.original_statut != this.hasCoEmprunteurSelected.statut && this.hasCoEmprunteurSelected.original_statut != '' && this.hasCoEmprunteurSelected.original_statut != null)
        || (this.hasCoEmprunteurSelected.original_nom != this.hasCoEmprunteurSelected.nom && this.hasCoEmprunteurSelected.original_nom != '' && this.hasCoEmprunteurSelected.original_nom != null)
        || (this.hasCoEmprunteurSelected.original_prenom != this.hasCoEmprunteurSelected.prenom && this.hasCoEmprunteurSelected.original_prenom != '' && this.hasCoEmprunteurSelected.original_prenom != null)
        || (this.hasCoEmprunteurSelected.original_nom_naissance != this.hasCoEmprunteurSelected.nom_naissance && this.hasCoEmprunteurSelected.original_nom_naissance != '' && this.hasCoEmprunteurSelected.original_nom_naissance != null)
        || (this.hasCoEmprunteurSelected.original_code_postal_naissance != this.hasCoEmprunteurSelected.code_postal_naissance && this.hasCoEmprunteurSelected.original_code_postal_naissance != '' && this.hasCoEmprunteurSelected.original_code_postal_naissance != null)
        || (this.hasCoEmprunteurSelected.original_commune_naissance != this.hasCoEmprunteurSelected.commune_naissance && this.hasCoEmprunteurSelected.original_commune_naissance != '' && this.hasCoEmprunteurSelected.original_commune_naissance != null)
        || (this.hasCoEmprunteurSelected.original_pays_naissance != this.hasCoEmprunteurSelected.pays_naissance && this.hasCoEmprunteurSelected.original_pays_naissance != '' && this.hasCoEmprunteurSelected.original_pays_naissance != null)
        || (this.hasCoEmprunteurSelected.original_code_postal != this.hasCoEmprunteurSelected.moyen_contact.code_postal && this.hasCoEmprunteurSelected.original_code_postal != '' && this.hasCoEmprunteurSelected.original_code_postal != null)
        || (this.hasCoEmprunteurSelected.original_ville_id != this.hasCoEmprunteurSelected.moyen_contact.ville_id && this.hasCoEmprunteurSelected.original_ville_id != '' && this.hasCoEmprunteurSelected.original_ville_id != null)) {
          return true
        }
        }
      }

      return false
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import '@core/scss/vue/pages/page-pricing.scss';

.block-cylindre .custom-checkbox .custom-control-label::before,
.block-cylindre .custom-checkbox .custom-control-label::after {
    top: 0.7rem;
}

.label-cylindre {
  margin-top: 0.7rem;
  min-width: 125px;
}

.title-custom-wizard{
    color: #4d25bc ;
    margin-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.min-w-150 {
  min-width: 150px
}

#collapse-besoin .card:has(div.d-block.invalid-feedback, input.is-invalid) span h4 {
    color: #ea5455;
}

.w-80px {
  width: 80px!important;
}

.w-100px {
  width: 100px!important;
}
</style>

<style lang="scss" scoped>
label{
    font-weight: 900;
}

.w-125 {
  width: 125px;
}
.w-190 {
  width: 190px!important;
}
.h-40p {
  height: 40px;
}

@media only screen and (min-width: 700px) {
  #lg-popover .popover {
  width:600px;
  max-width:none; // Required for popovers wider than 276px (Bootstrap's max-width for popovers)
}
}


</style>
